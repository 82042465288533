div.regles{
    padding: 10px 0;
    position: relative;
    max-width: 100%;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .list_regles{
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 40px 0 0;
        @media screen and (max-width: 820px) {
            flex-direction: column;
        }
    }
    .regle{
        width: calc(100% / 3 - 3px);
        display: inline-block;
        .description{
            max-width: 940px;
            & > p, div{
                width: 85%;
                display: block;
                max-width: 368px;
                font-size: 18px;
                line-height: 1.2;
                text-align: center;
                margin: 15px auto 0;
               // font-family: 'DIN';
                p{
                    font-size: inherit!important;
                    line-height: inherit!important;
                    margin: 0 auto;
                    strong{
                        //font-family: 'DINBold';
                    }
                }
            }
        }
        @media screen and (max-width: 820px){
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 25px;
            &>img{
                display: inline-block;
                width: 80px;
                margin: 0;
            }
            .description{
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 290px;
                padding-left: 30px;                
                & > p, div{
                    margin: 0;
                    text-align: left;
                    font-size: 16px!important;
                    width: 100%;
                    max-width: 240px;
                    br{
                        display: none;
                    }
                }
            }
        }
        @media screen and (max-width: 450px){
            &>img{
                width: 60px;
                margin-left: 10px;
            }
            .description{
                & > p, div{
                    font-size: 14px!important;
                }
            }
        }
    }
    @media screen and (max-width: 500px) {
        padding: 0;
    }

    &.special{
        width: auto;
        flex-direction: row;
        padding: 40px 0!important;
        &>img{
            max-width: 50%;
            width: auto;
            height: 100%;
            bottom: 0;
            right: 50%;
            position: absolute;
        }
        &>div{
            margin-left: calc(50% - 7.5%);
            h2{
                margin: 7px 0;
                padding: 10px 0!important;
            }
            .list_regles{
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                padding: 0;
                margin-top: 25px;
                .regle{
                    display: block;
                    width: 100%;
                }
                .description{
                    height: auto;
                    div{
                        text-align: left;
                        width: 100%;
                        color: white;
                    }  
                } 
            }
        }
    }
}

div.regles_without_image{
    .list_regles .regle.inline-flex{
        display: flex;
        flex-direction: row;
        &>div{
            display: inline-flex;
            padding: 20px;
            flex-direction: column;
            &.description{
                flex-grow: 1;
                h3{
                    margin-top: 0;
                    margin-bottom: 8px;
                }
            }
        }
    }
}

.how-container.rules{
    div.regles{
        padding: 0;
        .list_regles{
            padding: 20px 0; 
        }
        .regle{
            width: auto;
            margin: 0 20px;
            .description{
                height: auto;
                div{
                    width: auto;
                    p{
                        //font-family: 'Infini';
                        width: 100%;
                        strong{
                            //font-family: 'Infini_Bold';
                        }
                    }
                }
            }
            img{                
                max-width: 100%;
                width: 120px;
            }
            @media screen and (max-width: 820px){
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 25px;
                &>img{
                    display: inline-block;
                    width: 80px;
                    margin: 0;
                }
                .description{
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 290px;
                    padding-left: 30px;                
                    & > p, div{
                        margin: 0;
                        text-align: left;
                        font-size: 16px!important;
                        line-height: 22px!important;
                        width: 100%;
                        max-width: 240px;
                        br{
                            display: none;
                        }
                    }
                }
            }
            @media screen and (max-width: 450px){
                &>img{
                    width: 60px;
                    margin-left: 10px;
                }
                .description{
                    padding-right: 5px;
                }
            }
        }
    } 
}