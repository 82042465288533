.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #FAFAFA;

    .button {
        margin-top: 30px;
    }
    
    .appBar{
        box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
        .toolbar{
            background-color: white ;
            justify-content: center;
        }
    }

    .login-header {
        margin-bottom: 8px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
            height: 100px;
        }
    }

    .login-body {
        padding: 4rem;
        background-color: #fff;
        border: 0.5px solid rgba(201, 203, 204, 0.5);
        max-width: 500px;

        h2 {
            margin: 0 auto;
        }
    }

    @media screen and (max-height: 650px) {
        height: auto;
    }
}
