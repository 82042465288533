footer{
    position: relative;
    color: white;
    padding-top: 40px;
    padding-bottom: 30px;
    & > div.footer{
        max-width: 90%;
        margin:auto;
        padding: 5px;
    }
    p{
        font-size: inherit;    
        line-height: inherit;
        width: auto;
        display: block;
        margin: auto;
        @media screen and (max-width: 768px) {
            font-size: 12px!important;
            line-height: 15px;
        }
        @media screen and (max-width: 450px){
            font-size: 10px!important;
            line-height: 14px;
        }
    }
    & > div.footer {
        max-width: 90%;
        min-width: 300px;
        // font-family: 'Noto';
        strong{
            // font-family: 'Noto_Bold';
        }
    }
    a{
        color: $link_footer;
        // font-family: 'Noto_Bold';
    }
    .reglement_footer{
        text-decoration: underline;
        font-weight: bold;
        cursor: pointer;
    }
}