#builder-template-scope-book{
    .not-displayed{
        display: none;
    }
    .gift_list_container{
        // max-width: 1230px;
        margin: auto;
        position: relative;
        overflow: hidden;
        z-index: 3;
        background: url('./image/bg-jeu.jpg');
        background-position: top center;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: #0EA29D;
        &.cart{
            max-width: 100%;
            background: none;
            .header_cart{
                padding: 0;
                background: white;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                &.sticky {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    z-index: 101;
                }
                .container{
                    width: calc(1230px - 40px);
                    max-width: 100%;
                    margin: auto;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                    padding: 0 20px;
                    .logo {
                        max-width: 170px;
                        max-height: 80px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    @media screen and (max-width: 768px){
                        justify-content: flex-end;
                        height: 100px;
                        .wrapper_header_left{
                            .homeIcon{
                                position: absolute;
                                left: 20px;
                                margin-left: 0 !important;
                            }
                        }
                        .logo{
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }
                        .cart_wrapper{
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                    .wrapper_header_left{
                        display: flex;
                        align-items: center;
                        .homeIcon{
                            margin-left: 40px;
                            cursor: pointer;
                            svg{
                                fill: #0D4119;
                                width: 30px;
                                height: 30px;                            
                            }
                        }
                    }
                    .cart_wrapper{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        cursor: pointer;
                        .debris {
                            display: none;
                            position: absolute;
                            width: 28px;
                            height: 28px;
                            background-color: #ED7424;
                            opacity: 1.0;
                            overflow: hidden;
                            border-radius: 8px;
                        }
                        .cart-icon{
                            position: relative;
                            z-index: 10;
                            .product_qty{
                                position: absolute;
                                background: #ED7424;
                                padding: 1px 5px;
                                vertical-align: top;
                                margin-left: -10px;
                                font-size: 9px;
                                color: white;
                                padding-left: 5px;
                                padding-right: 5px;
                                border-radius: 9px;
                                right: -5px;
                                top: -5px;
                            }
                        }
                        span{
                            margin-left: 9px;
                            color: #0D4119;
                            text-transform: uppercase;
                            font-size: 13px;
                            line-height: 18px;
                            font-family: 'Roboto', sans-serif;
                            font-weight: bold;
                            @media screen and (max-width: 768px){
                                display: none;
                            }
                        }
                    }
                }
        
            }
            .wrapper_container{
                max-width: 1230px;
                margin: auto;
                overflow: hidden;
                position: relative;
                padding-bottom: 40px;
                .title{
                    padding: 60px 100px 40px 100px;
                    margin: auto;
                    margin-top: 0 !important;
                    @media screen and (max-width: 768px){
                        padding: 90px 10px 40px 10px;
                        h1{
                            font-size: 25px !important;
                            line-height: 37px !important;
                        }
                        h2{
                            font-size: 40px !important;
                            line-height: 37px !important;
                            position: relative;
                        }
                    }
                    @media screen and (max-width: 767px){
                        h2{
                            max-width: none;
                        }
                    }
                    @media screen and (max-width: 500px){
                        // background-size: contain !important;
                        padding: 59px 10px 41px 10px;
                        h1{
                            font-size: 18px !important;
                            line-height: 28px !important;
                        }
                        h2{
                            font-size: 27px !important;
                            line-height: 28px !important;
                            max-width: none;
                            position: relative;
                        }
                    }
                    @media screen and (max-width: 345px){
                        h1{
                            font-size: 18px !important;
                            line-height: 28px !important;
                        }
                        h2{
                            font-size: 24px !important;
                            line-height: 25px !important;
                        }
                    }
                    h1{
                        font-family: 'Roboto_Condensed', sans-serif;
                        font-weight: bold;
                        position: relative;
                        text-shadow: 0px 4px 13px #0000006C;
                    }
                    h2{
                        font-family: 'Roboto_Condensed', sans-serif;
                        font-weight: bold;
                        margin: 0;
                        text-shadow: 0px 4px 13px #0000006C;
                    }   
                }
                .wrapper_card{
                    position: unset;
                    .card_container{
                        background: none;
                        padding: 0;
                        .filters_wrapper{
                            margin-top: 0;
                            .flipBook{
                                background: none;
                                padding: 0;
                                .wrapper_text{
                                    display: none;
                                }
                                .container{
                                    .container_choices{
                                        display: none;
                                    }
                                }
                                .wrapperButton{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.readOnly{
            max-width: 100%;
            background: none;
            .ribbon_top_left{
                display: none;
            }
            .ribbon_bottom_right{
                display: none;
            }
            .title{
                display: none;
            }
            .title_filter{
                display: none;
            }
            .list_filters{
                display: none;
            }
        }
        .ribbon_top_left{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            @media screen and (max-width: 767px){
                img{
                    width: 100px;
                }
            }
            @media screen and (max-width: 450px){
                img{
                    width: 80px;
                }
            }
        }
        .ribbon_bottom_right{
            position: absolute;
            bottom: 0;
            right: 0;
            display: flex;
            z-index: 1;
            img{
                position: relative;
                bottom: 0;
                @media screen and (max-width: 767px){
                    height: 200px;
                }
                @media screen and (max-width: 600px){
                    height: 150px;
                }
            }
        }
        .title{
            position: relative;
            z-index: 2;
            text-align: center;
            padding: 24px 100px 28px;
            @media screen and (max-width: 767px){
                padding: 24px 20px 28px;
            }
            h1{
                font-family: 'Cera_Bold';
                font-size: 25px;
                line-height: 36px;
                color: white;
                margin: 0;
                @media screen and (max-width: 767px){
                    font-size: 35px;
                    line-height: 32px;
                }
                @media screen and (max-width: 600px){
                    font-size: 24px;
                    line-height: 25px;
                }
                @media screen and (max-width: 450px){
                    font-size: 15px;
                    line-height: 16px;
                }
            }
            h2{
                font-family: 'Cera_Normal';
                font-size: 25px;
                line-height: 36px;
                color: white;
                margin: 0;
                font-weight: normal;
                // margin-top: 16px;
                @media screen and (max-width: 767px){
                    font-size: 16px;
                    line-height: 25px;
                    max-width: 550px;
                    margin: auto;
                    margin-top: 8px;
                }
                @media screen and (max-width: 600px){
                    font-size: 14px;
                    line-height: 18px;
                    max-width: 390px;

                }
                @media screen and (max-width: 450px){
                    font-size: 12px;
                    line-height: 14px;
                    max-width: 254px;
                }
            }
        }
        .wrapper_card{
            padding: 0 18px;
            margin-bottom: 0;
            position: relative;
            z-index: 2;
            min-height: 43vw;
            @media screen and (max-width: 767px){
                margin-bottom: 50px;
            }
            @media screen and (max-width: 600px){
                padding: 0;
            }
            .card_container{
                max-width: 1056px;
                margin: auto;
                background: white;
                padding: 12px;
                @media screen and (min-width: 1921px){
                    max-width: 80%; 
                }
                @media screen and (max-width: 600px){
                    padding: 12px 0; 
                }
                .filters_wrapper{
                    text-align: center;
                    .container-filters-age{
                        @media screen and (max-width: 600px){
                            width: 100% !important;
                        }
                    }
                    .title_filter{
                        font-family: 'Ubuntu_Bold';
                        font-size: 20px;
                        line-height: 24px;
                        margin-bottom: 24px;
                        font-weight: 500;
                        @media screen and (max-width: 450px){
                            font-size: 15px;
                            line-height: 17px;
                        }
                    }
                    .list_filters{
                        max-width: 100%;
                        margin: 0 32px;
                        text-align: left;
                        .filter_block{
                            cursor: pointer;
                            display: inline-block;
                            margin: 2px;
                            width: 100%;
                            &:hover{
                                filter: brightness(115%);
                            }
                            img{
                                width: 100%;
                            }
                        }
                    }
                    .list_filters_age{
                        margin: 0 32px; 
                        button{
                            background: #FFCF00;
                            border-radius: 24px;
                            border: 0;
                            box-shadow: 0px 3px 0px #524200;
                            font-size: 16px;
                            line-height: 1.2;
                            color: #032C2A;
                            font-family: 'Cera_Bold';
                            margin: 0;
                            margin-bottom: 8px;
                            padding: 4px 10px;
                            cursor: pointer;
                            &.selected{
                                background: #FF9F06;
                            }
                            &:hover{
                                filter: brightness(115%);
                            }
                            @media screen and (max-width: 700px){
                                font-size: 12px;
                                margin: 4px;
                            }
                            @media screen and (max-width: 400px){
                                font-size: 10px;
                                line-height: 1;
                                height: 29px;
                                margin: 4px 1px;
                            }
                        }
                    }
                }
            }
        }
        // @media screen and (max-width: 749px){
        //     background: url('./image/bg-vert-mobile.png');
        //     background-position: top center;
        //     background-size: contain;
        //     background-repeat: no-repeat;
        // }
    }
    div.swiper-button-prev-filters, div.swiper-button-next-filters, div.swiper-button-prev-age, div.swiper-button-next-age{
        &:after{
            color: #D70008;
            font-size: 25px;
            font-weight: bold;
        }
        @media screen and (max-width: 700px){
            &:after{
                font-size: 16px;
            }
        }
    }
    div.swiper-button-prev-filters{
        left: 0px;
    } 
    div.swiper-button-next-filters{
        right: 0px;
    }
    div.swiper-button-disabled{
        display: none;
    }
    div.swiper-button-prev-age, div.swiper-button-next-age{
        @media screen and (min-width: 850px){
            display: none;
        }
        @media screen and (max-width: 493px){
            top: 50% !important;
        }
    }
    div.swiper-button-prev-age{
        left: 0px;
        top: 15px;
    } 
    div.swiper-button-next-age{
        right: 0px;
        top: 15px;
    }
    :not(.topHomepage) .bottom_section{
        background: url('./image/vague-desktop.svg');
        background-position: 0px 80px;
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 30px;
        @media screen and (max-width: 1300px) {
            background-position: 0px 60px;
        }
        @media screen and (max-width: 1050px) {
            background-position: top center;
        }
        @media screen and (max-width: 800px) {
            background: url('./image/vague-mobile.svg');
            background-position: 0px 40px;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .container_bottom{
            width: 1300px;
            max-width: 100%;
            margin: 50px auto 20px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 0;
            .firstPart{
                position: relative;
                padding-right: 6.5vw;
                img{
                    &:first-child{
                        width: 12vw;
                    }
                    &:last-child{
                        width: 6.5vw;
                        position: absolute;
                        right: 40px;
                        top: 10px;
                    }
                }
            }
            .text{
                padding-top: 100px;
                max-width: 650px;
                text-align: center;
                font-family: 'Cera_Black_Italic';
                transform: matrix(1, -0.09, 0.09, 1, 0, 0);
                p{
                    font-size: 1.9vw;
                    line-height: 1.2;
                    color: #032C2A;
                    white-space: nowrap;
                    span{
                        color:#0EA29D;
                    }
                }
            }
            .lastPart{
                padding-left: 50px;
                img{
                    width: 16.5vw;
                }
            }
            @media screen and (max-width: 1050px) {
                .text{
                    padding-top: 40px;
                }
            }
            @media screen and (max-width: 800px) {
                display: flex;
                flex-wrap: wrap;
                &>div{
                    display: flex;
                    box-sizing: border-box;
                    width: 50%;
                    align-items: center;
                }
                .firstPart{
                    order: 0;
                    justify-content: center;
                    padding: 0;
                    img{
                        &:first-child{
                            width: 25vw;
                        }
                        &:last-child{
                            width: 17vw;
                            right: 0;
                        }
                    }
                }
                .lastPart{
                    order: 0;
                    padding: 0;
                    justify-content: center;
                    img{
                        width: 34vw;
                    }
                }
                .text{
                    order: 1;
                    justify-content: center;
                    padding-bottom: 40px;
                    width: 100%;
                    p{
                        font-size: 4.5vw;
                    }
                }
            }
            @media screen and (max-width: 500px) {
                &>div{
                    margin-top: -5%;
                }
            }
        }
    }
}