#builder-template-scope-game.lego_toulouse {

  * {
    box-sizing: border-box;
  }

  header {
    width: 100%;
    .banner{
      font-family: "Cera_Black_Italic", sans-serif;
      p{
        font-size: 12px !important;
      }
    }
  }

  .preheader {

    div.div:first-child {
      padding-left: 0;

      @media screen and (max-width: 600px) {
        display: block !important;
      }
    }

    > div.div img {
      max-height: unset;
    }
  }

  .first {
    padding-top: 15px;

    @media all and (max-width: 500px) {
      padding: 25px !important;
      padding-top: 16px !important;
    }

    h1 {
      margin-bottom: 12px;
      margin-top: 0;
      font-family: "Cera_Black_Italic", sans-serif;
      font-size: 30px;
      @media all and (max-width: 500px) {
        font-size: 18px !important;
      }
    }
    h2{
      margin-top: 0;
      font-size: 13px !important;
    }

    form.complete_form {
      display: flex;
      flex-wrap: wrap;
      column-gap: 30px;
      row-gap: 14px;
      margin-top: 15px;
      
      @media all and (max-width: 500px) {
        column-gap: 15px;
      }

      > input {
        margin: 0;

        &:nth-of-type(1) {
          width: calc(50% - 30px) !important;
          
          @media all and (max-width: 500px) {
            width: calc(50% - 15px) !important;
          }
        }

        &:nth-of-type(2) {
          width: 50% !important;
        }
         
         &:nth-of-type(3),
         &:nth-of-type(4) {
          width: 100%;
        }
      }

      .consentContainer {
        margin-top: 0;
        margin-bottom: 0;
        .consent{
          align-items: flex-start;
        }
        label{
          font-size: 10px!important;
        }
      }

      .button {
        width: 100%;
        border-radius: 5px;
        border: none !important;
        font-family: "Cera_Black_Italic", sans-serif;
      }
    }
  }

  .amazing-bloc {

    .onlyDesktop {
      width: auto !important;
    }

    p {
      padding-left: 30px;
      padding-right: 30px;
    }

    .container-content {
      background-size: 38px 42px !important;
      background-position: 93% 8% !important;
      
      @media screen and (max-width: 650px) {
        background-position: 93% 16% !important;
      }

      @media screen and (max-width: 450px) {
        background-position: 93% 25% !important;
      }

      @media screen and (max-width: 340px) {
        background-position: 93% 35% !important;
      }
    }
  }

  .paragraph-weight-bold {
    font-family: "Cera_Bold", sans-serif;
  }

  .cera-italic-bold {
    font-family: "Cera_Black_Italic", sans-serif;
  }

  .image-erreur {
    max-width: 1000px;
    width: 100%;
  }
}
