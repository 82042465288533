.first{
    h1{
        margin: 10px 0;
        max-width: 100%;
        max-width: max-content;
        margin: auto;
        strong{
            font-weight: 900;
        }
    }
    form{
        .verification{
            color: #FF6565;
            margin-bottom: 12px;
        }
        &>label{
            width: calc(70% + 16px);
            max-width: 500px;
            margin: auto;
            display: block;
        }
        div{
            button{
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.inputZone{
                margin-top: 5px;
                margin-bottom: 15px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                flex-wrap: wrap;
                &> *{
                    display: flex;
                    align-items: center;
                    width: auto;
                    margin: 0;
                    padding: 0 10px;
                    min-height: 40px;
                    height: inherit;
                    border-radius: 0;
                }
                input{
                    width: 70%;
                    max-width: 500px;
                }
            }
            &.consent{
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                input{
                    margin: 0 10px 0 0;
                }
                &.error{
                    input{
                        outline: 1px solid #FF6565
                    }
                }
            }
        }
        &.complete_form{
            width: 100%;
            max-width: 600px;
            > label{
                color: black;
                display: none;
                margin-left: 5px;
                text-align: center;
                width: 100%;
                @media screen and (max-width: 700px) {
                    display: block;
                }
            }
            &>input{
                display: inline-block;
                margin: 5px;
                padding-left: 10px;
                vertical-align: middle;
                &[type="date"]{
                    padding-top: 1px;
                    padding-bottom: 1px;
                }
                &:nth-of-type(1), &:nth-of-type(2){
                    width: calc(50% - 29px);
                }
                &:nth-of-type(3){
                    width: calc(70% - 29px);
                }
                &:nth-of-type(4){
                    width: calc(30% - 37px);
                    padding-right: 10px;
                }
                &::placeholder{
                    font-size: 14px;
                    text-transform: inherit;
                }
                @media screen and (max-width: 700px) {
                    width: calc(100vw - 35px) !important;
                    &:nth-of-type(4){
                        padding-right: 2px;
                    }
                }
            }
            .consentContainer{
                padding: 0 5px;
                margin-top: 10px;
                margin-bottom: 20px;
                display: flex;
                justify-content: space-around;
                @media screen and (max-width: 700px) {
                    flex-direction: column;
                    .consent{
                        margin: 5px 0;
                    }
                }
            }
            button{
                width: auto;
                margin: 5px auto!important;
            }
        }
        .boxCheckbox{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            &>div{
                margin-top: 6px;
            }
        }
    }
    @media screen and (max-width: 700px){
        h1{
            font-size: 30px!important;
        }
        h2{
            margin-bottom: 8px;
            padding-bottom: 0;
            br{
                display: none;
            }
        }
        &>div{
            padding-left: 10px!important;
            padding-right: 10px!important;
            background-size: 700px!important;
        }
        form div.inputZone input{
            width: 100%;
        }
    }
    @media screen and (max-width: 500px){
        justify-content: center!important;
        min-height: 450px!important;
        padding: 25px 0!important;
        h1{
            font-size: 24px!important;
        }
        h2{
            font-size: 16px!important;
            margin-bottom: 10px;
        }
        input, button{
            font-size: 16px!important;
        }
        form label{
            font-size: 14px!important;
        }
    }
    @media screen and (max-width: 400px){
        h1{
            font-size: 24px!important;
        }
        h2{
            font-size: 16px!important;
        }
        form label{
            font-size: 13px!important;
        }
    }
}

.withImageSide{
    display: flex;
    .contentCenter{
        width: 70%;
        @media screen and (max-width: 1000px) {
            width: 100%; 
        }
    }
    .imageSide{
        width: 30%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        @media screen and (max-width: 1000px) {
            display: none;
        }
    }
}

.game_index{
    .popup.play.game{
        button.goToGame{
            margin-top: 25px;
        }
    }
}