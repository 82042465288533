.demo {
    padding: 50px;

    .panel-summary {
        background-color: #ddd;
        font-weight: bold;
    }

    .panel-details {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .palette_button{
        margin: 10px;
        min-width: 230px;
    }
}