
.preheader{
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 60px;
    &>div.div{
        display: inline-flex;
        align-items: center;
        &:first-child{
            padding-left: 10px;
            @media screen and (max-width: 600px) {
                display: none;
            }
        }
        a{
            text-decoration: none;
            height: 100%;
            width: 100%;
            display: flex;
            position: relative;
            p{
                position: relative;
                z-index: 1;
            }
        }
        p{
            text-transform: uppercase;
            max-width: 240px;
            padding: 10px;
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // font-family: 'Noto_Bold';
            @media screen and (max-width: 750px){
                font-size: 16px!important;
            }
            @media screen and (max-width: 450px){
                font-size: 14px!important;
            }
        }
        img{
            max-height: 90px;
        }
    }
}
