.link-banner {
    width: 100%;

    .image {
        width: 100%;
        height: 400px;
        background-size: cover;
        display: flex;
        flex-direction: column;
        background-repeat: no-repeat;
        background-position-y: center;
        position: relative;

        .logo_small {
            position: absolute;
            top: 4%;
            right: 3%;
            width: 120px;
            display: none;
        }

        @media screen and (max-width: 450px) {
            .logo_small {
                display: block;
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            position: relative;
            left: -30%;

            .title {
                position: relative;
                text-align: center;
                font-size: 42px;
                line-height: 34px;
                overflow: hidden;
                margin-bottom: 0;
    
                h1 {
                    font-family: 'Cera Pro', sans-serif;
                    display: inline;
                    font-weight: bold;
                }

                @media screen and (max-width: 1200px) {
                    h1 {
                        font-size: 42px !important;
                    }
                }

                @media screen and (max-width: 768px) {
                    h1 {
                        font-size: 32px !important;
                        margin-left: 20px;
                    }
                }

                @media screen and (max-width: 375px) {
                    h1 {
                        font-size: 24px !important;
                        margin-left: 20px;
                    }
                }
            } 
        }

        @media screen and (max-width: 997px) {
            .text {
                left: -25%;
            }
        }

        @media screen and (max-width: 768px) {
            .text {
                left: -15%;
            }
        }

        @media screen and (max-width: 450px) {
            .text {
                left: 0%;
            }
        }

        .button {
            color: #212121;
            margin: 10px 0;
            min-width: 165px;
            width: 100%;
            height: 65px;
            text-align: center;

            button {
                font-weight: bold !important;
                font-family: 'Cera Pro', sans-serif;
                border: 2px solid #fff;
    
                &:hover {
                    color: #000 !important;
                    background-color: #fff !important;
                    opacity: 1 !important;
                }
            }

            @media screen and (max-width: 768px) {
                button {
                    padding: 4px 24px !important;
                    margin-left: 20px;
                }
            }

            @media screen and (max-width: 425px) {
                button {
                    padding: 4px 24px !important;
                    font-size: 14px !important;
                }
            }

            @media screen and (max-width: 375px) {
                button {
                    padding: 4px 16px !important;
                    font-size: 14px !important;
                }
            }
        }
    }
}