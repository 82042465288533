#builder-template-scope-game.chasse_aux_codes_2022, #builder-template-scope-game.chasse_aux_codes_2022_-_cdiscount{
    max-width: 1188px;
    font-family: "Mont", sans-serif;
    .preheader{
        background-size: contain!important;
        background-position: center top!important;
        position: absolute!important;
        max-width: 1188px!important;
        z-index: 2;
        height: 240px;
    }
    .first{
        padding-top: 100px!important;
        h1{
            max-width: 90%;
            width: 710px;
            @media screen and (max-width: 700px){
                font-size: 16px!important;
            }
        }
        &>div{
            padding: 18px 25px;
            max-width: calc(100% - 50px);
        }
    }
    div.regles{
        max-width: 90%;
        .list_regles{
            justify-content: flex-start;
            padding: 0;
        }
        .regle{
            width: calc(100% / 2 - 3px);
            &>img{
                margin: 0;
            }
            .description > div{
                text-align: start;
                max-width: 100%;
                margin: 15px 0 0;
            }
            @media screen and (max-width: 820px){
                width: 100%;
                align-items: flex-start;
                .description > div{
                    margin-top: 0;
                }
            }
        } 
    }
    div.results section.win{
        .result_div > div{
            padding: 200px 30px 100px!important;
            max-width: 600px;
            margin: auto;
            &>p{
                &:first-of-type{
                    margin-bottom: 12px;
                }
            }
            @media screen and (max-width: 1000px) {
                &>p{
                    font-size: 20px!important;
                    &:first-of-type{
                        font-size: 48px!important;
                    }
                    &:last-of-type{
                        font-size: 16px!important;
                    }
                }
            }
            @media screen and (max-width: 900px){
                h1{
                    font-size: 20px!important;
                }
            }
            @media screen and (max-width: 700px){
                background-size: 680px!important;
                &>p{
                    &:first-of-type{
                        font-size: 40px!important;
                    }
                    &:last-of-type{
                        font-size: 14px!important;
                    }
                }
            }
            @media screen and (max-width: 500px) {
                background-size: 630px!important;
                background-position: center top!important;
                padding: 160px 10px 80px!important;
                &>p, h1{
                    font-size: 14px!important;
                }
            }
            @media screen and (max-width: 400px){
                &>p{
                    &:first-of-type{
                        font-size: 36px!important;
                    }
                }
            }
        }
        img.lot{
            display: none;
        }
        a.reglement{
            font-weight: bold;
            text-transform: uppercase;
            text-decoration: none;
        }
        @media screen and (max-width: 800px){

        }
    }
    .social.complete{
        width: auto;
        a{
            flex-direction: column;
            width: 170px;
            text-decoration: none;
        }
        .container-picto{
            max-width: inherit;
            max-height: inherit;
            img{
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    .title{
        @media screen and (max-width: 700px) {
            font-size: 36px!important;
            padding-top: 0!important;
        }
        @media screen and (max-width: 500px) {
            font-size: 24px!important;
        }
    }
    .info-box .container-content{
        @media screen and (max-width: 450px) {
            padding-top: 130px!important;
            padding-bottom: 80px!important;
        }
    }
    .with-share .container-content{
        &>div:first-child .title{
            @media screen and (max-width: 700px) {
                font-size: 16px!important;
                padding-bottom: 0!important; 
            }
            @media screen and (max-width: 500px) {
                font-size: 16px!important;
            }
        }
    }
}



#builder-template-scope-game.jeu-concours_wishlist_de_noel_2021, #builder-template-scope-game.jeu-concours_wishlist_de_noel_2021_micromania{
    background-image: inherit!important;
    background: #071242;
    font-family: "Mont", sans-serif;
    .imageAbsolute{
        width: 1180px;
        max-width: 70%;
        min-width: 360px;
        z-index: -1;
        top: 0;
    }
    div.containerImage{
        position: absolute;
        z-index: 1;
    }
    .first{
        padding-top: 200px!important;
        padding-bottom: 120px!important;
        img.logo{
            max-width: 65%!important;
            margin-bottom: 50px;
        }
        form{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .inputZone{
                input{
                    width: 70%;
                }
                button{
                    font-weight: bold!important;
                }
            } 
        }
        @media screen and (max-width: 767px){
            padding-top: 100px!important;
            img.logo{
                max-width: 95%!important;
            }
        }
        @media screen and (max-width: 500px){
            padding-top: 0px!important;
            form{
                max-width: 95%;
                .inputZone{
                    justify-content: flex-start;
                    input{
                        width: calc(100% - 113px);
                    }
                    button{
                        font-size: 14px!important;
                    }
                } 
            } 
        }
    }
    .how-container{
        margin-top: -120px;
        padding-bottom: 200px;
        padding-top: 160px;
        .how{
            .regles{
                width: 100%;
                max-width: 100%;
                padding-top: 10px!important;
                h2{
                    text-shadow: 0px 0px 14px #3CBCEDB3;
                }
                .description{
                    font-weight: bold;
                    strong{
                        color: #E6CB60;
                    }                
                }
                @media screen and (max-width: 820px){
                    .regle > img{
                        width: 60px;
                    }
                }
            }
            .dotations{
                width: 90%;
                .dotation{
                    img{
                        max-height: 170px;
                        margin-bottom: 20px;
                    }
                    @media screen and (min-width: 1000px){
                        p{
                            position: relative;
                            padding: 10px;
                            width: calc(100% - 20px);
                            &:after{
                                content: '•';
                                color: #E6CB60;
                                position: absolute;
                                font-size: 20px;
                                right: -8px;
                                top: 16px;
                            }
                        }
                        &:first-child p:before{
                            content: '•';
                            color: #E6CB60;
                            position: absolute;
                            font-size: 20px;
                            left: -8px;
                            top: 16px;
                        }
                    }
                    @media screen and (max-width: 1000px) {
                        p{
                            font-size: 14px!important;
                        }
                    }
                } 
                div.list{
                    align-items: flex-start;
                    &+div{
                        p{
                            text-align: center;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 1500px) {
            background-size: 1500px!important;
            padding-bottom: 150px;
        }
        @media screen and (max-width: 1275px){
            padding-bottom: 120px;
            .how{
                .regles{
                    padding-top: 150px;
                    .regle .description div{
                        font-size: 16px!important;
                    }
                }
            }
        }
        @media screen and (max-width: 991px) {
            .how{
                .regles{
                    padding-top: 150px;
                    h2, h2 strong, h2 span{
                        font-size: 24px!important;
                    }
                    
                }
                .dotations{
                    h2{
                        font-size: 46px!important;
                    }
                }
            }
        }
        @media screen and (max-width: 767px) {
            .how{
                .dotations{
                    h2{
                        font-size: 32px!important;
                    }
                }
            }
        }
        @media screen and (max-width: 550px) {
            .how{
                .dotations{
                    h2{
                        font-size: 24px!important;
                    }
                }
            }
        }
    }
    .amazing-bloc{
        
        .container-content{
            margin: auto;
            a{
                display: block;
                margin: 20px auto;
                font-weight: bold!important;
                width: 320px;
                max-width: 100%;
                text-decoration: inherit;
                text-align: center;
                @media screen and (max-width: 600px){
                    font-size: 14px!important;
                    width: 230px;
                }
            }
        }
    }
    .no-padding-top{
        padding-top: 0;
        margin-top: -130px;
    }
    .no-padding-bottom{
        padding-bottom: 0;
    }
    span.yellow{
        color: #E6CB60;
    }
    div.popup{
        z-index: 0;
        .popup_content > div.inscription{
            h2{
                font-weight: bold;
                text-shadow: 0px 0px 14px #3CBCEDB3;
                transform: matrix(1, -0.05, 0.05, 1, 0, 0);
                margin-bottom: 20px;
                &+p{
                    font-weight: bold;
                }
            }
            p{
                max-width: 550px;
            }
            h3{
                max-width: 100%;
                width: 100%;
                margin-bottom: 0;
            }
            button{
                font-weight: bold!important;
            }
        }
        .popup_content{
            h1{
                font-weight: bold;
                text-shadow: 0px 0px 14px #3CBCEDB3;
                transform: matrix(1, -0.05, 0.05, 1, 0, 0);
                margin-bottom: 20px;
                @media screen and (max-width: 1500px){
                    font-size: 40px!important;
                    width: 700px;
                }
                @media screen and (max-width: 991px){
                    width: 600px;
                    font-size: 30px!important;
                }
                @media screen and (max-width: 600px){
                    width: 100%;
                    font-size: 28px!important;
                }
                @media screen and (max-width: 450px){
                    width: 100%;
                    font-size: 24px!important;
                }
            }
            hr{
                display: none;
            }
            a.as_button{
                display: block;
                margin: auto;
                font-weight: bold;
                width: 300px!important;
                background-size: cover!important;
                @media screen and (max-width: 991px){
                    width: 220px!important;
                    font-size: 16px!important;
                }
            }
            &> div.inline_div{
                img{
                    width: 320px!important;
                    margin: 10px 0px 10px 0!important;
                }
                &+p{
                    margin-bottom: 20px;
                }
            } 
        }
        &.play{
            z-index: 0;
        }
        .overlay{
            background-color: rgba(0, 43, 99, 0.6);
            background-image: url("https://sony-api.spread.sinfin.fr/medias/619bc51986cc9344622187.png");
            background-position: center center;
            background-size: 1920px;
            background-repeat: no-repeat;
        }
        &.descriptionProduct{
            height: 100%;
            display: block;
            .overlay{
                position: absolute;
                height: 100%;
                background-image: inherit;
                background-color: rgba(7, 18, 66, 0.85);
            }
            .return{
                position: absolute;
                color: white;
                top: -120px;
                right: 80%;
                cursor: pointer;
            }
            .popup_content{
                transform: translate(-50%, 0);
                width: 90%;
                max-width: 800px;
                &> img{
                    position: relative;
                    max-width: 80%;
                    max-height: 250px;
                    height: auto;
                    @media screen and (max-width: 600px) {
                        max-width: 100%;
                    }
                }
                &>div video, &>div img{
                    max-width: 100%;
                    max-height: 500px;
                    display: block;
                    margin: 0 auto;
                }
                div{
                    color: white;
                    margin-bottom: 8px;
                    p{
                        font-size: 16px;
                    }
                }
            }
            .swiper-button-prev, .swiper-button-next{
                background: rgba(7, 18, 66, 0.85);
                border-radius: 50%;
                height: 50px;
                width: 50px;
                &:after{
                    font-size: 25px;
                    color: #E6CB60;
                }
            }
            button{
                background: #E6CB60;
                color: #071242;
                border: 0;
                display: block;
                font-weight: bold;
                text-align: center;
                width: 100%;
                margin-top: 12px;
                padding: 16px 20px;
                font-size: 14px;
                text-transform: uppercase;
                cursor: pointer;
                &:hover{
                    filter: brightness(0.9);
                }
                @media screen and (max-width: 650px){
                    padding: 12px;
                    font-size: 12px;
                }
                &:disabled{
                    background: none !important;
                    border: 1px solid #999999;
                    background-color: #cccccc !important;
                    color: #666666 !important;
                    cursor: auto;
                    opacity: 0.5;
                    &:hover{
                        filter: none;
                    }
                }
            }
        }
    }
    div.game_index section.game{
        background-position: center;
        background-size: cover;
        height: 850px;
    }
    .content_modal_share{
        background-color: #040B28;
        .title{
            color: white;
        }
        .list_email{
            .container-input{
                label{
                    color: white;
                }
                input{
                    border-color: rgb(230, 203, 96);
                }
            }
        }
        .add-button{
            background: #E6CB60;
            color: black;
            &:hover{
                background: #E6CB60;
                filter: brightness(0.9);
            }
        }
        .container-validation{
            .validation_button{
                background: #E6CB60;
                color: black;
                &:hover{
                    background: #E6CB60;
                    filter: brightness(0.9);
                }
            }
        }
        .button_close{
            .as_button{
                color: white;
            }
        }
    }
    .game_index.alreadyplayed.sony{
        .popup.play{
            .popup_content{
                text-align: center;
                h1{
                    background-image: none!important;
                    font-size: 82px!important;
                    width: 100%!important;
                    text-shadow: none;
                    font-size: 82px !important;
                    margin-left: auto;
                    margin-right: auto;
                    &+h3{
                        font-weight: bold;
                        text-shadow: 0px 0px 14px #3CBCEDB3;
                        transform: matrix(1, -0.05, 0.05, 1, 0, 0);
                        margin-bottom: 30px;
                        margin-top: 0;
                        width: 100%;
                        max-width: 1000px!important;
                        @media screen and (max-width: 1100px) {
                            width: 90%;
                            margin-left: auto;
                            margin-right: auto;
                        }
                        @media screen and (max-width: 768px) {
                            font-size: 25px !important;
                        }
                        @media screen and (max-width: 450px) {
                            font-size: 20px !important;
                        }
                    }
                }
                .inline_div{
                    display: none;
                }
                a.as_button{
                    display: none;
                }
            }
        }
    }
    .game_game{
        .game.wishlist{
            h1{
                text-shadow: 0px 0px 14px #3CBCEDB3;
            }
        }
    }
    .recap-wishlist{
        section{

            padding-top: 140px!important;
            padding-bottom: 140px!important;
            h1{
                text-shadow: 0px 0px 14px #3CBCEDB3;
                @media screen and (max-width: 991px){
                    font-size: 32px!important;
                }
                @media screen and (max-width: 500px){
                    font-size: 24px!important;
                    br{
                        display: none;
                    }
                }
                @media screen and (max-width: 360px){
                    font-size: 20px!important;
                }
            }
        }
        .win{
            .endGame{
                text-align: center;
                img{
                    max-width: 90%;
                    width: 600px;
                    margin: auto;
                }
                h3{
                    font-weight: bold;
                    text-shadow: 0px 0px 14px #3cbcedb3;
                    transform: matrix(1, -0.05, 0.05, 1, 0, 0);
                    margin-bottom: 30px;
                    margin-top: 0;
                    width: 1000px;
                    max-width: 90%;
                    margin-left: auto;
                    margin-right: auto;
                    @media screen and (max-width: 991px){
                        font-size: 32px!important;
                    }
                    @media screen and (max-width: 500px){
                        font-size: 24px!important;
                    }
                    @media screen and (max-width: 360px){
                        font-size: 20px!important;
                    }
                }
                p{
                    max-width: 90%;
                    display: block;
                    margin: auto;
                }
            }
        }
        div.recap{
            display: flex;
            margin: 35px auto;
            flex-direction: column;
            background: rgba(255,255,255,0.3);
            max-width: 1000px;
            width: 90%;
            padding: 24px;
            .products{
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                width: 100%;
                div{
                    margin: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    position: relative;
                    width: 200px;
                    img{
                        max-width: 90%;
                    }
                    p{
                        color: white;
                        font-size: 16px;
                        line-height: 1.1;
                        text-align: center;
                    }
                }
            }
            .button{
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                button{
                    border: 1px solid #E6CB60;
                    background: inherit;
                    color: #E6CB60;
                    text-transform: uppercase;
                    padding: 12px 50px;
                    font-size: 14px;
                    line-height: 1.2;
                    font-weight: bold;
                    cursor: pointer;
                    &.finished{
                        background-color: #E6CB60;
                        color: black;
                        &:hover{
                            filter: brightness(1.1);
                        }
                    }
                }
            }
        }
    }
}