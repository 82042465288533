#builder-template-scope-game.rainbow_six_extraction{
    font-family: 'Roboto', sans-serif;
    header .banner div p{
        font-family: 'Erbaum', sans-serif;
    }
    .first h1{
        font-family: 'Erbaum', sans-serif;
    }
    .amazing-bloc .container-content div:first-child p{
        font-family: 'Erbaum', sans-serif;
    }
    .how .dotations h2{
        font-family: 'Erbaum', sans-serif;
    }
    .popup_content h2{
        font-family: 'Erbaum', sans-serif;
    }
    .scratch-container{
        h1, h1 + p{
            font-family: 'Erbaum', sans-serif;
        }
    }
    .social p{
        font-family: 'Erbaum', sans-serif;
    }
    button{
        font-family: 'Erbaum', sans-serif;
        filter: brightness(1);
        transition: all ease 300ms;
        &:hover{
            filter: brightness(0.8);
        }
    }
    .first + div.popup{
        .overlay{
            background-size: 800px!important;
        }
        .popup_content{
            margin-left: 60%!important;
            top: 60%!important;
        }
        h2{
            font-family: 'Erbaum', sans-serif;
        }
        @media screen and (max-width: 500px){
            .overlay{
                background-size: 680px!important;
            }
            .popup_content{
                max-width: 280px;
                padding: 10px;
                top: 57.5%!important;
            }
        }
        @media screen and (max-width: 500px){
            .popup_content{
                max-width: 250px;
            }
        }
    }
    .game_index.alreadyplayed{
        .popup .popup_content{
            padding: 100px 10px 0!important;
            background-size: 100%!important;
            background-position: top left!important;
            width: calc(100% - 20px);
            max-width: 775px;
            &>h1, &>h3, &>h2, &>p{
                position: relative;
                left: 40%;
                width: 55%; 
            }
            &>h1{
                font-family: 'Erbaum', sans-serif;
            }
            &>h2{
                padding: 0;
                margin: 0;
            }
            hr{
                display: none;
            }
            div.inline_div{
                margin-bottom: 80px;
                margin-top: 120px!important;
                flex-direction: column;
                img{
                    max-width: 90%;
                    width: 360px;
                }
                p{
                    font-family: 'Erbaum', sans-serif;
                }
            }
            a.as_button{
                left: 0px;
                position: absolute;
                width: calc(100% - 24px)!important;
                bottom: 0;
                font-family: 'Erbaum', sans-serif;
            }
            @media screen and (max-width: 900px){
                background-size: 700px!important;
                background-position: top right!important;
                max-width: 400px;
                padding-bottom: 30px!important;
                h1{
                    font-size: 30px!important;
                }
                div.inline_div{
                    margin-bottom: 30px;
                    margin-top: 50px!important;
                    p{
                        font-size: 14px!important;
                    }
                }
                a.as_button{
                    font-size: 14px!important;
                }
            }
            @media screen and (max-width: 600px){
                &>h1, &>h3, &>h2, &>p{
                    position: relative;
                    left: 20%;
                    width: 70%; 
                }
                div.inline_div{
                    margin-bottom: 10px!important;
                }
            }
            @media screen and (max-width: 450px){
                padding-top: 50px!important;
                background-size: 630px !important;
                &>h1, &>h3, &>h2, &>p{
                    position: relative;
                    left: 10%;
                    width: 80%; 
                }
            }
        }
        &.ended_game{
            .popup .popup_content div.inline_div{
                @media screen and (max-width: 600px) {
                    margin-top: 90px!important;
                }
            }
        }
    }
    div.regles .regle .description div{
        font-size: 16px;
    }
    div.results section.win{
        padding-top: 80px;
        padding-bottom: 0;
        .result_div > div{
            padding: 40px 60px!important;
            background-size: 100% 100%!important;
            img.lot{
                position: relative;
            }
            h1{
                font-family: 'Erbaum', sans-serif;
            }
            p{
                font-family: 'Erbaum', sans-serif;
                &:last-child{
                    font-family: 'Roboto', sans-serif;
                }
            }
            @media screen and (max-width: 720px){
                padding: 40px 30px!important; 
            }
            @media screen and (max-width: 500px) {
                background-size: auto 100%!important;
            }
        }
    }
    div.results section.loose{
        padding: 150px 0 0!important;
        .result_div{
            padding: 0 50px 100px!important;
            &>div{
                background-size: 100% 100%!important;
                padding: 100px!important;
                &>*{
                    font-family: 'Erbaum', sans-serif;
                }
            }
            img{
                position: absolute;
                bottom: calc(100% - 41px);
                left: 50%;
                transform: translateX(-50%);
            }
        }
        @media screen and (max-width: 750px) {
            padding: 50px 0 0!important;
            .result_div{
                padding: 0 10px 50px!important;
                &>div{
                    padding: 100px 30px!important;
                    h1{
                        font-size: 16px!important;
                    }
                }
                img{
                    display: none!important;
                }
            }
        }
    }
}





#builder-template-scope-game.mario_lapins_cretins{
    font-family: 'Soleil', sans-serif;    
    @media screen and (max-width:600px) {   
        br{
            display: none;
        }
    }    
    header{
        height:40px;   
        font-family: 'Soleil', sans-serif;    
        font-weight: bold;
        @media screen and (max-width:600px) {   
            padding: 10px 10px;
        }   
    }
    .logo{
        display: none !important;
    }

    .typeSection{
        background-position: center top  !important;   
        justify-content: center;
        .popup{
            .overlay{
                position: absolute;
            }
            .popup_content{
                width: 740px;
                .inscription{
                    p{
                        max-width: none !important;
                    }
                    h2,strong{
                        font-family: 'Boldfinger',sans-serif;
                        font-weight: 500;
                    }
                    
                }
                .button{
                    width: 190px;
                    background-size: contain !important;         
                    margin: 140px auto 20px auto;                               
                }
                h3{
                    margin: 0;
                }
            }
        }
        .withoutImageSide{
            .contentCenter{
                .first {
                    padding: 100px 0 50px 0 !important;
                    >div{
                        h1{
                            font-family: 'Boldfinger',sans-serif;
                            font-weight: 500;
                        }
                        h2{
                            margin-bottom: 70px;
                        }
                        position: initial !important;
                        #form_login{
                            label{                            
                                text-align: center;
                            }
                            #inputEmail{
                                width: 100%;
                                height: 56px;
                                max-width: none;
                                &::placeholder {                                         
                                    text-align: center;
                                    color: #2827E9;
                                    text-transform: none;
                                }
                                &:-ms-input-placeholder {                 
                                    text-align: center;
                                    color: #2827E9;
                                    text-transform: none;
                                }
                                &::-ms-input-placeholder {                        
                                    text-align: center;
                                    color: #2827E9;
                                    text-transform: none;
                                }
                            }
                            .inputZone{
                                margin-bottom: 5px;
                                .button{
                                    position: absolute;
                                    bottom: 50px;
                                    width: 400px;
                                    height: 76px;
                                    background-size: contain !important;
                                }
                            }
                            .boxCheckbox{
                                align-items: flex-start !important;
                            }
                        }

                    }
                }
            }
        }
    }
    .how{
        .title{
            font-family: 'Boldfinger',sans-serif;            
            font-weight: 500;
        }
        .regles{
            font-family: 'Boldfinger',sans-serif;
            font-weight: 500;
        }
    }      
      
    .lots{
        font-family: 'Boldfinger',sans-serif;
        font-weight: 500;
        background-size: cover !important;
        background-position: center bottom !important ;         
        .container-content{
            .title{
                @media screen and (max-width:700px) {
                    font-size: 21px !important;
                }       
            }
            .list{           
               img{                   
                   object-fit: contain;                   
               }
           }           

        }
    }
    .share{
        min-height: 160px;               
        .title{
            font-family: 'Boldfinger',sans-serif;
            text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.495);
            font-weight: 500;
            @media screen and (max-width:700px) {
                font-size: 20px !important;
            }       
        }
        background-position: center bottom !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .discover{        
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover !important;
        min-height: 550px;
        background-position: center bottom !important;
        @media screen and (min-width:1200px) {
            min-height: 750px;                        
        }
        .actionButton{            
            transform: translateY(100%);            
            width: 400px;
            min-height: 100px;
            background-size: contain !important;
            @media screen and (max-width:600px) {
                width: 300px;                         
            }
            @media screen and (max-width:400px) {
                width: 200px;                         
            }
        }
    }    
    .home_not_started{        
        p{
            position: absolute;            
            top: 65%;
            left: 50%;
            transform: translate(-50%,-50%);

        }
        .title{
            top: 50%;
        }
    }
    .socials_container{
        background-position: center bottom !important;
        .title{
            font-family: 'Boldfinger',sans-serif;     
            font-weight: 500;
        }
        .socials {
            .list_socials{
                display: flex;
                justify-content: space-between;
                @media screen and (min-width:1500px) {
                    padding: 0 100px;
                }
                @media screen and (max-width:1000px) {
                    flex-direction: column;                    
                    width: fit-content;
                    margin: auto;
                }
                .social.complete{
                    width: auto;
                    padding: 10px 0 0 0 !important; 
                    a{
                        text-decoration: none;
                        u{
                            text-decoration: underline;
                        }
                    }                   
                }
                img{
                    width: 56px;
                    height: 56px;
                    max-width: none;
                    max-height: none;
                }
                .text-button{
                    width: auto;
                    padding-left: 20px;
                    font-size: 20px !important;
                    font-family: 'Soleil', sans-serif;    
                    font-weight: bold;
                }
            }

        }
    }
    .results{
        .loose{
            padding: 200px 0 !important;
            background-size: cover !important;
            .result_div >div >p{                                
                font-family: 'Boldfinger',sans-serif;  
                font-weight: 500;
            }
            .result_div >div >h1>strong{                                
                font-family: 'Soleil', sans-serif;    
                font-weight: bold;              
            }
        }
        .win{
            padding: 280px 0 200px 0 !important;
            position: relative;
            background-size: cover !important;
            h1{
                color: white !important;
                font-family: 'Boldfinger',sans-serif;  
                font-weight: 500;
                font-size: 25px !important;
            }
            h2{
                position: absolute;
                top: 0;
                transform: translate(-50%,-250px);
                left: 50%;
                font-size: 31px !important;
                font-family: 'Boldfinger',sans-serif;  
                font-weight: 500;
            }
            .result_div > div > p:first-of-type{
                padding-bottom: 50px;
                @media screen and (max-width:1000px) {
                    font-size: 25px !important;
                }
            }
            .result_div > div {
                padding: 80px 20px 0 !important;
            }            
        }
    }
    .alreadyplayed.sony{
        .game{
            background-position: center bottom !important;
            height: 800px !important;
            .popup{
                background-color:transparent;  
                position: initial !important;
                .imageAbsolute{
                    top: 0;
                    height: 250px;
                    width: 450px;
                    object-fit: contain;
                    left: 50%;
                    transform: translateX(-50%);
                }             
                .popup_content{
                    height: auto;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    margin-left: 0 !important;
                    h1{
                        font-family: 'Boldfinger';
                        font-weight: 500;
                    }
                    h3,.center{
                        font-family: 'Soleil', sans-serif;    
                        font-weight: bold;                  
                    }
                   @media screen and (max-width:500px) {
                       padding: 0;
                       height: auto !important;
                   }
                   .inline_div, hr{
                    display: none;
                   }
               }

            }
        }
    }
    .alreadyplayed.ended_game{
        .game{
            background-size: cover !important;  
            background-position: center bottom !important;
            height: 800px !important;
            .popup{
                background-color:transparent;  
                position: initial !important;
                .imageAbsolute{
                    top: 0;
                    height: 250px;
                    width: 450px;
                    object-fit: contain;
                    left: 50%;
                    transform: translateX(-50%);
                }             
                .popup_content{
                    height: auto;
                    top: 50% !important;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    margin-left: 0 !important;
                    h1{
                        padding-bottom: 50px;
                        font-family: 'Boldfinger';
                        font-weight: 500;
                    }    
                    h2{
                        font-family: 'Soleil', sans-serif;    
                        font-weight: bold;     
                    }       
                    .inline_div, hr{
                        display: none;
                    }         
               }

            }          
        }
    }
    .alreadyplayed.not_started{
        .game{
            background-size: cover !important;  
            background-position: center bottom !important;
            height: 800px !important;
            .popup{
                background-color:transparent;  
                position: initial !important;
                .imageAbsolute{
                    top: 0;
                    height: 250px;
                    width: 450px;
                    object-fit: contain;
                    left: 50%;
                    transform: translateX(-50%);
                }             
                .popup_content{
                    height: auto;
                    top: 50% !important;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    margin-left: 0 !important;
                    h1{
                        padding-bottom: 50px;
                        font-family: 'Boldfinger';
                        font-weight: 500;
                    }  
                    h3{
                        font-family: 'Soleil', sans-serif;    
                        font-weight: bold;                 
                    }   
                    .inline_div, hr{
                        display: none;
                    }               
               }

            }          
        }
    }
    .game_index{
        .game{
            background-position: center top !important;
            .popup{
                background-color: rgba(39, 39, 233, 0.5);               
                .popup_content{
                   height: 450px;
                   .button{
                       width: 300px;
                       min-height: 80px;
                       background-size: contain !important;
                       margin-top: 150px;
                       @media screen and (max-width:900px) {
                        margin-top: 100px;  
                        }  
                        @media screen and (max-width:555px) {
                            margin-top: 70px;  
                        }  
                   }
               }            
            }
        }
    }
    .game_game .game{        
        .hidden{
            display: none !important;
        }
        .game-title{
            font-family: 'Boldfinger',sans-serif;
            font-weight: 500;
        }
        .popup{
            display: flex ;
            align-items: center;
            justify-content: center;
            .popup_content{
                top: auto;   
                left: 50%;
                margin: 0 20px;
                @media screen and (max-width:575px) {
                    padding-top: 0;                    
                }
                > .content_popup{
                    height: 100%;
                    display: grid;   
                    h1{
                        font-family: 'Boldfinger',sans-serif;
                        font-weight: 500;
                    }                 
                    img{
                        padding-top: 30px;
                        max-width: 271px;
                        margin: auto;
                    }
                    .goToGame{                                      
                        width: 400px;
                        min-height: 100px;
                        background-size: contain !important;
                        @media screen and (max-width:600px) {
                            width: 300px;                         
                        }
                        @media screen and (max-width:400px) {
                            width: 200px;                         
                        }
                    }
                }
                
            } 

        }        
        .endGame_popup{
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: rgba(39, 39, 233, 0.5);   
            display: flex !important;
            align-items: center;
            justify-content: center;             
            &_content{
                position: absolute;
                width: 600px; 
                height: 500px;    
                border-radius: 15px; 
                h1{
                    font-family: 'Boldfinger',sans-serif;
                    font-weight: 500;
                }
                @media screen and (max-width:700px) {
                    width: 90%; 
                    height: auto;
                    padding: 20px 0;
                }                  
                .video_container{
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    width:100%;
                    margin-top: 50px;
                    video{
                        border-radius: 15px;
                    }
                }        
            }
        }
    }
}