@import '../../../../node_modules/react-quill/dist/quill.snow.css';


.MuiOutlinedInput-root{
    border-radius: 0 !important;
    border-color: #C9CBCC;
}

.MuiRadio-colorPrimary.Mui-checked{
    color: #0273A5 !important;
}

.card-content .ql-editor{
    min-height: 500px;
}

.card-content > div{
    margin-bottom: 0;
}

.ql-editor {
    min-height: 200px;
    max-height: 400px;
    background-color: white;
    &::-webkit-scrollbar{
        width: 7px;
        height: 100%;
        display: block;
    }
    &::-webkit-scrollbar-track{
        background: #f7fafb;
        display: block;
    }
    &::-webkit-scrollbar-thumb{
        display: block;
        background-color: #C5DEEC ;
        border-radius: 6px;
        //border: 0px solid #CFD8DC;
    }
    @media screen and (max-width: 1450px){
        min-height: 100px;
    }
    @media screen and (max-width: 1280px){
        min-height: 80px;
    }
    @media screen and (max-width: 1000px){
        min-height: 60px;
    }
}

.editor-button {
    width: 30px;
    height: 30px;
    background-color: #ddd;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    user-select: none;

    &.active {
        border: solid 1px #aaa;
    }

    &.bold {
        font-weight: bold;
    }

    &.underline {
        text-decoration: underline;
    }

    &.italic {
        font-style: italic;
    }
}

.ql-toolbar.ql-snow {
    @media screen and (max-width: 1450px){
        padding: 4px;
    }
}

.ql-tooltip.ql-editing {
    left: 0 !important;
}

.singleSelectTree.react-dropdown-tree-select {
    @font-face {
        font-family: "Material Icons";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
            format("woff2");
    }
    .dropdown {
        width: 100%;

        .dropdown-trigger.arrow {
            width: 100%;
            box-sizing: border-box;
            &.top:after,
            &.bottom:after {
                content: "";
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 10px;
                width: 0; 
                height: 0; 
                border-left: 4.8px solid transparent;
                border-right: 4.8px solid transparent;
                border-top: 4.8px solid rgba(0, 0, 0, 0.54);
            }
        }

        .dropdown-content {
            width: 100%;
            z-index: 11;
            box-shadow: none;
            border: 1px solid #C9CBCC;
            .search {
                height: 40px;
                margin-bottom: 10px;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                width: 90%;
            }

            .no-matches {
                display: block;
                margin: 15px;
            }

            ul {
                margin: 15px;
            }
        }

        .dropdown-trigger {
            padding: 7px 14px;
            border-radius: 0;
            border: 1px solid #C9CBCC;
            .tag-item {
                margin-left: 0px;

                &:nth-child(2) {
                    display: none;
                }

                .placeholder {
                    font-size: 16px;
                }

                .tag {
                    background-color: inherit;
                    border: 0;
                    padding: 0;
                    font-size: 14px;
                    .tag-remove {
                        display: none;
                    }
                }
            }
        }
    }

    .checkbox-item, .radio-item {
        display: none;
    }
    .material-icons {
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
    }
    .dropdown-trigger > span:after {
        font-size: 12px;
        color: #555;
    }
}

.singleSelectTreeBottom.react-dropdown-tree-select {
    @font-face {
        font-family: "Material Icons";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
            format("woff2");
    }
    .dropdown {
        width: 100%;

        .dropdown-trigger.arrow {
            width: 100%;
            box-sizing: border-box;
            &.top:after,
            &.bottom:after {
                content: "";
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 10px;
                width: 0; 
                height: 0; 
                border-left: 4.8px solid transparent;
                border-right: 4.8px solid transparent;
                border-top: 4.8px solid rgba(0, 0, 0, 0.54);
            }
        }

        .dropdown-content {
            width: 100%;
            z-index: 11;
            box-shadow: none;
            border: 1px solid #C9CBCC;
            bottom: calc(100% + 5px);
            .search {
                height: 40px;
                margin-bottom: 10px;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                width: 90%;
            }

            .no-matches {
                display: block;
                margin: 15px;
            }

            ul {
                margin: 15px;
            }
        }

        .dropdown-trigger {
            padding: 7px 14px;
            border-radius: 0;
            border: 1px solid #C9CBCC;
            .tag-item {
                margin-left: 0px;

                &:nth-child(2) {
                    display: none;
                }

                .placeholder {
                    font-size: 16px;
                }

                .tag {
                    background-color: inherit;
                    border: 0;
                    padding: 0;
                    font-size: 14px;
                    .tag-remove {
                        display: none;
                    }
                }
            }
        }
    }

    .checkbox-item, .radio-item {
        display: none;
    }
    .material-icons {
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
    }
    .dropdown-trigger > span:after {
        font-size: 12px;
        color: #555;
    }
}

.multiSelectTreeBottom.react-dropdown-tree-select {
    /* fallback, until https://github.com/CompuIves/codesandbox-client/issues/174 is resolved */
    @font-face {
        font-family: "Material Icons";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
            format("woff2");
    }

    &.error{
        .dropdown .dropdown-trigger.arrow{
            border-color: #f44336;
        }
    }
    
    .material-icons {
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
    }
    .dropdown-trigger > span:after {
        font-size: 12px;
        color: #555;
    }
    
    .toggle {
        white-space: pre;
        margin-right: 4px;
        outline: none;
        position: absolute;
        top: 2px;
    &:after{
        content: "";
        border: solid #555;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
    }
    
    .toggle.collapsed::after {
    content: "";
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }
    
    
    /* checkbox styles */
    .checkbox-item {
        position: relative;
        width: 1rem;
        height: 1rem;
        margin-right: 0.75rem;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: 0;
        vertical-align: middle;
    }
    
    .checkbox-item:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        border: 2px solid #aaa;
        transition: all 0.3s ease-in-out;
    }
    
    .checkbox-item:checked:before {
        height: 50%;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        border-top-style: none;
        border-right-style: none;
        border-color: #0273A5;
    }
    .node{
        margin-left: 10px;
        position: relative;
        &[aria-checked="true"]{
            label{
                color: #0273A5;
            }
        }
        > label{
            margin-left: 20px;
        }
    }
    .dropdown {
        width: 100%;

        .dropdown-trigger.arrow {
            width: 100%;
            box-sizing: border-box;
            &.top:after,
            &.bottom:after {
                content: "";
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 10px;
                width: 0; 
                height: 0; 
                border-left: 4.8px solid transparent;
                border-right: 4.8px solid transparent;
                border-top: 4.8px solid rgba(0, 0, 0, 0.54);
            }
        }

        .dropdown-content {
            width: 100%;
            z-index: 11;
            bottom: calc(100% + 5px);
            box-shadow: none;
            border: 1px solid #C9CBCC;
            .search {
                height: 40px;
                margin-bottom: 10px;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                width: 90%;
            }

            .no-matches {
                display: block;
                margin: 15px;
            }

            ul {
                margin: 15px;
                .infinite-scroll-component{
                    max-height: 350px;
                }
            }
        }

        .dropdown-trigger {
            border-radius: 0;
           border: 1px solid #C9CBCC;
            padding: 7px 14px;
            .tag-item {
                margin-left: 0px;
                &:nth-last-child(n+2) ~ li:last-child{
                    display: none;
                }
            }
        }
    }
}

.multiSelectTree.react-dropdown-tree-select {
    /* fallback, until https://github.com/CompuIves/codesandbox-client/issues/174 is resolved */
    @font-face {
        font-family: "Material Icons";
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
            format("woff2");
    }

    &.error{
        .dropdown .dropdown-trigger.arrow{
            border-color: #f44336;
        }
    }
    
    .material-icons {
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
    }
    .dropdown-trigger > span:after {
        font-size: 12px;
        color: #555;
    }
    
    .toggle {
        white-space: pre;
        margin-right: 4px;
        outline: none;
        position: absolute;
        top: 2px;
    &:after{
        content: "";
        border: solid #555;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
    }
    
    .toggle.collapsed::after {
    content: "";
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }
    
    
    /* checkbox styles */
    .checkbox-item {
        position: relative;
        width: 1rem;
        height: 1rem;
        margin-right: 0.75rem;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: 0;
        vertical-align: middle;
    }
    
    .checkbox-item:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        border: 2px solid #aaa;
        transition: all 0.3s ease-in-out;
    }
    
    .checkbox-item:checked:before {
        height: 50%;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        border-top-style: none;
        border-right-style: none;
        border-color: #0273A5;
    }
    .node{
        margin-left: 10px;
        position: relative;
        &[aria-checked="true"]{
            label{
                color: #0273A5;
            }
        }
        > label{
            margin-left: 20px;
        }
    }
    .dropdown {
        width: 100%;

        .dropdown-trigger.arrow {
            width: 100%;
            box-sizing: border-box;
            &.top:after,
            &.bottom:after {
                content: "";
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 10px;
                width: 0; 
                height: 0; 
                border-left: 4.8px solid transparent;
                border-right: 4.8px solid transparent;
                border-top: 4.8px solid rgba(0, 0, 0, 0.54);
            }
        }

        .dropdown-content {
            width: 100%;
            z-index: 11;
            box-shadow: none;
            border: 1px solid #C9CBCC;
            .search {
                height: 40px;
                margin-bottom: 10px;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                width: 90%;
            }

            .no-matches {
                display: block;
                margin: 15px;
            }

            ul {
                margin: 15px;
                .infinite-scroll-component{
                    max-height: 350px;
                }
            }
        }

        .dropdown-trigger {
            border-radius: 0;
           border: 1px solid #C9CBCC;
            padding: 7px 14px;
            .tag-item {
                margin-left: 0px;
                &:nth-last-child(n+2) ~ li:last-child{
                    display: none;
                }
            }
        }
    }
}

.error-label {
    color: #f44336;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: soleil, sans-serif;
    font-weight: 400;
    line-height: 1.66;
}

.quill.error {
    .ql-toolbar, .ql-container {
        border-color: #f44336;
    }
}

.image-marker img{
    height:100%
}
.marker-data-container::-webkit-scrollbar-track{
    display: block;
}

.marker-data-container::-webkit-scrollbar-thumb{
    display: block;
    background-color: #AAB4BA;
}

.marker-data-container::-webkit-scrollbar{
    display: block;
}