.text-image {
    padding-top: 10px;
    padding-bottom: 10px;

    .text-image-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        
        .text {
            text-align: left;
            justify-content: center;
            display: flex;
            flex-direction: column;
            padding: 50px 65px;
    
            h2, 
            p {
                font-family: 'Cera Pro', sans-serif;
                font-weight: bold;
                text-align: left;
            }
    
            h2 {
                margin-bottom: 20px;
            }
    
            p {
                line-height: 32px !important;
            }
    
            .button {
                padding-left: 0;
                margin-top: 15px;
    
                button {
                    font-weight: 500 !important;
                    font-family: 'Cera Pro', sans-serif;
                    border: 2px solid #fff;
        
                    &:hover {
                        color: #000 !important;
                        background-color: #fff !important;
                        opacity: 1 !important;
                    }
                }
    
                @media screen and (max-width: 425px) {        
                    button {
                        font-size: 18px !important;
                        padding: 4px 18px !important;
                    }
                }
            }
        }
    
        @media screen and (max-width: 768px) {
            .text {
                padding: 25px;
            }
            h2 {
                font-size: 28px !important;
            }
    
            p {
                font-size: 14px !important;
            }
        }
        @media screen and (max-width: 425px) {
            .text {
                padding: 13px;
            }
            .text:nth-child(2n + 1) {
                padding: 13px;
                padding-left: 20px;
            }
            h2 {
                font-size: 24px !important;
            }
    
            p {
                font-size: 14px !important;
                font-weight: 500 !important;
            }
        }
    
        .image,
        .video {
            height: 574px;
            padding: 24px 16px;
    
            img,
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        
        @media screen and (max-width: 425px) {
            .image {
                padding: 13px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .text-image-content {
            flex-direction: column;
            flex-wrap: nowrap;
            margin-right: 0;
            margin-left: 0;

            .image,
            .text {
                max-width: 100%;
            }
        }
    }
}