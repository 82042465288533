.lineStyle{
    top: -44px;
}
.lineStyleOpen{
    top: 0
}
.seeMore{
    background: linear-gradient(rgba(243, 243, 243, 0.10) 0%, rgba(250, 250, 250, 0.60) 15%, rgb(250, 250, 250) 60%);
    width: 100%;
    position: relative;
    transition: all 0.2s ease-in-out;
    height: 50px;
}

.seeMoreText{
    cursor: pointer;
    margin-top: 30px !important;
    font-weight: bold !important;
    transition: all 0.2s ease-in-out;
}
.seeMoreText.close{
    margin-top: 40px !important;
}