.lego_mario_day#builder-template-scope-game, .lego_mario_day_micromania#builder-template-scope-game, .lego_mario_day_fnac#builder-template-scope-game, .lego_mario_day_lego#builder-template-scope-game{
    font-family: "Cera Pro", sans-serif;
    max-width: 1200px;
    margin: auto;
    .typeSection .home_reglement{
        @media screen and (max-width: 1000px){
            color:rgb(0, 0, 0)!important;
        }
    }
    .first{    
        padding: 0 0 50px 0!important;
        h1{
            strong{
                color: #E6202F;
            }
        }
        h2{
            em{
                color: #E6202F;
                font-style: normal;
            }
            @media screen and (max-width: 820px){
                p{
                    display: inline;
                }
            }
        }
        button, a{
            box-shadow: 0 5px 0 0 #1f8310;
            margin: 25px auto 70px;
            @media screen and (max-width: 600px) {
                padding: 8px 16px!important;
                margin: 8px auto 130px;
            }
            &.loading{
                height: 56px;
            }
        }
        a{
            box-shadow: 0 5px 0 0 #0079C1;
            text-decoration: none;
        }
        .inputZone{
            input{
                border: 2px solid #66C1F9!important;
                border-radius: 0!important;
                text-align: center;
            }
        }
        form{
            .boxCheckbox{
                max-width: 480px;
                margin: auto;
            }
        }
    }
    div.regles{
        width: 100%!important;
        .list_regles{
            padding-top: 10px;
        }
        .regle{
            background: white;
            border-radius: 40px;
            padding: 20px;
            margin: 8px;
            .description div p{
                line-height: 1.35!important ;
                @media screen and (max-width: 1250px){
                    font-size: 16px!important;
                }
                @media screen and (max-width: 820px){
                    font-size: 14px!important;
                }
            }
            @media screen and (max-width: 820px){
                padding: 10px;
                width: calc(100% - 40px);
                max-width: 360px;
                border-radius: 10px;
                margin: 10px auto;
            }
        }
    } 
    .withImageSide{
        justify-content: space-between;
        .imageSide{
            max-width: 400px;
        }
    }
    .how-play{
        margin-top: -120px;
        background-size: cover!important;
        .container-content{
            @media screen and (max-width: 820px){
                padding-bottom: 10px!important;
            }
            .title{
                text-shadow: rgb(0, 0, 0) 2px 0px 0px, rgb(0, 0, 0) 1.75517px 0.958851px 0px, rgb(0, 0, 0) 1.0806px 1.68294px 0px, rgb(0, 0, 0) 0.141474px 1.99499px 0px, rgb(0, 0, 0) -0.832294px 1.81859px 0px, rgb(0, 0, 0) -1.60229px 1.19694px 0px, rgb(0, 0, 0) -1.97998px 0.28224px 0px, rgb(0, 0, 0) -1.87291px -0.701566px 0px, rgb(0, 0, 0) -1.30729px -1.5136px 0px, rgb(0, 0, 0) -0.421592px -1.95506px 0px, rgb(0, 0, 0) 0.567324px -1.91785px 0px, rgb(0, 0, 0) 1.41734px -1.41108px 0px, rgb(0, 0, 0) 1.92034px -0.558831px 0px;
                @media screen and (max-width: 820px){
                    font-size: 30px!important;
                }
            }
        } 
    }
    .dotations-block{
        background-size: cover!important;
        .container-content{
            .title{
                @media screen and (max-width: 820px){
                    font-size: 30px!important;
                }
            }
        }
        &.mt-100{
            margin-top: -100px;
        }
    }
    .copyrightText{
        position: absolute;
        bottom: 120px;
        max-width: 150px;
        left: 10px;
    }
    div.popup{
        .overlay{
            height: 100vh;
        }
        .popup_content{
            border-radius: 30px;
            overflow: hidden;
            width: 80%;
            max-width: 700px;
            &> div.inscription{
                h2{
                    margin-top: 0;
                    margin-bottom: 24px;
                }
                h3{
                    max-width: none;
                }
                width: auto;
                p{
                    max-width: none;
                    font-size: 18px;
                    &:last-child{
                        max-width: 364px;
                    }
                }
                .custom_div{
                    > p {
                        max-width: none !important;
                    }
                }
            }
            @media screen and (max-width: 500px) {
                padding: 20px 0;
                width: 95%;
            }
        } 
    }
}
.lego_mario_day_lego#builder-template-scope-game{
    div.popup{
        position: fixed;
    }
}