.builder-panel {
    background-color: white;
    position: relative;
    width: 336px;
    border-right: solid 1px #eee;

    .panel-content {
        overflow: auto;

        .panel-header {
            padding: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px;
            position: relative;
    
            &-cross {
                font-size: 32px;
                position: absolute;
                left: 16px;
                top: calc(50% - 2px);
                transform: translateY(-50%);
                margin-top: -2px;
                cursor: pointer;
                transition: all ease-in-out .1s;

                &:hover {
                    opacity: 0.7;
                }
            }
    
            &-label {
                font-size: 20px;
                font-weight: bold;
            }

            &-edition {
                font-size: 16px;
                font-weight: bold;
                cursor: pointer;
                transition: all ease-in-out .1s;

                &:hover {
                    opacity: 0.7;
                }

                .panel-header-back svg {
                    font-size: 14px;
                    position: relative;
                    top: 1px;
                    cursor: pointer;
                }
            }
        }
    
        .panel-tabs {
            display: grid;
            grid-template-columns: 1fr 1fr;
            background-color: #FAFAFA;
    
            &-columns-1 { grid-template-columns: 1fr; }
            &-columns-2 { grid-template-columns: 1fr 1fr; }
            &-columns-3 { grid-template-columns: 1fr 1fr 1fr; }
            &-columns-4 { grid-template-columns: 1fr 1fr 1fr 1fr; }
    
            &-item {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                height: 38px;
                font-size: 12px;
                color: #98999A;
                transition: all ease-in-out .1s;
    
                &--selected {
                    font-weight: bold;
                    color: #151619;
                }
                
                &:hover {
                    color: #151619;
                }
            }
        }
    
        .panel-category {
            color: #151619;
            padding: 0 16px;
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: solid 1px #eee;
            font-size: 12px;
            font-weight: bold;
            cursor: pointer;
            transition: all ease-in-out .1s;
    
            &:hover,
            &--selected {
                color: #727779;
            }
    
            &-toggle {
                font-size: 16px;
            }
        }
    
        .panel-components {
            margin-top: 32px;
    
            &-list {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 8px;
                padding: 16px;
            }
        }
    
        .panel-search {
            width: 100%;
            position: relative;
            padding: 0 16px;
            margin-top: 16px;
    
            svg {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 32px;
                color: #98999A;
            }
    
            input {
                width: calc(100% - 80px);
                height: 34px;
                border: solid 1px #C9CBCC;
                background-color: #FAFAFA;
                padding-left: 48px;
    
                &::placeholder {
                    color: #98999A;
                    font-style: italic;
                    font-size: 12px;
                }
            }
        }

        .panel-order {
            margin-top: 16px;
            padding: 0 16px;
        } 
    }

    .panel-footer {
        background-color: #FAFAFA;
        height: 53px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100% - 32px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;

        &-actions {
            display: flex;
            align-items: center;

            span {
                margin-right: 16px;
                cursor: pointer;
                color: #151619;
                transition: all ease-in-out .1s;
                height: 18px;

                &:hover {
                    opacity: 0.7;
                }

                svg {
                    font-size: 18px;
                }
            }
        }

        &-button {
            background-color: #4CAA5F;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 32px;
            color: #fff;
            cursor: pointer;
            transition: all ease-in-out .1s;
            font-size: 12px;
            font-weight: bold;

            &:hover {
                opacity: 0.7;
            }
        }

        svg {
            font-size: 18px;
        }
    }
}