#builder-template-scope-book {
    .copyrightWrapper{
        width: 100%;
        margin: auto;
        background: white;
        position: relative;
        .container{
            .starsSection{
                height: 150px;
                position: relative;
                &>img{
                    position: absolute;
                    &:nth-child(1){
                        left: 28vw;
                        top: 25%;
                    }
                    &:nth-child(2){
                        left: 37vw;
                        top: 60%;
                    }
                    &:nth-child(3){
                        left: 52vw;
                        top: 35%;
                    }
                    @media screen and (max-width: 900px) {
                        width: 25px;
                        &:nth-child(1){
                            left: 20vw;
                        }
                        &:nth-child(3){
                            left: 60vw;
                        }
                    }
                    @media screen and (max-width: 767px) {
                        width: 22px;
                        &:nth-child(3){
                            left: 70vw;
                        }
                    }
                    @media screen and (max-width: 500px) {
                        width: 20px;
                    }
                }
                @media screen and (max-width: 767px){
                    height: 100px;
                }
            }
            .copyright{
                color: #333333;
                font-size: 12px;
                line-height: 1.2;
                position: relative;
                font-family: 'Ubuntu_Regular';
                font-weight: 500;
                padding-bottom: 8px;
                padding: 6px 32px;
                max-width: 1230px;
                margin: auto;
                display: block;
                width: 80%;
                
                @media screen and (max-width: 1500px) {
                    margin: 0;
                }
                @media screen and (max-width: 768px) {
                    width: 65vw;
                    font-size: 10px;
                    &.longer{
                        width: 70vw;
                    }
                }
                @media screen and (max-width: 400px) {
                    &.longer{
                        width: calc(100% - 64px);
                    }
                }
            }
        }
        .ribbon_bottom_right{
            position: absolute;
            bottom: 0;
            right: 0;
            display: flex;
            z-index: 1;
            width: 13vw;
            height: 13vw;
            img{
                width: 100%;
            }
            @media screen and (max-width: 991px) {
                width: 20vw;
                height: 20vw;
            }
            @media screen and (max-width: 768px) {
                width: 30vw;
                height: 30vw;
            }
        }
    }
}
