#builder-template-scope-book {
    .postCardWrapper{
        width: 100%;
        margin: auto;
        background: #F8F9F9;
        position: relative;
        .container{
            .cardWrapper{
                .left-block{
                    width: 50%;
                    position: relative;
                    @media screen and (max-width : 1000px){
                        width: 100%;
                        margin-top: 24px;
                    }
                    .title_postcard{
                        transform: matrix(1, -0.03, 0.03, 1, 0, 0);
                        color: #CF2E25;
                        font-size: 31px;
                        line-height: 37px;
                        text-align: center;
                        max-width: 450px;
                        font-weight: bold;
                        font-style: italic;
                        margin: auto;
                        margin-bottom: 40px;
                        margin-top: 90px;
                        position: relative;
                        font-family: 'Cera_Black_Italic';
                        @media screen and (max-width : 1200px){
                            font-size: 28px;
                            line-height: 34px;
                        }
                        @media screen and (max-width : 1000px){
                            margin-top: 20px;
                        }
                        @media screen and (max-width : 600px){
                            font-size: 20px;
                            line-height: 23px;
                        }
                        @media screen and (max-width : 360px){
                            font-size: 18px;
                            line-height: 21px;
                        }
                    }
                    .instructions{
                        margin: auto;
                        max-width: 456px;
                        font-size: 18px;
                        line-height: 28px;
                        text-align: center;
                        margin-top: 16px;
                        font-family: 'Ubuntu_Regular';
                        @media screen and (max-width : 1200px){
                            font-size: 16px;
                            line-height: 24px;
                        }
                        @media screen and (max-width : 600px){
                            font-size: 12px;
                            line-height: 19px;
                            max-width: 330px;
                        }
                        @media screen and (max-width : 320px){
                            font-size: 11px;
                        }
                        span{
                            color: #CF2E25;
                            font-family: 'Ubuntu_Bold';
                            font-weight: 500;
                        }
                        strong{
                            font-family: 'Ubuntu_Bold';
                            font-weight: 500;
                        }
                    }
                    .wrapper_button{
                        text-align: center;
                        margin-top: 40px;
                        margin-bottom: 65px;
                        height: 35px;
                        position: relative;
                        @media screen and (max-width : 1000px){
                            margin-bottom: 10px;
                        }
                        a.button_lego{
                            font-size: 16px;
                            line-height: 18px;
                            text-transform: uppercase;
                            padding: 18px 32px;
                            background: transparent linear-gradient(180deg, #69A719 0%, #01852C 100%) 0% 0% no-repeat padding-box;
                            box-shadow: 0px 3px 0px #185A16;
                            border-radius: 30px;
                            color: white;
                            border: none;
                            font-family: 'Ubuntu_Bold';
                            font-weight: 500;
                            text-decoration: none;
                            &:hover{
                                filter: brightness(110%);
                            }
                            @media screen and (max-width: 600px){
                                font-size: 16px;
                                line-height: 18px;
                                padding: 13px 23px;
                            }
                        }
                    }
                    .snowflake_red{
                        position: absolute;
                        img{
                            width: 30px;
                            @media screen and (max-width : 768px){
                                width: 20px;
                            }
                        }
                        &.snowflake_card_1{
                            top: 0;
                            left: 40%;
                            @media screen and (max-width : 1000px){
                                top: -20px;
                            }
                        }
                        &.snowflake_card_2{
                            top: 200px;
                            left: 3%;
                            @media screen and (max-width : 600px){
                                top: 95px;
                            }
                        }
                        &.snowflake_card_3{
                            top: 50px;
                            right: 36px;
                            @media screen and (max-width : 600px){
                                top: 15px;
                                right: 6%;
                            }
                        }
                        &.snowflake_card_4{
                            bottom: -15px;
                            right: 0;
                            @media screen and (max-width : 600px){
                                bottom: 55px;
                            }
                        }
                    }
                }
                .right-block{
                    width: 50%;
                    position: relative;
                    height: 100%;
                    text-align: center;
                    @media screen and (max-width : 1000px){
                        width: 100%;
                        margin-top: -110px;
                        position: relative;
                    }
                    @media screen and (max-width : 600px){
                        margin-top: -70px;
                    }
                    img{
                        width: 600px;
                        @media screen and (max-width : 1200px){
                            width: 500px;
                        }
                        @media screen and (max-width : 600px){
                            max-width: 300px;
                            margin: auto;
                        }
                    }
                }
            }
            .wrapper_button{
                text-align: center;
                margin-top: 40px;
                margin-bottom: 50px;
                position: relative; 
                .container_button{
                    position: relative;
                    z-index: 1;
                    a.button_lego{
                        font-size: 16px;
                        line-height: 1.2;
                        text-transform: uppercase;
                        padding: 18px 32px;
                        background: #D70008;
                        box-shadow: 0px 3px 0px #33333380;
                        border-radius: 4px;
                        color: white;
                        border: none;
                        font-family: 'Cera_Bold';
                        cursor: pointer;
                        &:hover{
                            filter: brightness(110%);
                        }
                        @media screen and (max-width: 600px){
                            font-size: 16px;
                            line-height: 18px;
                            padding: 13px 23px;
                        }
                    }
                }
                @media screen and (max-width: 820px) {
                    margin-top: 0;
                }
            }
            .copyright{
                color: #333333;
                font-size: 12px;
                line-height: 1.2;
                position: relative;
                font-family: 'Ubuntu_Regular';
                font-weight: 500;
                padding-bottom: 8px;
                padding: 6px 32px;
                max-width: 1230px;
                margin: auto;
                display: block;
                width: 80%;
                p{
                    margin: 0;
                }
                @media screen and (max-width: 1500px) {
                    margin: 0;
                }
                @media screen and (max-width: 768px) {
                    width: 65vw;
                    font-size: 10px;
                }
            }
            .wrapper_bottom_card{
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media screen and (max-width: 500px){
                    flex-direction: column;
                    align-items: flex-start;
                }
                .date_tirage{
                    color: white;
                    font-size: 12px;
                    line-height: 28px;
                    position: relative;
                    font-family: 'Ubuntu_Regular';
                    font-weight: 500;
                    @media screen and (max-width: 500px){
                        width: 100%;
                    }
                }
                .rules{
                    z-index: 3;
                    // position: absolute;
                    // top: 4px;
                    // left: 10px;
                    color: white;
                    font-size: 12px;
                    line-height: 28px;
                    text-decoration: underline;
                    font-family: Helvetica;
                    font-weight: bold;
                    cursor: pointer;
                    @media screen and (max-width: 500px){
                        width: 100%;
                    }
                }
                
            }
            div.regles{
                position: relative;
                width: calc(100% - 64px);
                padding: 40px 32px;
                max-width: 1230px;
                margin: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &>div>img{
                    width: 450px;
                    max-width: 90%;
                    margin: auto;
                    display: block;
                }
                .list_regles{
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    padding: 40px 0 0;
                    @media screen and (max-width: 820px) {
                        flex-direction: column;
                        padding-top: 30px;
                    }
                }
                .regle{
                    width: calc(100% / 3 - 3px);
                    display: inline-block;
                    .description{
                        max-width: 940px;
                        & > p, div{
                            width: 85%;
                            display: block;
                            max-width: 368px;
                            font-size: 18px;
                            line-height: 1.3;
                            text-align: center;
                            margin: 15px auto 0;
                            @media screen and (max-width: 1150px){
                                font-size: 14px;
                            }
                            p{
                                font-size: inherit!important;
                                line-height: inherit!important;
                                margin: 0 auto;
                            }
                        }
                    }
                    img{
                        width: 80px;
                        display: block;
                        margin: auto;
                    }
                    @media screen and (max-width: 820px){
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 25px;
                        &>img{
                            display: inline-block;
                            width: 80px;
                            margin: 0;
                        }
                        .description{
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            width: 360px;
                            padding-left: 30px;                
                            & > p, div{
                                margin: 0;
                                text-align: left;
                                font-size: 16px;
                                width: 100%;
                                max-width: 300px;
                                br{
                                    display: none;
                                }
                            }
                        }
                    }
                    @media screen and (max-width: 450px){
                        &>img{
                            width: 60px;
                            margin-left: 0px;
                        }
                        .description{
                            padding-right: 5px;
                            & > p, div{
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
        .ribbon_bottom_right{
            position: absolute;
            bottom: 0;
            right: 0;
            display: flex;
            z-index: 1;
            width: 13vw;
            height: 13vw;
            img{
                width: 100%;
            }
            @media screen and (max-width: 991px) {
                width: 20vw;
                height: 20vw;
            }
            @media screen and (max-width: 768px) {
                width: 30vw;
                height: 30vw;
            }
        }
    }
}
