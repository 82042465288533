a.reglement{
    position: absolute!important;
    color: #212120;
    right: 20px;
    top: 10px;
    font-size: 16px;
    z-index: 100;
    cursor: pointer;
    //font-family: 'Noto_Bold';
    text-shadow: 1px 1px 3px white;
    &:hover{
        text-decoration: underline;
    }
    @media screen and (max-width: 780px) {
        top: 10px;
        font-size: 14px;
    }
    p{
        display: inline;
        padding: 5px;
    }
}
.text_reglement{
    display: none;
    position: fixed;
    z-index: 3000;
    background-color: $bg_reglement;
    width: calc(860px - 60px);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 30px;
    height: 80vh;
    overflow-x: auto;
    h2, h3, p, ul, li{
        // color: $color_reglement;
        max-width: 100%;
    }
    a{
        // color: $color_reglement;
    }
    p, li{
        text-align: justify;
        font-size: 16px;
        line-height: 24px;
        @media screen and (max-width: 450px){
            font-size: 14px!important;
        }
    }
    ul{
        margin: 0;
    }
    @media screen and (max-width: 1000px) {
        width: calc(90% - 60px);
    }
}
.overlay_reglement{
    display:none;
    width: 100vw;
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    height: 110vh;
    top: 0;
    z-index: 2900;
    left: 0;
}