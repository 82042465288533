header {
    height: auto;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: calc(100% - 20px);
    z-index: 800;
    .banner{
        width: 100%;
        display: flex;
        padding: 5px 0;
        div{
            font-size: 25px;
            letter-spacing: 0.9px;
            color: white;
            text-transform: uppercase;
            text-align: center;
            width: 100%;
            margin: auto;
            // font-family: 'Noto_Bold';
            p{
                display: inline-block;
                // font-family: 'Noto_Bold';
            }
        }
        &.withlogo{
            padding: 2.5px 0;
            div{
                font-size: 25px;
                letter-spacing: 0.9px;
                color: white;
                text-transform: uppercase;
                text-align: center;
                width: calc(100% - 200px);
                margin: auto auto auto 0;
                display: inline-block;
            }
            img.logo {
                width: auto;
                max-width: 100%;
                width: 100px;
                height: auto;
            }
            @media screen and (max-width: 600px){
                div{
                    width: 100%;
                }
                img.logo{
                    display: none;
                }
            }
        }
    }
    .user + .banner{
        width: 70%;
    }
    .user{
        height: 100%;
        display: none;
        width: 30%;
        min-width: 110px;
        color: white;
        background: black;
        text-align: center;
        padding: 0 20px;
        #userName,.logOut{
            padding: 8px 5px;
            display: inline-block;
            font-size: 17px;
            // font-family: 'Noto_Bold';
            vertical-align: middle;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100px;
        }
        .logOut{
            height: 25px;
            cursor: pointer;
        }
    }
    @media screen and (max-width: 780px){
        p, div{
            font-size: 15px!important;
        }
        .user{
            padding:0;
            #userName{
                font-size: 15px;
                padding: 12px 0px;
                max-width: 75px;
            }
            .logOut{
                width: 18px;
                padding: 0;
                margin-top: 8px;
                img{
                    width: 100%;
                }
            }
        }
    }
    @media screen and (max-width: 336px){
        p, div{
            font-size: 13px!important;
        }
    }
}