:root{
    font-size: 16px;
    @media screen and (max-width: 1450px) {
        font-size: 15px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 14px;
    }
    @media screen and (max-width: 768px) {
        font-size: 13px;
    }
    @media screen and (max-width: 450px) {
        font-size: 12px;
    }
}

.builder {
    display: flex;
    flex-direction: row;
    background-color: #333;
    width: 100%;

    .img-preview {
        overflow: hidden;
        width: 100%;
        height: 200px;
        max-height: 200px;
        margin-top: 20px;
        border-radius: 4px;
        position: relative;
    }

    .builder-page {
        position: relative;
        background-color: #fff;
        display: inline-flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: calc(100% - 336px);

        &.full-width {
            width: 100%;
        }

        .builder-loading {
            margin: 15px;
        }

        .builder-expand-button {
            position: absolute;
            left: 0;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            top: 50%;
            transform: translateY(-50%);

            svg {
                font-size: 1.2rem;
            }
        }

        .builder-scope {
            position: relative;
        }

        .top-bar {
            z-index: 20;
            height: 42px;
            background-color: #EDEEEE;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;

            .changer-page {
                display: flex;
                height: 100%;

                .selecter {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: calc(100% - 22px);
                    margin: 5px;
                    border-radius: 4px;
                    padding: 6px 16px;
                    cursor: pointer;
                    p {
                        color: #2B4553;
                    }
                    svg {
                        fill: #2B4553;
                        margin-left: 20px;
                    }
                    &:hover {
                        p{
                            color: #15181C;
                        }
                        svg{
                            fill: #15181C;
                        }
                    }
                }

                .dynamicChangerPage {
                    position: absolute;
                    left: 0px;
                    width: 100%;
                    top: 42px;
                    background-color: rgba(66, 94, 124, 0.7);
                    height: calc(100vh - 42px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    overflow: auto;

                    & > svg {
                        fill: white;
                        position: absolute;
                        left: 96%;
                        top: 1%;
                        width: 2em;
                        height: 2em;
                        cursor: pointer;
                        z-index: 9999;
                    }

                    & > div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:hover {
                            img {
                                filter: grayscale(90%) contrast(200%) drop-shadow(0 0 0.75rem black) brightness(1.1);
                            }
                            p {
                                display: block;
                                cursor: pointer;
                            }
                        }
                    }

                    p {
                        display: none;
                        position: absolute;
                        font-weight: bold;
                    }

                    img {
                        height: 30vh;
                        max-height: inherit;
                        margin: 0;
                        image-rendering: -moz-crisp-edges;
                        image-rendering: -o-crisp-edges;
                        image-rendering: -webkit-optimize-contrast;
                        image-rendering: crisp-edges;
                        -ms-interpolation-mode: nearest-neighbor;
                        cursor: pointer;
                    }
                }
            }

            &-text {
                font-size: 16px;
                color: #C9CBCC;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .content {
            position: absolute;
            z-index: 1;
            overflow: auto;
            top: 42px;
            left: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            height: calc(100% - 42px);

            .builder-placeholder {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                box-sizing: border-box;
                max-width: calc(1200px - 30px);
                padding-left: 15px;
                padding-right: 15px;

                &.fullwidth {
                    max-width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                }

                span {
                    font-size: 40px;
                    font-weight: bold;
                }
            }
        }
    }

    .right {
        background-color: #eee;
        display: inline-flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        z-index: 9;

        .MuiTabs-root {
            height: 60px;
            svg {
                fill: #2b4553;
                opacity: 1;
            }

            .MuiTabs-indicator {
                height: 4px;
                background-color: #eaecee;
            }
            .MuiTabs-flexContainer{
                height: 100%;
            }
        }

        .right-content {
            width: 100%;
            height: calc(100vh - 48px);
            overflow: auto;

            & > div {
                min-height: 100%;
            }

            .first-panel {
                padding: 20px;
                width: calc(100% - 40px);
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                overflow: auto;
            }

            .second-panel {
                padding: 20px;
                width: calc(100% - 40px);
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                overflow: auto;
            }

            .third-panel {
                padding: 20px;
                width: calc(100% - 40px);
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }

            .fourth-panel {
                padding: 20px;
                width: calc(100% - 40px);
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }

            .sortable-helper {
                z-index: 1000;
            }
        }
    }
}

.MuiMenu-paper{
    border-color: #2B4553;
    svg{
        font-size: 1.4rem;
        fill: #2B4553;
    }
    span{
        color: #2B4553;
    }
    // .MuiMenuItem-root:focus{
    //     background: rgba(0, 0, 0, 0.04);
    // }
}

.tools-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 3px;
}

.translate-btn {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    bottom: 2px;
    margin: 16px;

    img {
        width: 20px;
        height: 20px;

        &.rotate {
            animation: rotation 2s infinite linear;
        }
    }

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
}
