.dropdown, .dropdown-trigger {
    width: 100%;

    .dropdown-trigger { 
        width: calc(100% - 8px);
        border-radius: 4px;
    }
}

.helper {
    z-index: 1000;
}