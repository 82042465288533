.about{
    .left-block, .right-block{
        width: calc(50%);
        display: inline-block;
        // height: 100%;
        vertical-align: top;
        @media screen and (max-width: 768px){
            width: 100%;
            display: block;
        }
    }
    @media screen and (max-width: 768px){
        &.container{
            flex-direction: column;
        }
    }
    .left-block{
        position: relative;
        .container_image{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            @media screen and (max-width: 768px){
                position: relative;
                top: 0;
                transform: none;
                margin-top: 16px;
            }
            img{
                width: 100%;
            }
        }
    }
    .right-block{
        background: #70aecf;
        color: white;
        padding: 0 20px;
        padding-bottom: 40px;
        .agency_image{
            margin-top: 80px;
            img{
                max-width: 500px;
                width: 100%;
            }
        }
        .agency_content{
            max-width: 500px;
            margin: auto;
            margin-top: 24px;
            p{
                font-size: inherit;
            }
        }
        .agency_stats{
            margin: auto;
            margin-top: 50px;
            max-width: 500px;
            display: flex;
            justify-content: center;
            .container_stat{
                width: calc(100%/3);
                padding: 0 16px;
                display: inline-block;
                text-align: center;
                font-weight: bold;
                @media screen and (max-width: 768px){
                    width: calc(100%/3 - 30px);
                }
                @media screen and (max-width: 500px){
                    width: calc(100%/3);
                }
                .number{
                    font-size: 25px;
                    // height: 80px; 
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    @media screen and (max-width: 768px){
                        font-size: 20px;
                    }
                    @media screen and (max-width: 400px){
                        font-size: 16px;
                    }
                    span{
                        top:0;
                        left:0;
                        bottom:0;
                        right:0;
                        position:absolute;
                        display:flex;
                        align-items:center;
                        justify-content:center;
                        flex-direction: column;
                        .sup{
                            position: relative;
                            font-size: 60%;
                            text-align: center;
                        }
                    }
                }
                .text{
                    margin-top: 16px;
                }
            }
        }
    }
}