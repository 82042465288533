.company_image{
    margin-top: 230px;
    @media screen and (max-width: 768px) {
        margin-top: 80px;
    }
    img{
        max-width: 500px;
        width: 100%;
    }
}
.front_content{
    // position: absolute;
    // bottom: 50px;
    // left: 0;
    margin-top: 128px;
    width: 100%;
    padding-bottom: 36px;
    @media screen and (max-width: 768px) {
        margin-top: 80px;
    }
    .offer_name{
        font-weight: bold;
        font-size: 25px;
        max-width: 800px;
        margin-left: 50%;
        transform: translateX(-50%);
        padding-bottom: 20px;
        @media screen and (max-width: 768px) {
            font-size: 20px;
            max-width: none;
            transform: none;
            margin: auto;
        }
    }
}