#builder-template-scope-book{
    .recap-wrapper{
        max-width: 100%;
        margin: auto;
        position: relative;
        overflow: hidden;
        background: url('./image/bg-log.png');
        background-size: cover;
        padding-top: 70px;
        @media screen and (max-width: 500px){
            padding-top: 30px;
            background: url('./image/bg-log-mobile.png');
            background-size: cover;
        }
        &>h1{
            font-size: 39px;
        }
        &>h2{
            font-size: 25px;
        }
        &>h1, &>h2{
            color: white;
            font-family: 'Cera_Black_Italic';
            text-align: center;
            line-height: 1.2;
            margin: 0;
            @media screen and (max-width: 768px){
                width: 60%;
                padding-right: 10px;
                margin-left: calc(40% - 10px);
                text-align: inherit;
                font-size: 24px;
            }
            @media screen and (max-width: 500px){
                width: 50%;
                padding-right: 10px;
                margin-left: calc(44% - 10px);
                text-align: inherit;
                font-size: 20px;
            }
            @media screen and (max-width: 380px){
                margin-left: calc(50% - 10px);
                font-size: 18px;
            }

        }
        
        img.absolute_img{
            position: absolute;
            bottom: 100%;
            height: 150px;
            padding-left: 30px;
            @media screen and (max-width: 900px) {
                height: 120px;
                padding-left: 10px;
            }
            @media screen and (max-width: 500px) {
                height: 100px;
            }
        }
        .wrapper_card{
            padding: 0 18px;
            margin-bottom: 144px;
            margin-top: 40px;
            position: relative;
            z-index: 2;
            @media screen and (max-width: 767px){
                margin-bottom: 50px;
                padding:0;
            }
            @media screen and (max-width: 500px){
                margin-top: 20px;
            }
            .card_container{
                max-width: 1056px;
                margin: auto;
                background: white;
                overflow: hidden;
                .top_card{
                    padding: 0 16px 32px;
                    h3{
                        color: #0EA29D;
                        font-size: 25px;
                        line-height: 1.2;
                        font-family: 'Cera_Bold';
                        max-width: 410px;
                        margin: auto;
                        text-align: center;
                        margin-top: 40px;
                        @media screen and (max-width: 600px){
                            font-size: 22px;
                            line-height: 29px;
                        }
                        @media screen and (max-width: 450px){
                            font-size: 20px;
                            line-height: 23px;
                        }
                    }
                    .subtitle_text{
                        font-size: 16px;
                        line-height: 1.2;
                        font-family: 'Cera_Normal';
                        color: #333333;
                        max-width: 410px;
                        margin: auto;
                        text-align: center;
                        margin-top: 10px;
                        margin-bottom: 40px;
                        font-weight: normal;
                        @media screen and (max-width: 600px){
                            font-size: 15px;
                            line-height: 21px;
                        }
                        @media screen and (max-width: 450px){
                            font-size: 14px;
                            line-height: 17px;
                        }
                        p{
                            margin: 0;
                        }
                    }
                    .form_validation{
                        form{
                            margin: auto;
                            max-width: 609px;
                            width: 100%;
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            &:focus{
                                outline: none;
                            }
                            .wrapper_checkbox{
                                margin-top: 8px;
                                background: #FBFBFB;
                                border: 1px solid #ADBBBA;
                                border-radius: 2px;
                                padding: 14px;
                                padding-left: 27px;
                                label{
                                    margin-left: 8px;
                                    color: #5D6565;
                                    font-size: 14px;
                                    cursor: pointer;
                                }
                            }
                            input{
                                cursor: pointer;
                                &:focus{
                                    outline: none;
                                }
                                &#mail{
                                    width: 100%;
                                    background: white;
                                    border: 1px solid #ADBBBA;
                                    border-radius: 2px;
                                    padding: 14px;
                                    box-sizing: border-box;
                                    font-size: 15px;
                                    line-height: 24px;
                                    font-family: 'Sansa_Normal';
                                    text-align: center;
                                    @media screen and (max-width: 600px){
                                        text-align: left;
                                        padding: 15px 137px 15px 17px;
                                        font-size: 14px;
                                    }
                                }
                                &#submit{
                                    font-size: 16px;
                                    line-height: 1.2;
                                    text-transform: uppercase;
                                    padding: 18px 32px;
                                    background: #D70008;
                                    box-shadow: 0px 3px 0px #032C2A33;
                                    border-radius: 4px;
                                    color: white;
                                    border: none;
                                    font-family: 'Cera_Bold';
                                    cursor: pointer;
                                    margin: 25px auto;
                                    -webkit-appearance: none;
                                    &:hover{
                                        filter: brightness(90%);
                                    }
                                    @media screen and (max-width: 600px){
                                        font-size: 14px;
                                        line-height: 18px;
                                        padding: 13px 23px;
                                    }
                                }
                            }
                        }
                    }
                }
                .bottom_card{
                    background: #F2F2F2;
                    padding-bottom: 41px;
                    text-align: center;
                    position: relative;
                    h3{
                        font-size: 25px;
                        line-height: 1.2;
                        padding-top: 25px;
                        @media screen and (max-width: 767px){
                            font-size: 16px;
                        }
                    }
                    .return_button{
                        color: #D70008;
                        font-family: 'Cera_Bold';
                        font-size: 16px;
                        border: 1px solid #D70008;
                        border-radius: 2px;
                        padding: 16px 40px;
                        cursor: pointer;
                        @media screen and (max-width: 600px){
                            font-size: 14px;
                            line-height: 18px;
                            padding: 13px 23px;
                        }
                        &:hover{
                            color: white;
                            background: #D70008;
                        }
                    }
                    .wrapper_list{
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        padding: 0 8px;
                        margin-top: 26px;
                        margin-bottom: 26px;
                        @media screen and (max-width: 767px){
                            margin-top: 14px;
                        }
                        .selected_product{
                            width: calc((100% / 4) - 16px);
                            background: white;
                            margin: 0 8px;
                            text-align: center;
                            padding: 18px;
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: column;
                            @media screen and (max-width: 900px){
                                width: calc((100% / 2) - 16px);
                                margin: 8px;
                            }
                            .image_product{
                                flex: 1;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                img{
                                    max-height: 140px;
                                    max-width: 100%;
                                    // @media screen and (max-width: 500px){
                                    //     width: 100%;
                                    // }
                                }
                            }
                            .infos_product{
                                font-size: 15px;
                                line-height: 24px;
                                font-family: 'Sansa_Normal';
                                color: #4B4B4B;
                                max-height: 24px;
                                min-height: 24px;
                                overflow: hidden;
                                display: -webkit-box;
                                margin-top: 12px;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;  
                                @media screen and (max-width: 600px){
                                    font-size: 12px;
                                    line-height: 15px;
                                    max-height: 15px;
                                    min-height: 15px;
                                }
                                @media screen and (max-width: 450px){
                                    font-size: 10px;
                                    line-height: 12px;
                                    max-height: 12px;
                                    min-height: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}