.how-container, .amazing-bloc{
    display: block;
    padding: 60px 0;
    overflow: hidden;
    @media screen and (max-width: 1260px){
        padding: 10px 0;
        width: 100%;
    }
    .how, .container-content{
        padding-top: 10px;
        background-color: rgba(0,0,0,0.6);
        max-width: 100%;
        width: 1200px;
        margin: 0px auto;
        @media screen and (max-width: 1260px) {
            width: 100%;
            padding-top: 0;
        }
        h3{
            margin: 0;
            //font-family: 'Noto_Bold';
            @media screen and (max-width: 450px){
                font-size: 20px !important;
            }
        }
    }
}

.dotations{
    position: relative;
    padding-top: 60px;
    padding-bottom: 30px;
    width: 1100px;
    max-width: 100%;
    display: block;
    margin: auto;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div{
        &.list{
            width: 100%;
            padding: 40px 0;
            @media screen and (min-width: 781px){
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &.unique{
                max-width: 800px;
                .dotation{
                    width: 100%;
                    max-width: 100%;
                    flex-basis: 100%;
                    flex-grow: inherit;
                }
                img.mobile_image{
                    display: none;
                    max-width: 80% !important;
                }
                @media screen and (max-width: 600px) {
                    img.mobile_image{
                        display: block;
                    }
                    img.desktop_image{
                        display: none;
                    }
                }
            }
        }
        &.dotation{
            width: calc(100% / 4 - 20px);
            max-width: 360px;
            margin: 0 7.5px;
            display: inline-block;
            vertical-align: top;
            @media screen and (min-width: 781px){
                flex-basis: 150px;
                flex-grow: 1;
            }
            hr{
                margin: auto;
            }
            p{
                text-align: center;
                color: white;
                font-size: 15px;
                line-height: 25px;
                max-width: 100%;
                width: 100%;
                display: block;
                padding: 12px 0;
            }
            &>div{
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: auto;
                }
            }
        }
    }
    &>p{
        text-shadow: 0px 1px 6px #001B46;
        font-size: 30px;
        line-height: 42px;
    }

    @media screen and (max-width: 1260px) {
        padding-top: 40px;
    }
    @media screen and (max-width: 1190px){
        max-width: 95%;
        margin: auto;
        
        div.dotation{
            width: calc(100% / 4 - 15px);
            margin: 0 5px;
            p{
                font-size: 17px!important;
            }
        }
        &>p{
            font-size: 26px;
            line-height: 32px;
        }
    }
    @media screen and (max-width: 780px) {
        width: 95%;
        margin: auto;
        &.more_details{
            max-width: 95%;
        }
        div.dotation{
            width: calc(100% / 2 - 15px);
            margin: 0 5px 15px;
            p{
                font-size: 16px!important;
            }
        }
        h2{
            background: none!important;
            line-height: 1.2!important;
        }
        &>p{
            margin: 30px 0 20px;
            font-size: 24px;
            line-height: 30px;
        }
    }
    @media screen and (max-width: 460px) {
        div.dotation{
            width: calc(100% / 2 - 4px);
            margin: 0px;
            p{
                font-size: 15px!important;
                width: 100%;
            }
        }
        &>p{
            font-size: 24px;
            line-height: 30px;
        }
    }
    @media screen and (max-width: 400px) {
        div.dotation p{
            font-size: 14px!important;
            line-height: 24px;
        }
        &>p{
            font-size: 22px;
            line-height: 28px;
        }
    }
    @media screen and (max-width: 340px) {
        div.dotation p{
            font-size: 13px!important;
            line-height: 22px;
        }
        &>p{
            font-size: 21px;
            line-height: 27px;
        }
    }

    &.more_details{
        .list{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        .dotation{
            flex: 0 0 calc(33.333333% - 20px);
            margin-bottom: 20px;
            &>div{
                flex-direction: column;
                padding: 10px 40px;
                h2, h3, p{
                    margin: 10px 10px 0 25px;
                    text-align: left;
                    width: 90%;
                    max-width: 100%;
                    padding: 0;
                }
                h2{
                    line-height: 1!important;
                }
                h3{
                    margin-top: 0;
                }
                img{
                    margin-top: 10px;
                    max-width: 100%;
                    max-height: 270px;
                    position: absolute;
                    bottom: 30px;
                }
            }
            @media screen and (max-width: 1100px) {
                flex: 0 0 calc(50% - 20px);
            }
            @media screen and (max-width: 720px){
                flex: 0 0 100%;
            }
            @media screen and (max-width: 350px){
                height: 400px!important;
            }
        }
    }
    &.special{
        .list{
            display: block;
            .dotation{
                position: relative;
                border: 1px solid white;
                border-top: 0;
                margin-top: 50px;
                width: calc(50% - 20px);
                max-width: 50%;
                display: inline-block;
                &:before{
                    content: '';
                    position: absolute;
                    border-color: white;
                    border-style: solid;
                    border-width: 1px 0 0 0;
                    height: 1px;
                    left: 0;
                    top: 0;
                    width: calc(90% / 2 - 100px);
                }
                &:after{
                    content: '';
                    position: absolute;
                    border-color: white;
                    border-style: solid;
                    border-width: 1px 0 0 0;
                    height: 1px;
                    right: 0;
                    top: 0;
                    width: calc(90% / 2 - 100px);
                }
                &>div{
                    flex-direction: column;
                    position: relative;
                    img{
                        &.logo{
                            position: absolute;
                            width: 200px;
                            bottom: 90%;
                        }
                        &.lot{
                            margin-top: 50px;
                        }
                    }
                    p{
                        width: 90%;
                        max-width: 330px;
                    }
                }
            }
        }
    }
}