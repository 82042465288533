
input{
    border: 0;
    width: calc(100% - 25px);
    height: 55px;
    padding-left: 25px;
    outline: none;
    margin-top: 10px;
    margin-bottom: 20px;
    // font-family: 'Noto_Bold';
    &[type="radio"]{
        position: relative;
        opacity: 0;
    }
    &[type="checkbox"]{
        height: 12px;
        width: 12px;
        margin-right: 15px;
    }
    @media screen and (max-width: 768px){
        height: 35px;
        padding-left: 15px;
        font-size: 14px;
    }
    &::placeholder{
        text-transform: uppercase;
        font-size: 16px;
        // font-family: 'Noto_Bold';
    }
}

label{
    color: white;
    font-size: 20px;
    line-height: 23px;
    // font-family: 'Noto_Bold';
    @media screen and (max-width: 768px){
        font-size: 16px;
        line-height: 20px;
    }
}
