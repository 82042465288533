
/* COMMON */
#builder-template-scope-game{
    margin: auto;
    
    @import 'common/variables';
    @import 'common/fonts';
    @import 'common/global';
    @import 'common/form';

    /* COMPONENTS */
    @import 'components/header';
    @import 'components/preHeader';
    @import 'components/footer';
    @import 'components/buttons';
    @import 'components/popup';
    @import 'components/dotations';
    @import 'components/rules';
    @import 'components/gallery';
    @import 'components/reglement';
    @import 'components/bandeau';
    @import 'components/basicBlock';
    @import 'components/social';

    /* PAGES */
    @import 'pages/index';
    @import 'pages/infos';
    @import 'pages/game';
    @import 'pages/login';
    @import 'pages/result';
}