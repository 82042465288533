div.popup{
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: auto;
    z-index: 1000;
    top: 0;
    left: 0;
    .logo{
        position: absolute;
        width: 250px;
        margin-left: 50px;
        margin-top: 50px;
        @media screen and (max-width: 1100px){
            margin-left: 10px;
            margin-top: 10px;
        }
    }
    .overlay{
        background-color: rgba(0, 0, 0, 0.6);
        height: 100%;
        width: 100%;
        margin: auto;
    }
    .popup_content{
        top: 100px;
        position: absolute;
        margin-left: 50%;
        transform: translateX(-50%);
        height: auto;
        width: auto;
        max-width: 1000px;
        padding: 20px 30px;
        z-index: 1000;
        &>img{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            height: 250px;
            top: calc(-250px / 2);
        }
        &>a.as_button{
            padding: 10px;
            margin: 0;
            width: 100%;
            left: -10px;
            position: relative;
        }
        &>div.inscription{
            display: inline-block;
            vertical-align: top;
            padding: 10px;
            width: calc(100% - 20px);
            padding-bottom: 25px;
            &.ready{
                height: 100%;
                display: flex;
                flex-direction: column;
            }
            .image-popup{
                margin-top: 20px;
                margin-bottom: 20px;
                display: block;
            }
            h3{
                text-align: center;
                max-width: 100%;
            }
            img{
                margin: auto;
                display: block;
                max-width: 100%;
            }
            hr{
                width: 70px;
            }
            p{
                max-width: 360px;
                margin: auto;
                text-align: center;
                font-size: 18px;
                line-height: 22px;
                &:last-child{
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            button{
                background-size: cover;
                background-position: center;
                border: 0;
                display: block;
                font-size: 30px;
                text-transform: uppercase;
                padding: 5px 10px;
                margin: 50px auto;
                cursor: pointer;
            }
            img.absolute{
                position: absolute;
                top: 330px;
                right: -45px;
            }
            @media screen and (max-width: 530px) {
                h3{
                    font-size: 18px;
                    line-height: 26px;
                    margin: 10px auto 20px;
                }
                h4{
                    font-size: 16px;
                    line-height: 26px;
                }
                p{
                    font-size: 14px!important;
                    line-height: 19px;
                    &:last-child{
                        font-size: 14px!important;
                        line-height: 19px;
                    }
                }
                button{
                    font-size: 18px!important;
                    padding: 8px 30px!important;
                    margin: 30px auto;
                    width: auto;
                    height: 60px;
                }
            }
            @media screen and (max-width: 400px){
                padding-left: 10px;
            }
        }
    }
    &.play{
        position: relative;
        display: block;
        z-index: 2000;
    }
}

@media screen and (max-width: 600px){
    div.popup{
        .popup_content{
            width: 80%;
        }
    }
}

@media screen and (max-width: 500px){
    .game_index{
        .popup{
            .popup_content{
                background-size: cover;
                z-index: 1000;
                padding: 100px 0;
                height: 440px;
                .inscription{
                    p{
                        max-width: 100%;
                        margin: 10px auto;
                    }
                }
            }
        }
    }
}