.swiper-container {
    width: 90%;

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .finder-category-image {
            position: relative;
            width: 90%;
            cursor: pointer;
            transition: all ease-in-out 0.25s;
            overflow: hidden;

            .finder-selected {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: none;
                background: rgba(1, 151, 2, 0.85);
            }

            &.selected {
                .finder-selected {
                    display: block;
                    background: rgba(1, 151, 2, 0.80) url(./white-checked.png) no-repeat center;
                }
            }

            &:hover {
                img {
                    transform: scale(1.2);
                }

                .finder-selected {
                    display: block;
                }
            }
        }
    }
}

.finder-result {
    width: 100%;
    overflow: hidden;
    margin-top: 10px;
    position: relative;

    .swiper-container-result {
        width: 100%;

        .result-product {
            width: calc(100% - 20px);
            height: 350px;
            background-color: #fff;
            border-radius: 4px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .bottom {
                z-index: 10;
                position: absolute;
                bottom: 10px;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                button {
                    padding: 5px 10px;
                    border: none;
                    border-radius: 10px;
                    margin: 0 auto;
                    background-color: #2B4553;
                    cursor: pointer;
                    color: #fff;
                    font-weight: bold;
            
                    &:hover {
                        background-color: #1d2e38;
                    }
                }

                span {
                    font-size: 16px;
                    padding: 5px;
                }
            }

            .result-product-image {
                position: relative;
                width: 100%;
                height: 250px;
                overflow: hidden;

                img {
                    width: 60%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .swiper-result-button-prev,
    .swiper-result-button-next {
        position: absolute;
        top: 20px;
        width: 30px;
        height: 30px;
        z-index: 10;
        cursor: pointer;
        background-color: #2B4553;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all ease-in-out 0.25s;

        &:hover {
            background-color: #333;
        }

        &.swiper-button-disabled {
            opacity: 0.35;
            cursor: auto;
            pointer-events: none;
        }
    }

    .swiper-result-button-prev {
        left: 10px;
        right: auto;
    }
  
    .swiper-result-button-next {
        right: 10px;
        left: auto;
    }
}

.finder-categories {
    position: relative;

    .swiper-button-prev,
    .swiper-button-next {
        position: absolute;
        top: 50%;
        width: 40px;
        height: 40px;
        margin-top: -20px !important;
        z-index: 10;
        cursor: pointer;
        background-color: #2B4553;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all ease-in-out 0.25s;

        &:hover {
            background-color: #333;
        }

        &.swiper-button-disabled {
            opacity: 0.35;
            cursor: auto;
            pointer-events: none;
        }
    }

    .swiper-button-prev {
        left: 10px;
        right: auto;
    }
  
    .swiper-button-next {
        right: 10px;
        left: auto;
    }
}

.finder-container {
    width: 1230px;
    max-width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .finder-white-part {
        background-color: #fff;
        width: 100%;
        padding-bottom: 10px;
        padding-top: 10px;
        display: flex;
        flex-direction: row;
        border-radius: 4px;

        .finder-left {
            width: 80%;
        }
        
        .finder-right {
            width: 20%;
            text-align: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        
            button {
                padding: 15px;
                border: none;
                border-radius: 10px;
                width: 80%;
                margin: 0 auto;
                background-color: #2B4553;
                cursor: pointer;
                color: #fff;
                font-weight: bold;
        
                &:hover {
                    background-color: #1d2e38;
                }
            }
        }
    }
}

.filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    .filter {
        width: 50%;
        display: inline-block;
        padding: 10px;

        p {
            font-weight: bold;
            font-size: 22px;
        }

        button {
            position: relative;
        }
    }
}

.btn-group {
    button {
        background-color: #444;
        border: 1px solid #000;
        color: white;
        padding: 5px 10px;
        cursor: pointer;
        float: left;

        &:not(:last-child) {
            border-right: none;
        }

        &:hover {
            background-color: #333;
        }

        &.selected {
            background-color: #111;
        }
    }

    &.detached {
        button {
            margin-right: 5px;
            border-right: 1px solid #000;
        }
    }

    &:after {
        content: "";
        clear: both;
        display: table;
    }
}

.btn-tags {
    button {
        background-color: #2B4553;
        border: 1px solid #1d2e38;
        color: white;
        padding: 5px 10px;
        cursor: pointer;
        float: left;
        border-radius: 30px;
        margin-right: 5px;
        margin-bottom: 5px;

        &:hover {
            background-color: #1d2e38;
        }

        &.selected {
            background-color: #1d2e38;
        }
    }

    &:after {
        content: "";
        clear: both;
        display: table;
    }
}

@media screen and (max-width: 960px) {
    .finder-container {
        width: 100%;

        .finder-white-part {
            flex-direction: column;
    
            .finder-left,
            .finder-right {
                width: 100%;

                button {
                    width: auto;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .filters { 
        flex-direction: column;

        .filter {
            width: 100%;
        }
    }
}