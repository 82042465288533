.tags .MuiInputBase-root {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    min-height: 40px !important;
}

.searchbar {
    min-height: 40px !important;
}

.chip {
    .MuiChip-label {
        padding: 0px !important;
    }

    .MuiChip-deleteIcon {
        height: 15px !important;
        width: 15px !important;
    }
}

.autocomplete {
    .MuiAutocomplete-inputRoot {
        display: 'flex' !important;
        gap: '20px' !important;
    }
}

.seeMoreWrapper{
    transition: all 0.5s ease-in-out;
    overflow: hidden;
}