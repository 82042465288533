#builder-template-scope-game.lego_easter_2022{
    font-family: "Cera Pro", sans-serif;
    max-width: 1180px;
    div.popup .popup_content{
        border-radius: 20px;
    }
    .first h1 p, .title-styled p.title{
        font-style: italic;
        font-weight: bold;
        text-shadow: 0px 1px 8px #2922004d;
        @media screen and (max-width: 1000px) {
            font-size: 30px !important;
        }
        @media screen and (max-width: 600px) {
            font-size: 25px !important;
        }
    }
    .first{
        button{
            box-shadow: 0 5px 0 0 #1b8d29;
        }
        h2{
            p em{
                font-size: 15px;
            }
            @media screen and (max-width: 550px){
                p{
                    display: inline;
                }
            }
        }
    } 
    p.title{
        @media screen and (max-width: 1000px) {
            font-size: 30px !important;
        }
        @media screen and (max-width: 600px) {
            font-size: 25px !important;
        }
    }
    div.results section.win .result_div > div{
        max-width: 420px;
        margin: auto;
        border-radius: 20px;
        box-shadow: 0px 1px 8px #29220080;
        .lot + p{
            font-style: italic;
            font-weight: bold;
            text-shadow: 0px 1px 8px #2922004d;
            margin-bottom: 16px;
        }
        @media screen and (max-width: 1000px) {
            padding: 110px 20px 20px !important;
            .lot + p{
                font-size: 39px !important;
            }
        }
        @media screen and (max-width: 500px) {
            padding: 90px 20px 20px !important;
            .lot + p{
                font-size: 25px !important;
            }
        }
    }
    div.game_game{
        section.game.ended section.endGame.overlayImage img{
            max-width: 750px;
            transition: max-width 1s;
        }
        section.game{
            @media screen and (max-width: 750px){
                background-position: left bottom!important;
            }
            section.endGame{
                img{
                    max-width: 0px;
                    transition: max-width 1s;
                }   
            } 
            div.popup.play{
                background: rgba(0,0,0,0.4);
            }
        } 
    }
    a.reglement{
        top: 0;
        right: 0;
    }
}
