.block_social, .block_social_complete{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block_social_complete{
    max-width: 1000px;
    margin: auto;
    padding: 20px 0;
    @media screen and (max-width: 991px) {
        flex-direction: column;
    }
}

.list_socials{
    text-align: center;
}

.social{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 0;
    min-width: 35px;
    &>a, &>div{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    p{
        line-height: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        // font-family: 'Noto_Bold';
    }
    .container-picto{
        display: flex;
        height: 60px;
        width: 60px;
        justify-content: center;
        align-items: center;
        img{
            max-width: 24px;
            max-height: 24px;
        }
        &.full{
            width: 100%;
            height: auto;
            img{
                max-width: 100%;
                max-height: none;
                height: auto;
            }
        }
    }
    img{
        height: 40px;
    }
    .text-button{
        font-weight: bold;
    }
    &.complete{
        width: calc(100% / 3 - 20px);
        a{
            width: 100%;
            justify-content: flex-start;
            cursor: pointer;
        }
        .text-button{
            width: calc(100% - 40px);
            text-align: center;
            padding: 0 10px;
        }
        @media screen and (max-width: 991px) {
            width: 360px;
            max-width: 100%;
        }
    }
    @media screen and (max-width: 600px) {
        padding: 10px 4px;
        margin: 0 5px;
        &:first-child{
            min-width: 175px;
        }
        p{
            font-size: 18px!important;
            height: 25px;
        }
        img{
            height: 25px;
        }
    }
}

.overlay_modal_share{
    display:none;
    width: 100vw;
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    height: 100vh;
    top: 0;
    z-index: 2900;
    left: 0;
}

.content_modal_share{
    display: none;
    position: fixed;
    z-index: 3000;
    width: calc(860px - 60px);
    top: 200px;
    transform: inherit;
    left: 0;
    right: 0;
    margin: auto;
    padding: 30px;
    overflow-x: auto;
    flex-direction: column;
    justify-content: space-between;
    background: white;
    padding-top: 50px !important;
    max-width: 500px;
    border-radius: 20px;
    box-shadow: 0px 1px 8px #29220080;
    @media screen and (max-width: 1000px) {
        width: calc(90% - 60px);
    }
    @media screen and (max-width: 768px) {
        padding: 20px;
        width: calc(90% - 40px);
    }
    .title{
        text-align: center;
        color: black;
        font-size: 30px;
        line-height: 1.2;
        max-width: 500px;
        margin: auto;
        @media screen and (max-width: 768px) {
            font-size: 25px;
        }
        @media screen and (max-width: 450px) {
            font-size: 23px;
        }
    }
    .list_email{
        flex: 1;
        max-height: 480px;
        min-height: 200px;
        overflow: auto;
        margin-top: 24px;
        padding-bottom: 50px;
        .container-input{
            display: flex;
            flex-direction: column;
            .input-block{
                display: flex;
                align-items: center;
                margin-top: 12px;
                .delete-block{
                    background: #FF6565;
                    font-weight: bold;
                    color: white;
                    border: none;
                    padding: 10px;
                    cursor: pointer;
                    margin-left: 12px;
                    &:hover{
                        background: #D95656;
                    }
                }
            }
            &:not(:first-child){
                padding-top: 20px;
            }
            label{
                color: black;
                font-size: 18px;
            }
            input{
                border: 1px solid grey;
                width: calc(100% - 30px);
                max-width: 500px;
                margin: 0;
                font-size: 14px;
                height: 40px;
                &::placeholder{
                    font-size: 14px;
                }
                &.error{
                    border-color: #FF6565;
                }
            }
            .verification{
                color: #FF6565;
            }
        }
        
    }
    .add-button{
        background: #59C870;
        font-weight: bold;
        color: white;
        border: none;
        padding: 10px;
        cursor: pointer;
        margin-top: 20px;
        &:hover{
            background: #4CAA5F;
        }
    }
    .container-validation{
        display: flex;
        align-items: center;
        justify-content: center;
        .validation_button{
            background: #59C870;
            font-weight: bold;
            color: white;
            border: none;
            padding: 12px 24px;
            cursor: pointer;
            font-size: 20px;
            border-radius: 8px;
            box-shadow: 0 5px 0 0 #1b8d29;
            text-transform: uppercase;

            &:hover{
                background: #4CAA5F;
            }
        }
    }
    
    .button_close{
        position: fixed;
        top: 10px;
        left: 10px;
        .as_button{
            color: black;
            font-size: 14px;
            line-height: 23px;
            text-transform: uppercase;
            font-weight: bold;
            background: none;
            border: none;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}