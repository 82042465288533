
.slider-multiple {
    padding-top: 10px;
    padding-bottom: 10px;
    
    .swiper-container {
        width: 100%;

        .swiper-slide.slider-height-4  {
            height: 110px;
            background-size: contain;
            background-repeat: no-repeat;
        
            @media #{$xx-layout}{
                height: 110px;
            }
            @media #{$xl-layout}{
                height: 110px;
            }
            @media #{$lg-layout}{
                height: 110px;
            }
            @media #{$md-layout}{
                height: 110px;
            }
            @media #{$xs-layout}{
                height: 110px;
            }
        }

       .swiper-pagination-bullets .swiper-pagination-bullet {
            border-radius: 0;
            height: 14px;
            width: 14px;
            opacity: 0.5;

            &::before {
                display: none;
            }

            &.swiper-pagination-bullet-active {
                opacity: 1;
            }
       }
    }

    .title {
        margin-top: 10px;
        position: relative;
        text-align: center;
        font-size: 20px;
        line-height: 34px;
        overflow: hidden;
        margin-bottom: 0;

        &::after, &::before {
            background-color: #000;
            content: "";
            display: inline-block;
            height: 1px;
            position: relative;
            vertical-align: middle;
            width: 50%;
        }

        &::before {
            right: .5em;
            margin-left: -50%;
        }

        &::after {
            left: .5em;
            margin-right: -50%;
        }

        h1 {
            display: inline;

            span {
                padding: 5px;
                z-index: 100;
            }
        }
        
    }

    .pagination {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .prev, .next {
            background: #f2703e;
            color: #fff !important;
            display: inline-block;
            float: left;
            font-size: 24px !important;
            height: 35px !important;
            line-height: 31px !important;
            text-align: center !important;
            width: 35px !important;
            margin: 0 5px !important;
            
            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }
    }

    .right-images {
        .slider-image {
            width: 100%;
            height: calc(50% - 5px);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        .slider-image:first-child() {
            margin-bottom: 10px;
        }
    }
}