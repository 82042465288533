.activeNav{
    background: #0273A5 !important;
    padding: 0.5rem 0rem 0.5rem 1.9rem !important;
    &:hover{
        svg{
            fill: white !important;
            path{
                fill: white !important;
            }
        }
    }
    svg{
        fill: white !important;
        path{
            fill: white !important;
        }
    }
    span{
        color: white !important;
        font-weight: bold;
    }
}
.activePanel{
    // border-left: 4px solid #2B4553 !important;
    // p{
    //     color: #36738D;
    // }
}
.MuiListItemIcon-root{
    svg{
        border-radius: 5px;
        padding: 0.3rem;
        padding-left: 0;
        font-size: 2.2rem;
    }
}
.MuiListItem-root{
    padding: 0.5rem 1.5rem 0.5rem 1rem !important;
    @media screen and (max-width: 1400px){
        padding: 0.4rem 1.4rem 0.4rem 1.75rem !important;
    }
}

.MuiAccordion-root:before {
    background-color: transparent !important;
}

.MuiAccordionDetails-root{
    padding: 0 !important;
}
