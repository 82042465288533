.breacrumb{
    display: inline-block;
    font-weight: bold;
    .category, .sub-category{
        display: inline-block;
        padding: 8px 20px;
        font-size: 20px;
        @media screen and (max-width: 910px){
            font-size: 16px;
        }
        @media screen and (max-width: 500px) {
            font-size: 14px;
            padding: 8px 10px;
        }
    }
    .category{
        background: #70aecf;
        color: white;
    }
    .sub-category{
        color: #70aecf;
        padding-left: 4px;
    }
}

.subTitle{
    margin-top: 48px;
    font-size: 39px;
    position: relative;
    padding-left: 16px;
    font-weight: bold;
    line-height: 1;
    border-left: 3px solid black;
    @media screen and (max-width: 1000px) {
        font-size: 26px;
    }
    @media screen and (max-width: 768px) {
        font-size: 20px;
        margin-top: 24px;
    }
    @media screen and (max-width: 500px) {
        font-size: 18px;
    }
}