
.three-images-row {
    .row {
        & > div:nth-child(1) {
            padding-left: 15px;
            padding-right: 10px;
        }

        & > div:nth-child(2) {
            padding-left: 12.5px;
            padding-right: 12.5px;
        }

        & > div:nth-child(3) {
            padding-left: 10px;
            padding-right: 15px;
        }

        .image img {
            width: 100%;
        }
    }
}