#builder-template-scope-book{
    .topHomepage{
        position: relative;
        margin: auto;
        overflow: hidden;
        background-position: top;
        background-size: cover;
        .snowflake_white, .snowflake_red{
            @media screen and (max-width: 990px){
                img{
                    width: 18px;
                }
            }
            @media screen and (max-width: 500px){
                img{
                    width: 14px;
                }
            }
        }
        p{
            margin: 0;
        }
        .title{
            padding-top: 60px;
            padding-left: 180px;
            text-align: center;
            z-index: 0;
            position: relative;
            max-width: 550px;
            margin: auto;
            img{
                width: 100%;
            }
            .title_image{
                display: block;
                width: 35vw;
                @media screen and (max-width: 850px) {
                    width: 45vw;
                }
            }
            @media screen and (max-width: 950px) {
                padding-left: 230px;
            }
            @media screen and (max-width: 900px){
                > picture img{
                    max-width: 400px;
                }
            }
            @media screen and (max-width: 700px){
                padding-right: 0;
                padding-top: 30px;
                padding-left:0;
                margin: 0;
                > picture img{
                    max-width: 400px;
                    display: none;
                }
            }
            .snowflake_red{
                position: absolute;
                left: 54%;
                top: 25px;
            }
        }
        .santa_lego_mobile{
            display: none;
            @media screen and (max-width: 700px){
                display: block;
                width: 100%;
                position: relative;
                picture{
                    width: 100%;
                }
            }
        }
        .santa_lego_desktop{
            position: absolute;
            left: -45vw;
            top: 10px;
            max-width: 77.5vw;
            img{
                width: 100%;
            }
            @media screen and (max-width: 1400px) {
                max-width: 87.5vw;
                left: -50vw;
            }
            @media screen and (max-width: 1250px) {
                left: -55vw;
                max-width: 95vw;
            }
            @media screen and (max-width: 950px) {
                left: -47.5vw;
                max-width: 100vw;
            }
            @media screen and (max-width: 900px) {
                left: -43.5vw;
            }
            @media screen and (max-width: 850px){
                top: 50px;
            }
            @media screen and (max-width: 700px){
                display: none;
            }
        }
        .snowflake_1{
            position: absolute;
            bottom: 220px;
            left: 40px;
            z-index: 1;
        }
        .snowflake_2{
            position: absolute;
            top: 290px;
            left: 31%;
            z-index: 1;
        }
        .snowflake_3{
            position: absolute;
            top: 40px;
            left: 30%;
            z-index: 1;
        }
        .ribbon_top_left{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            img{
                width: 18vw;
                @media screen and (max-width: 1200px) {
                    width: 21vw;
                }
                @media screen and (max-width: 700px) {
                    width: 31vw;
                }
            }
        }
        .ribbon_top_right{
            position: absolute;
            top: 0;
            right: 44px;
            z-index: 0;
            img{
                width: 10vw;
                @media screen and (max-width: 1200px) {
                    width: 12.5vw;
                }
                @media screen and (max-width: 700px) {
                    width: 20vw;
                }
            }
            @media screen and (max-width: 900px) {
                right: 20px;
            }
        }
        .ribbon{
            margin: auto;
            margin-top: 30px;
            position: relative;
            z-index: 2;
            font-family: 'Cera_Bold';
            margin-top: 50px;
            margin-bottom: 70px;
            @media screen and (max-width: 768px){
                margin-top: 40px;
            }
            @media screen and (max-width: 700px){
                margin-top: -25%;
            }
            @media screen and (max-width: 500px) {
                margin-top: -30%;
            }
            h1{
                text-align: center;
                max-width: 410px;
                color: white;
                margin: auto;
                position: relative;
                font-weight: 500;
                line-height: 22px;
                font-size: 20px;
                @media screen and (max-width: 600px){
                    font-size: 16px;
                    max-width: 350px;
                    top: 13px;
                }
                @media screen and (max-width: 500px){
                    font-size: 13px;
                    line-height: 15px;
                    max-width: 260px;
                    top: 25px;
                }
            }
        }
        .wrapper_button{
            text-align: center;
            margin-top: 40px;
            height: 35px;
            position: relative;
            @media screen and (max-width: 768px){
                margin-bottom: 20px;
            }
            .container_button{
                position: relative;
                display: inline-block;
                z-index: 1;
                .snowflake_white{
                    position: absolute;
                    left: -120px;
                    bottom: -10px;
                    @media screen and (max-width: 768px){
                        left: -80px;
                    }
                    // @media screen and (max-width: 500px){
                    //     left: 13%;
                    // }
                }
                .snowflake_red{
                    position: absolute;
                    right: -116px;
                    bottom: -35px;
                    @media screen and (max-width: 768px){
                        right: -70px;
                    }
                    // @media screen and (max-width: 500px){
                    //     right: 13%;
                    // }
                }
                a.button_lego{
                    font-size: 20px;
                    line-height: 24px;
                    text-transform: uppercase;
                    padding: 18px 32px;
                    background: #D70008;
                    box-shadow: 0px 3px 0px #33333380;
                    border-radius: 4px;
                    color: white;
                    border: none;
                    font-family: 'Cera_Bold';
                    cursor: pointer;
                    &:hover{
                        filter: brightness(110%);
                    }
                    @media screen and (max-width: 600px){
                        font-size: 16px;
                        line-height: 18px;
                        padding: 13px 23px;
                    }
                }
            }
        }
        .rules{
            margin-top: 24px;
            padding-bottom: 20px;
            text-align: center;
            position: relative;
            z-index: 2;
            .link_reglement{
                color: white;
                text-decoration: underline;
                font-size: 16px;
                line-height: 23px;
                font-family: 'Cera_Bold';
                cursor: pointer;
                @media screen and (max-width: 768px) {
                    font-size: 13px;
                }
            }
        }
        .bottom_section{
            width: 1300px;
            max-width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin: 50px auto 20px;
            margin-bottom: 0;
            padding-bottom: 30px;
            .firstPart{
                position: relative;
                padding-right: 6.5vw;
                img{
                    &:first-child{
                        width: 12vw;
                    }
                    &:last-child{
                        width: 6.5vw;
                        position: absolute;
                        right: 40px;
                        top: 10px;
                    }
                }
            }
            .text{
                padding-top: 100px;
                text-align: center;
                font-family: 'Cera_Black_Italic';
                transform: matrix(1, -0.09, 0.09, 1, 0, 0);
                p{
                    font-size: 1.9vw;
                    line-height: 1.2;
                    color: #032C2A;
                    white-space: nowrap;
                    span{
                        color:#0EA29D;
                    }
                }
            }
            .lastPart{
                padding-left: 50px;
                img{
                    width: 16.5vw;
                }
            }
            @media screen and (max-width: 1700px) {
                margin: 30px auto;
                margin-bottom: 0;
                .text{
                    padding-top: 75px;
                }
            }
            @media screen and (max-width: 1250px) {
                margin: 0px auto 30px;
                margin-bottom: 0;
                .text{
                    padding-top: 60px;
                }
            }
            @media screen and (max-width: 1050px) {
                margin: 30px auto 30px;
                margin-bottom: 0;
                .text{
                    padding-top: 80px;
                }
            }
            @media screen and (max-width: 800px) {
                display: flex;
                flex-wrap: wrap;
                &>div{
                    display: inline-flex;
                    box-sizing: border-box;
                    width: 50%;
                    flex: 50%;
                    align-items: center;
                }
                .firstPart{
                    order: 0;
                    justify-content: center;
                    padding: 0;
                    img{
                        &:first-child{
                            width: 25vw;
                        }
                        &:last-child{
                            width: 17vw;
                            right: 0;
                        }
                    }
                }
                .lastPart{
                    order: 0;
                    padding: 0;
                    justify-content: center;
                    img{
                        width: 34vw;
                    }
                }
                .text{
                    order: 1;
                    justify-content: center;
                    padding-bottom: 40px;
                    p{
                        font-size: 5.5vw;
                    }
                }
            }
            @media screen and (max-width: 500px) {
                &>div{
                    margin-top: -5%;
                }
            }
        }
    }
}

.no-webp #builder-template-scope-book {
    .topHomepage {
        background: url('./image/bg-home-2.png');
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
    }
}

.webp #builder-template-scope-book {
    .topHomepage {
        background: url('./image/bg-home-2.png');
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 100% auto;
        @media screen and (max-width: 1500px){
            background-size: 1500px;

        }
        @media screen and (max-width: 700px){
            background: url('./image/bg-home-mobile.png');
            background-size: contain;
            background-repeat: no-repeat;
            .rules{
                margin-bottom: 40px;
            }
            .bottom_section{
                background: url('./image/vague.png');
                background-position: top center;
                background-size: 130%;
                margin-bottom: 0;
                margin-top: 0;
                padding-bottom: 30px;
                .text{
                    padding-top: 50px;
                }
            }
        }
    }
}