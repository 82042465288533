#swiper-home {
    .slider-area{
        .swiper-slide{
            height: auto;
        }
        button.swiper-button-prev{
            float: left;
        }
        button.swiper-button-next, button.swiper-button-prev{
            width: auto;
            height: auto;
            background: none;
            border: none;
            bottom: 0;
            cursor: pointer;
            .pe-7s-angle-left, .pe-7s-angle-right{
                &::before{
                    font-size: 30px;
                    font-weight: bolder;
                    color: #0273A5;
                }
            }
        }
        .swiper-button-prev{
            left: auto;
            right: 30px;
        }
        .swiper-pagination-bullets {
            bottom: unset !important;
            position: relative !important;
            background: white;
            text-align: center;
            .swiper-pagination-bullet{
                background: white;
                border: 1px solid #0273A5;
                width: 10px !important;
                height: 10px !important;
                margin: 0 4px;
                display: inline-block;
                border-radius: 50%;
                cursor: pointer;
            } 
            .swiper-pagination-bullet-active{
                background: #0273A5 !important;
                cursor: inherit;
            } 
        }
    } 
}