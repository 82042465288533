#builder-template-scope-book{
    .flipBook{
        background: #F2F2F2;
        padding: 0px 32px;

        @media screen and (max-width: 767px){
            padding: 0px 10px;
        }
        @media screen and (max-width: 600px){
            padding: 0px;
        }

        .modalProduct{
            z-index: 3001;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            // animation: successAnimation 3s ease-in-out;
            background: white;
            width: 600px;
            height: 450px;
            border-radius: 20px;
            padding: 8px 16px;
            margin: auto;
            transition: all 250ms linear;
            opacity: 0;
            visibility: hidden;
            @media screen and (max-width: 650px){
                width: 80%;
                height: 650px;
                padding-top: 20px;
            }
            &.show{
                opacity: 1;
                visibility: visible;
                transition: all 250ms linear;
            }
            .popup_detail_close{
                position: absolute;
                right: 10px;
                top: 10px;
                font-size: 30px;
                line-height: 20px;
                cursor: pointer;
            }
            .productInfo{
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                @media screen and (max-width: 650px){
                    align-items: flex-start;
                }
                .wrapper{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    @media screen and (max-width: 650px){
                        flex-direction: column;
                    }
                    .modalLeft{
                        width: 40%;
                        @media screen and (max-width: 650px){
                            width: 250px;
                        }
                        .imageProduct{
                            img{
                                max-width: 100%;
                                max-height: 300px;
                            }
                        }
                    }
                    .modalRight{
                        width: 60%;
                        text-align: left;
                        padding-left: 20px;
                        @media screen and (max-width: 650px){
                            width: 100%;
                            margin-top: 30px;
                            padding: 0;
                        }
                        .titleProduct{
                            font-size: 20px;
                            margin-bottom: 20px;
                        }
                        .price {
                            .amount {
                                font-weight: bold;
                            }
                        }
                        .description{
                            max-height: 160px;
                            overflow: auto;
                        }
                    }
                }
                .actions{
                    position: absolute;
                    bottom: 20px;
                    left: 50%;
                    transform: translate(-50%);
                    display: flex;
                    flex-direction: column;
                    button{
                        padding: 10px 20px;
                        font-weight: bold;
                        font-family: 'Roboto';
                        outline: none;
                        cursor: pointer;
                    }
                    .validate{
                        background: #0D4119;
                        color: white;
                        border: 1px solid #0D4119;
                        &:hover{
                            background: white !important;
                            color: #0D4119 !important;
                        }

                        &.disabled {
                            background: grey;
                            color: white;
                            cursor: default;
                            border-color: white;
                        }
                    }
                    .validate_2 {
                        background: #0D4119;
                        font-family: 'Roboto', sans-serif;
                        color: white;
                        border: 1px solid #0D4119;
                        font-weight: bold;
                        font-size: 13.33px;
                        text-decoration: none;
                        padding: 10px 20px !important;
                        margin-right: 5px;
                        cursor: pointer;

                        &:hover{
                            background: white !important;
                            color: #0D4119 !important;
                        }

                        &.disabled {
                            background: grey;
                            color: white;
                            cursor: default;
                            border-color: white;
                        }
                    }
                    .cancel{
                        border: none;
                        background: none;
                    }
                }
            }

            .variante-select {
                margin-top: 15px;
                height: 35px;
                border-radius: 4px;
                max-width: 100%;
            }
        }
        .successAdd{
            z-index: 3002;
            position: absolute;
            top: 260px;
            left: 50%;
            transform: translate(-50%);
            // animation: successAnimation 3s ease-in-out;
            background: #02AE4D;
            border-radius: 20px;
            padding: 8px 16px;
            margin: auto;
            transition: all 250ms linear;
            &.show-alert{
                opacity: 1;
                visibility: visible;
                transition: all 250ms linear;
            }
            &.hide-alert{
                opacity: 0;
                visibility: hidden;
                transition: all 250ms linear;
            }
            span{
                color: white;
                // font-size: 130px;
                line-height: 1;
                position: relative;
            }
        }

        &.readOnly {
            background: none;
            padding: 0;
            .wrapper_text{
                display: none;
            }
            .container_choices{
                .wrapperChoices{
                    display: none;
                }
            }
            .wrapperButton{
                display: none;
            }
        }

        .prevPageWrapper, .nextPageWrapper{
            z-index: 100;
            top: 50%;
            transform: translateY(-50%);
        }

        &.cart {
            .successAdd{
                top: 220px;
            }
            .pageButton{
                width: 40px;
                height: 40px;
                padding: 5px;
            }
            .prevPageWrapper{
                left: -65px;
                @media screen and (max-width: 1200px){
                    left: 0;
                }
            }
            .nextPageWrapper{
                right: -65px;
                @media screen and (max-width: 1200px){
                    right: 0;
                }
            }
            .turn-pagination{
                align-items: center;
                .prevPageWrapper, .nextPageWrapper{
                    position: relative;
                    display: none;
                }
                li.previous{
                    left: -10px;
                    position: relative;
                }
                li.next{
                    right: -10px;
                    position: relative;
                }
                li{
                    color: #000;
                    font-size: 23px;
                    font-family: 'Roboto', sans-serif;
                    &.active{
                        font-weight: bold;
                        font-size: 33px;
                        text-decoration: none;
                    }
                }
            }
            .white {
                color: #ffffff !important;
            }
            .black {
                color: #000000;
            }
            .cropPage{
                cursor: pointer;
            }
            .overlayModal{
                width: 100vw;
                position: fixed;
                height: 110vh;
                top: 0;
                z-index: 2900;
                left: 0;
                display: none;
                cursor: pointer;
                &.open{
                    display: block;
                }
                &.black{
                    z-index: 3001;
                    background-color: rgba(0,0,0,0.6);
                }
            }
        }

        .minicart-container {
            width: 1230px;
            max-width: 100%;
            margin: auto;
            position: fixed;
            top: 100px;
            right: 0;
            z-index: 2901;
            left: 50%;
            transform: translateX(-50%);
        }

        .minicart {
            position: absolute;
            width: 300px;
            top: 0;
            right: 0;
            background: white;
            z-index: 2901;
            box-shadow: 0px 3px 20px #0000007C;
            // display: none;
            transition: all 400ms ease-in-out;
            transform-origin: top center;
            opacity: 0;
            visibility: hidden;
            @media screen and (max-width: 500px){
                width: 100%;
            }
            @keyframes slideDown {
                0% { 
                    opacity: 0;
                    transform: translateY(-60px);
                    visibility: hidden;
                }
                
                100% {
                    opacity: 1;
                    transform: translateY(0);
                    visibility: visible;
                }
            }
            &.open{
                display: block;
                transition: all 1s ease-in-out;
                animation: slideDown 400ms ease-in-out forwards;
                visibility: visible;
            }
            .total{
                background: #0D4119;
                color: white;
                padding: 10px;
                font-family: 'Roboto', sans-serif;
                font-size: 15px;
                line-height: 21px;
                span{
                    font-family: 'Roboto', sans-serif;
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: bold;
                    margin-left: 8px;
                }
            }
            .listProducts{
                min-height: 190px;
                max-height: 190px;
                background: white;
                width: 100%;
                overflow: scroll;
                overflow-x: hidden;
                .productWrapper{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-top: 16px;
                    padding: 0 10px;
                    .imageProduct{
                        width: calc(30% - 10px);
                        img{
                            max-width: 100%;
                            max-height: 300px;
                        }
                    }
                    .infosProduct{
                        width: calc(70% - 10px);
                        .name_product{
                            font-size: 12px;
                            line-height: 12px;
                            font-family: 'Roboto', sans-serif;
                            color: #393939;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical; 
                            max-height: 24px;
                            text-align: left;
                        }
                        .numbers{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 4px;
                            .qty{
                                display: flex;
                                align-items: center;
                                font-family: 'Roboto', sans-serif;
                                font-size: 12px;
                                line-height: 12px;
                                font-weight: bold;
                                text-align: left;
                                .wrap-buttons{
                                    display: flex;
                                    align-items: center;
                                }
                                .qtyButtons{
                                    background: none;
                                    border: 1px solid #0061A8;
                                    color: #0061A8;
                                    border-radius: 50%;
                                    width: 19px;
                                    height: 19px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    cursor: pointer;
                                    &:focus{
                                        outline: none;
                                    }
                                }
                                span.value{
                                    padding: 0 8px;
                                }
                                span.text{
                                    margin-right: 8px;
                                }
                            }
                            .price_product{
                                font-family: 'Roboto', sans-serif;
                                font-weight: bold;
                                text-align: left;
                                font-size: 16px;
                                line-height: 12px;
                                color: #0061A8;
                            }
                        }
                        .delete_product{
                            font-family: 'Roboto', sans-serif;
                            font-weight: bold;
                            text-align: left;
                            font-size: 12px;
                            line-height: 12px;
                            color: red;
                            margin-top: 4px;
                            cursor: pointer;
                        }
                    }
                }
            }
            .validateWrapper{
                padding: 15px;

                .validateCart {
                    border: 0;
                    color: white;
                    font-family: 'Roboto', sans-serif;
                    font-weight: bold;
                    padding: 5px;
                    text-transform: uppercase;
                    cursor: pointer;
                    transition: all 0.8s ease;

                    &.disabled {
                        background: grey;
                        color: white;
                        border: none;
                        &:hover {
                            background: grey;
                            color: white;
                        }
                    }
                }
            }
        }

        .wrapper_text {
            p{
                font-size: 16px;
                line-height: 18px;
                font-family: 'Ubuntu_Regular';
                font-style: italic;
                margin: 0;
                @media screen and (max-width: 500px){
                    font-size: 12px;
                    line-height: 13px;
                }
                &.title_red{
                    font-family: 'Ubuntu_Bold_Italic';
                    color: #CF2E25;
                    margin-bottom: 8px;
                    font-weight: 500;
                }
            };
        }

        .container {
            .container_choices{
                width: 100%;
                // height: 100px;
                margin-top: 12px;
                margin-bottom: 12px;
                position: relative;
                .message_displayed{
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%);
                    padding: 8px 16px;
                    border-radius: 20px;
                    width: 350px;
                    top: 140px;
                    color: white;
                    background: #CF2E25;
                    z-index: 98;
                    @media screen and (max-width: 500px){
                        width: 270px;
                    }
                    &.show-alert{
                        opacity: 1;
                        visibility: visible;
                        transition: all 250ms linear;
                    }
                    &.hide-alert{
                        opacity: 0;
                        visibility: hidden;
                        transition: all 250ms linear;
                    }
                }
                .wrapperChoices{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    @media screen and (max-width: 500px){
                        flex-wrap: wrap;
                    }
                    .choiceBox{
                        position: relative;
                        border: 1px solid #ADBBBA;
                        display: flex;
                        .empty{
                            height: 80px;
                            width: 80px;
                            background-color: #CF2E2566;
                            background-image: url('./image/icone-wishlist.svg');
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: 60px;
                            position: relative;
                            @media screen and (max-width: 500px){
                                height: 58px;
                                width: 58px;
                                margin: auto;
                                background-size: 30px;
                            }
                            span{
                                position: absolute;
                                color: #CF2E25;
                                font-size: 16px;
                                line-height: 18px;
                                font-family: 'Ubuntu_Bold_Italic';
                                font-weight: 500;
                                right: 10px;
                                bottom: 10px;
                            }
                        }
                        @media screen and (max-width: 500px){
                            margin-bottom: 8px;
                            background: white;
                            width: calc((100vw/4) - 16px);
                            height: calc((100vw/4) - 16px);
                        }
                        @keyframes translatingFromBottom {
                            from {
                                transform: translateY(100px);
                                opacity: 0;
                            }
                        }
                        @keyframes notDisplayed {
                            0% {
                                opacity: 0;
                            }
                            100% {
                                opacity: 1;
                            }
                        }
                        .selected_product{
                            width: 100%;
                            height: 100%;
                            background: white;
                            text-align: center;
                            padding: 0 8px;
                            box-sizing: border-box;
                            left: 50%;
                            transform: translate(-50%);
                            animation: translatingFromBottom 1s ease-in-out;
                            position: absolute;
                            z-index: 1;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            @media screen and (max-width: 500px){
                                margin: auto;
                            }
                            .deleteProduct{
                                width: 17px;
                                height: 17px;
                                position: absolute;
                                left: -8px;
                                top: -8px;
                                background: url('./image/croix.svg');
                                border-radius: 50%;
                                cursor: pointer;
                                animation: notDisplayed 2s ease-in-out;
                            }
                            .image_product{
                                min-height: 48px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                @media screen and (max-width: 500px){
                                    min-height: 40px;
                                }
                                img{
                                    max-width: 100%;
                                    max-height: 48px;
                                    @media screen and (max-width: 500px){
                                        max-height: 40px;
                                    }
                                }
                            }
                            .infos_product{
                                font-size: 10px;
                                line-height: 12px;
                                width: 100%;
                                font-family: 'Sansa_Normal';
                                color: #4B4B4B;
                                margin-top: 6px;
                                max-height: 24px;
                                min-height: 24px;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;  
                                @media screen and (max-width: 500px){
                                    font-size: 9px;
                                    margin-top: 4px;
                                }
                            }
                        }
                        &:not(:last-child){
                            margin-right: 23px;
                            @media screen and (max-width: 767px){
                                margin-right: 7px;
                            }
                        }
                    }
                }
            }
            .game{
                width: 100%;
                position: relative;
                .container_flipbook{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    // background: white;
                    justify-content: space-between;
                    justify-content: center;
                    .page_flipbook{
                        width: 48%;
                        display: flex;
                        align-items: center;
                        position: relative;
                        @media screen and (max-width: 768px){
                            width: 100%;
                        }
                        img{
                            width: 100%;
                            max-height: 100%;
                        }
                        &#pageFlipbook0{
                            .zoomPage{
                                left: 5px;
                            }
                        }
                        &#pageFlipbook1{
                            .zoomPage{
                                right: 5px;
                            }
                        }
                        .zoomPage{
                            position: absolute;
                            top: 5px;
                            cursor: pointer;
                            z-index: 10;
                            width: 30px;
                            height: 30px;
                            background: #CF2E25;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            &.right{
                                right: 5px;
                            }
                            img{
                                width: 70%;
                                fill: white;
                            }
                        }
                    }
                }
            }
        }

        .cropPage {
            position: absolute;
            opacity: 0.8;
            cursor: url('./image/curseurs.png') 15 15, auto;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover{
                opacity: 1;
            }
            img.addIcon{
                max-width: 30px;
                max-height: 30px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .nextPageWrapper {
            right: 10px;
            position: absolute;
            .pageButton{
                transform: rotate(180deg);
            }
        }

        .prevPageWrapper {
            left: 10px;
            position: absolute;
        }

        .pageButton {
            width: 32px;
            height: 32px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            border: solid 2px #BF9233;
            border-radius: 50%;
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            &:not(.disabled){
                &:hover{
                    filter: brightness(120%);
                }
            }
            &:focus{
                outline: none;
            }
            &.disabled{
                filter: grayscale(1) !important;
                cursor: inherit;
            }
        }

        .pagination {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 16px;
            position: relative;
            width: 100%;
            margin-bottom: 16px;
            .numberPage{
                margin: 0 2px;
                cursor: pointer;
                &.numberPage--active{
                    text-decoration: underline;
                }
            }
        }

        .turn-pagination {
            display: flex;
            flex-direction: row;
            position: relative;
            width: 100%;
            padding: 0;
            justify-content: center;
            z-index: 11;
            margin: 0;
            margin-top: 10px;
            li {
                list-style: none;
                margin: 0 5px;
                outline: none;
                cursor: pointer;

                a {
                    outline: none;
                }
                
                &.active {
                    text-decoration: underline;
                }
            }

            .pageButton{
                // width: 32px;
                // height: 32px;
                border: none;
                width: 0; 
                height: 0; 
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                
                border-left: 16px solid #D70008;
                // background: url(./image/Btt.svg);
                background: none;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                cursor: pointer;
                border-radius: 0;
                // &:hover:not(.disabled){
                //     filter: brightness(120%);
                // }
                &:focus{
                    outline: none;
                }
                &:disabled{
                    filter: grayscale(1) !important;
                    cursor: inherit;
                }
            }
            .nextPageWrapper,.prevPageWrapper{
                position: absolute;
                display: flex;
                &:not(:disabled){
                    &:hover{
                        filter: brightness(120%);
                    }
                }
            }
            .nextPageWrapper{
                right: 0;
                .pageButton{
                    transform: none;
                }
            }
            .prevPageWrapper{
                left: 0;
                .pageButton{
                    transform: rotate(180deg);
                }
            }
            @media screen and (max-width: 600px) {
                margin-top: 100px;
                .nextPageWrapper{
                    top: -36px;
                }
                .prevPageWrapper{
                    top: -36px;
                }
            }
        }

        .wrapperButton {
            width: 100%;
            margin-top: 16px;
            margin-bottom: 16px;
            z-index: 99;
            position: relative;
            .buttonValidation{
                line-height: 24px;
                text-transform: uppercase;
                padding: 18px 32px;
                background: #D70008;
                box-shadow: 0px 3px 0px #33333380;
                border-radius: 4px;
                color: white;
                border: none;
                font-family: 'Cera_Bold';
                padding: 8px 22px;
                color: white;
                text-decoration: none;
                text-transform: uppercase;
                display: block;
                max-width: 340px;
                margin: auto;
                cursor: pointer;
                &:not(.disabled){
                    &:hover{
                        filter: brightness(120%);
                    }
                }
                &.disabled{
                    border: 1px solid #BABABB;
                    background: none;
                    color: #BABABB;box-shadow: none;
                }
                @media screen and (max-width: 500px){
                    font-size: 13px;
                    line-height: 16px;
                    width: 150px;
                }
            }
        }

        .magazine-container {
            position: absolute;
            left: 0; 
            top: 0;
            width: 100%;
            z-index: 10;
            overflow: hidden;

            .turn-overlay {
                background-color: #fff;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                z-index: 1000;
                
                p {
                    font-size: 30px;
                    font-family: 'Roboto', sans-serif;

                    .ellipsis-anim span {
                        opacity: 0;
                        -webkit-animation: ellipsis-dot 1s infinite;
                        animation: ellipsis-dot 1s infinite;
                    }
                    
                    .ellipsis-anim span:nth-child(1) {
                        -webkit-animation-delay: 0.0s;
                        animation-delay: 0.0s;
                    }
                    .ellipsis-anim span:nth-child(2) {
                        -webkit-animation-delay: 0.1s;
                        animation-delay: 0.1s;
                    }
                    .ellipsis-anim span:nth-child(3) {
                        -webkit-animation-delay: 0.2s;
                        animation-delay: 0.2s;
                    }
                    
                    @-webkit-keyframes ellipsis-dot {
                          0% { opacity: 0; }
                         50% { opacity: 1; }
                        100% { opacity: 0; }
                    }
                    
                    @keyframes ellipsis-dot {
                          0% { opacity: 0; }
                         50% { opacity: 1; }
                        100% { opacity: 0; }
                    }
                }
            }
        }

        .magazine {
            width: 100%;
            // height: 646px;
            // height: 500px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            position: relative;

            .page-wrapper > div:first-child {
                // height: 100% !important;
                // min-height: 100% !important;
            }

            .white {
                background-color: rgba(255, 255, 255, 1);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            &.hidden {
                height: auto;
                // left: -2000px;
            }

            .page {
                width: 50%;
                height: auto !important;
                font-size: 0;
                background-color:white;
                -webkit-box-shadow:0 0 20px rgba(0,0,0,0.2);
                -moz-box-shadow:0 0 20px rgba(0,0,0,0.2);
                -ms-box-shadow:0 0 20px rgba(0,0,0,0.2);
                -o-box-shadow:0 0 20px rgba(0,0,0,0.2);
                box-shadow:0 0 20px rgba(0,0,0,0.2);
                position: relative;

                &.odd{
                    .zoomPage{
                        left: 10px;
                        right: auto;
                    }
                }

                .zoomPage{
                    position: absolute;
                    bottom: 5px;
                    right: 10px;
                    cursor: pointer;
                    z-index: 10;
                    width: 30px;
                    height: 30px;
                    background: #CF2E25;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    img{
                        width: 70%;
                        height: auto;
                    }
                }

                &.mobile {
                    width: 100%;
                }
               
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .modalZoom {
            position: fixed;
            z-index: 3000;
            // background-color: white;
            // width: 780px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            // padding: 30px;
            // height: 63vh;
            overflow-x: auto;
            // @media screen and (max-width: 1000px) {
            //     width: calc(90% - 60px);
            // }
            // @media screen and (max-width: 768px) {
            //     height: 50vh;
            // }
            // @media screen and (max-width: 500px) {
            //     height: 30vh;
            // }
            // @media screen and (max-width: 370px) {
            //     width: auto;
            // }
            
            .contentPage{
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .message_displayed{
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%);
                    padding: 8px 16px;
                    border-radius: 20px;
                    width: 350px;
                    top: 140px;
                    color: white;
                    background: #CF2E25;
                    z-index: 10000;
                    &.show-alert{
                        opacity: 1;
                        transition: all 250ms linear;
                    }
                    &.hide-alert{
                        opacity: 0;
                        transition: all 250ms linear;
                    }
                    @media screen and (max-width: 500px){
                        width: 270px;
                    }
                }
                @keyframes successAnimation {
                    0% {
                        opacity: 0;
                        // transform: scale(0);
                    }
                    10%{
                        opacity: 1
                    }
                    50% {
                        opacity: 1;
                        // transform: scale(2);
                    }
                    90% {
                        opacity: 1;
                        // transform: scale(2);
                    }
                    100% {
                        opacity: 0;
                        // transform: scale(0);
                    }
                }
                .overlay{
                    width: 100vw;
                    background-color: rgba(0,0,0,0.6);
                    position: fixed;
                    height: 110vh;
                    top: 0;
                    z-index: 2900;
                    left: 0;
                }
                .wrapper_page{
                    position: relative;
                    // width: 60%;
                    // margin: auto;
                    // height: 100%;
                    z-index: 3000;
                    img{
                        width: auto;
                        max-height: 80vh;
                        @media screen and (max-width: 1000px) {
                            width: 100%;
                        }
                    }
                    .wrapper_buttonClose{
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%);
                        bottom: -50px;
                        button{
                            background: #CF2E25;
                            color: white;
                            border: none;
                            padding: 10px 20px;
                            text-transform: uppercase;
                            font-weight: bold;
                            cursor: pointer;
                            &:focus{
                                outline: none;
                            }
                        }
                    }
                }
            }
        }
    }
}