
.bloc-text {
    padding-top: 10px;
    padding-bottom: 10px;

    .title {
        margin-top: 10px;
        position: relative;
        text-align: center;
        font-size: 20px;
        line-height: 34px;
        overflow: hidden;
        margin-bottom: 0;

        &::after, &::before {
            background-color: #000;
            content: "";
            display: inline-block;
            height: 1px;
            position: relative;
            vertical-align: middle;
            width: 50%;
        }

        &::before {
            right: .5em;
            margin-left: -50%;
        }

        &::after {
            left: .5em;
            margin-right: -50%;
        }

        h1 {
            display: inline;

            span {
                padding: 5px;
                z-index: 100;
            }
        }
    }

    .description {
        text-align: center;
        padding: 30px 30px 15px 30px;
    }
}