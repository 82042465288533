.panel-drag {
    border: solid 1px #DBDDDD !important;
    background-color: #EDEEEE;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75px;
    font-size: 10px;
    color: #727779;
    transition: all ease-in-out .1s;
    cursor: grab;
    opacity: 1;
    text-align: center;
    padding: 0 36px;

    &:hover {
        background-color: #F5F5F5;
        font-weight: bold;
        color: #151619;
    }

    svg {
        margin-bottom: 4px;
    }
}