
.three-images-row {
    .row {
        img {
            width: 100%;
        }

        .left-image {
            padding-left: 0;
            padding-right: 10px;
        }

        .right-images {
            .image {
                width: 100%;
                height: calc(50% - 5px);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
    
            .image:first-child() {
                margin-bottom: 10px;
            }
        }
    }
}