#builder-template-scope-book{
    section.scope-book{
        z-index: 2;
        position: relative;
        .originBanner{
            display: none;
            text-align: center;
            // height: 50px;
            padding: 10px 0px;
            @media screen and (max-width: 768px) {
                display: block;
            }
            img{
                max-height: 40px;
                max-width: 100px;
            }
        }
        .wrapper_lego{
            text-align: center;
            padding: 15px 0;
            img{
                width: auto;
                height: 100%;
                max-height: 56px;
            }
            @media screen and (max-width: 768px) {
                padding: 8px 0;
                img{
                    max-height: 36px;
                }
            }
        }
        .banner{
            background-color: #019CDD;
            color: white;
            p{
                margin: 0;
                padding: 10px 0px;
                font-size: 20px;
                line-height: 29px;
                font-weight: 500;
                text-align: center;
                font-family: 'Cera_Bold';
                @media screen and (max-width: 768px){
                    font-size: 16px;
                    padding: 4px 0;
                }
                @media screen and (max-width: 500px){
                    font-size: 13px;
                    line-height: 19px;
                }
            }
        }
    }
    .header_cart{
        padding: 0;
        background: white;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.sticky {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 101;
        }
        .container{
            width: calc(1230px - 40px);
            max-width: 100%;
            margin: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            padding: 0 20px;
            @media screen and (max-width: 768px){
                justify-content: flex-end;
                height: 100px;
                .wrapper_header_left{
                    .homeIcon{
                        position: absolute;
                        left: 20px;
                        margin-left: 0 !important;
                    }
                }
                .logo{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
                .cart_wrapper{
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            .wrapper_header_left{
                display: flex;
                align-items: center;
                .homeIcon{
                    margin-left: 40px;
                    cursor: pointer;
                    svg{
                        fill: #0D4119;
                        width: 30px;
                        height: 30px;                            
                    }
                }
            }
            .cart_wrapper{
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                .debris {
                    display: none;
                    position: absolute;
                    width: 28px;
                    height: 28px;
                    background-color: #ED7424;
                    opacity: 1.0;
                    overflow: hidden;
                    border-radius: 8px;
                }
                .cart-icon{
                    position: relative;
                    z-index: 10;
                    .product_qty{
                        position: absolute;
                        background: #ED7424;
                        padding: 1px 5px;
                        vertical-align: top;
                        margin-left: -10px;
                        font-size: 9px;
                        color: white;
                        padding-left: 5px;
                        padding-right: 5px;
                        border-radius: 9px;
                        right: -5px;
                        top: -5px;
                    }
                }
                span{
                    margin-left: 9px;
                    color: #0D4119;
                    text-transform: uppercase;
                    font-size: 13px;
                    line-height: 18px;
                    font-family: 'Roboto', sans-serif;
                    font-weight: bold;
                    @media screen and (max-width: 768px){
                        display: none;
                    }
                }
            }
        }
    }
}