.slider-product {
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;

    .title {
        margin-top: 70px;
        position: relative;
        text-align: center;
        font-size: 32px;
        line-height: 34px;
        margin-bottom: 30px;

        h1 {
            font-family: 'Cera Pro', sans-serif;
            font-weight: bold;
            margin-bottom: 0;
        }
    }

    .swiper-wrapper {

        .card {
            height: 100%;
            margin: 30px 0;
            justify-content: flex-start;
            border: none;
    
            .slider-image {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                background-color: #fff;
                position: relative;
                top: 0;
                width: 100%;
                height: 300px;
                border: 1px solid rgba(0, 0, 0, 0.125);
    
                img {
                    max-width: 100%;
                    height: 90%;
                    object-fit: cover;
                    background-position: center center;
                    background-repeat: no-repeat;
                }

                @media screen and (max-width: 1200px) {
                    img {
                        height: 70%;
                    }
                }
                @media screen and (max-width: 768px) {
                    img {
                        height: 80%;
                    }
                }
            }
    
            @media screen and (max-width: 768px) {
                .slider-image {
                    height: 200px;
                }
            }
    
            .card-text {
                width: 100%;
                padding: 16px 10px;
                background-color: #eee;
    
                .ref-product {
                    font-family: 'Cera Pro', sans-serif;
                    margin-bottom: 0px;
                    font-size: 12px;
                    font-weight: 500 !important;
                    height: 24px;
                }

                .title-product {
                    font-family: 'Cera Pro', sans-serif;
                    margin-bottom: 0px;
                    font-weight: bold !important;
                    font-size: 18px;
                    height: 55px;
                }

                @media screen and (max-width: 768px) {
                    .title-product {
                        font-size: 16px !important;
                    }
                }

                @media screen and (max-width: 500px) {
                    .title-product {
                        font-size: 15px !important;
                    }
                }
    
                .price {
                    font-family: 'Cera Pro', sans-serif;
                    font-weight: bold !important;
                    margin-bottom: 24px;
                    font-size: 20px;
                    height: 24px;
                }
    
                button {
                    width: 100%;
                    font-weight: 500;
                    margin-top: 10px;
                    font-weight: 600;
                }
            }
        }
    }

    .button {
        margin-top: 30px;
        margin-bottom: 90px;
        text-align: center;

        button {
            font-weight: 500 !important;
            font-family: 'Cera Pro', sans-serif;
            border: 2px solid #000;

            &:hover {
                color: #fff !important;
                background-color: #000 !important;
                opacity: 1 !important;
            }
        }

        @media screen and (max-width: 768px) {
            button {
                font-size: 18px !important;
                padding: 4px 36px !important;
            }
        }
    }

    .pagination {
        display: flex;
        justify-content: center;
        width: 100%;

        .prev, .next {
            position: absolute;
            top: 50%;
            color: '#fff' ;
            display: inline-block;
            font-size: 24px;
            height: 35px;
            line-height: 31px;
            text-align: center;
            width: 35px;
            margin: 0 5px;
            
            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }

        .prev {
            left: 2%;
        }

        .next {
            right: 2%;
        }

        @media screen and (max-width: 768px) {
            .prev {
                left: -2%;
            }
    
            .next {
                right: -2%;
            }
        }
    }
}