//SANSA
@font-face {
    font-family: 'Sansa_Normal';
    src: url('../fonts/Sansa/SansaSoftPro-Normal.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display:swap;
}
@font-face {
    font-family: 'Sansa_Bold';
    src: url('../fonts/Sansa/SansaSoftPro-Bold.ttf') format('truetype');
    font-style: normal;
    font-display:swap;
}
//CERA
@font-face {
    font-family: 'Cera_Normal';
    src: url('../fonts/Cera/Cera\ Pro\ Medium.otf') format('truetype');
    font-style: normal;
    font-display:swap;
}
@font-face {
    font-family: 'Cera_Black_Italic';
    src: url('../fonts/Cera/Cera\ Pro\ Black\ Italic.otf') format('truetype');
    font-weight: normal;
    font-display:swap;
}
@font-face {
    font-family: 'Cera_Bold';
    src: url('../fonts/Cera/Cera\ Pro\ Bold.otf') format('truetype');
    font-weight: normal;
    font-display:swap;
}
//UBUNTU
@font-face {
    font-family: 'Ubuntu_Regular';
    src: url('../fonts/ubuntu/Ubuntu-Regular.ttf') format('truetype');
    font-style: normal;
    font-display:swap;
    // letter-spacing: -1px;
}
@font-face {
    font-family: 'Ubuntu_Bold';
    src: url('../fonts/ubuntu/Ubuntu-Bold.ttf') format('truetype');
    font-display:swap;
    // letter-spacing: -1px;
}
@font-face {
    font-family: 'Ubuntu_Bold_Italic';
    src: url('../fonts/ubuntu/Ubuntu-BoldItalic.ttf') format('truetype');
    font-display:swap;
    // letter-spacing: -1px;
}

//ROBOTO

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-display:swap;
    // letter-spacing: -1px;
}

@font-face {
    font-family: 'Roboto_Condensed';
    src: url('../fonts/Roboto/RobotoCondensed-Regular.ttf') format('truetype');
    font-display:swap;
    // letter-spacing: -1px;
}


@import "../components/BannerLink/BannerLink.scss";
@import "../components/FlipBook/FlipBook.scss";
@import "../components/FooterBook/FooterBook.scss";
@import "../components/GiftsList/GiftsList.scss";
@import "../components/HeaderBook/HeaderBook.scss";
@import "../components/PostCardBook/PostCardBookOld.scss";
@import "../components/RecapBlockBook/RecapBlockBookOld.scss";
@import "../components/ResultScreenBook/ResultScreenBookOld.scss";
@import "../components/TopHomepageBook/TopHomepageBookOld.scss";
@import "../components/RulesBlock/RulesBlock.scss";
@import "../components/CopyrightBook/CopyrightBook.scss";