.text_reglement{
    display: none;
    position: fixed;
    z-index: 3000;
    background-color: white;
    width: calc(860px - 60px);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 30px;
    height: 80vh;
    overflow-x: auto;
    h2, h3, p{
        max-width: 100%;
    }
    h2{
        @media screen and (max-width: 768px) {
            font-size: 20px;
        }
    }
    p{
        text-align: justify;
        margin: 16px 0;
        font-size: 16px;
        line-height: 24px;
        @media screen and (max-width: 768px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
    ul{
        li{
            @media screen and (max-width: 768px) {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    @media screen and (max-width: 1000px) {
        width: calc(90% - 60px);
    }
    @media screen and (max-width: 768px) {
        padding: 20px;
        width: calc(90% - 40px);
    }
    .button_close{
        position: fixed;
        bottom: 1vw;
        left: 50%;
        transform: translate(-50%);
        .as_button{
            font-size: 26px;
            line-height: 1;
            text-transform: uppercase;
            padding: 6px 12px;
            background: #D70008;
            box-shadow: 0px 3px 0px #33333380;
            border-radius: 50%;
            color: white;
            border: none;
            font-family: 'Cera_Bold';
            cursor: pointer;
        }
        @media screen and (max-width: 768px) {
            bottom: 3vw;
        }
    }
}
.overlay_reglement{
    display:none;
    width: 100vw;
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    height: 110vh;
    top: 0;
    z-index: 2900;
    left: 0;
}