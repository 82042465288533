div.alreadyplayed, div.end{
    div.popup{
        position: relative;
        .popup_content{
            &>div{
                display: inline-block;
                vertical-align: top;
                &.inline_div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 20px;
                    img{
                        width: 120px;
                        margin: 10px 20px 10px 0; 
                    }
                }
                @media screen and (max-width: 480px){
                    img{
                        width: 100px;
                    }
                }
            }
            hr{
                margin: 20px auto;
            }
        }
    }
}