body #builder-template-scope-game.lego_90_challenge_la_roue_legor .first form div.consentOptionnal, body #builder-template-scope-game.es_lego_90_challenge_la_roue_legor .first form div.consentOptionnal{
    display: none;
}
body #builder-template-scope-game.lego_90_challenge_la_roue_legor .amazing-bloc.hide-not-lego{
    display: none;
}
body.legostore #builder-template-scope-game.lego_90_challenge_la_roue_legor .amazing-bloc.hide-not-lego{
    display: block;
}
body.legoretailer #builder-template-scope-game.lego_90_challenge_la_roue_legor .amazing-bloc.hide-not-lego{
    display: block;
}
body.rs #builder-template-scope-game.lego_90_challenge_la_roue_legor .amazing-bloc.hide-not-lego{
    display: block;
}
// body.legostore #builder-template-scope-game.lego_90_challenge_la_roue_legor .first form div.consent{
//     display: block;
// }
#builder-template-scope-game .social .container-picto.full img{
    @media screen and (max-width: 600px) {
        max-width: 250px;
    }
}
#builder-template-scope-game.lego_90_challenge_la_roue_legor, #builder-template-scope-game.es_lego_90_challenge_la_roue_legor{
    max-width: 1180px;
    margin: auto;
    font-family: 'Cera_Normal';
    header{
        .banner{
            p{
                @media screen and (max-width: 700px) {
                    font-size: 13px !important;
                }
            }
        }
    }
    .first{
        height: 690px;
        .logo{
            display: none;
            @media screen and (max-width: 700px) {
                display: block;
                right: 0;
                top: -30px;
                position: absolute;
                width: 100% !important;
                max-width: none !important;
                z-index: 0;
            }
            @media screen and (max-width: 400px) {
                top: 0;
            }
        }
        h1{
            margin: 10px auto;
            z-index: 1;
            @media screen and (max-width: 700px) {
                margin-top: 120px;
            }
            @media screen and (max-width: 500px) {
                margin-top: 60px;
            }
            strong{
                font-family: 'Cera_Black_Italic';
            }
            p{
                font-style: italic;
                font-weight: normal;
                line-height: 1 !important;
            }
        }
        h2{
            z-index: 1;
        }
        form.complete_form{
            max-width: 640px;
            z-index: 1;
            input{
                display: inline-flex;
            }
            input[type="text"], input[type="date"]{
                border-radius: 0!important;
                border: 2px solid black!important;
                font-family: 'Arial', sans-serif;
            }
            input[type="text"]::placeholder, input[type="date"]::placeholder{
                font-size: 20px;
            }
            button{
                margin-top: 30px!important;
                box-shadow: 0 5px 0 0 #148322;
            }
        }
    }
    a.reglement{
        right: 0;
        top: 0;
    }
    .result_div{
        &>div{
            padding-top: 10px!important;
        }
    }
    img.lot{
        display: none;
    }
    .lot_text{
        font-weight: bold;
    }
    div.regles{
        .list_regles{
            .regle{
                @media screen and (max-width: 820px) {
                    &:first-child{
                        .description{
                            padding-left: 52px;
                        }
                    }
                }   
                @media screen and (max-width: 600px) {
                    &:first-child{
                        .description{
                            padding-left: 52px;
                        }
                    }
                    img.img_center{
                        max-height: 80px;
                        width: auto !important;
                    }
                }   
                .description{
                    strong{
                        font-weight: bold;
                        font-style: italic;
                        font-size: 25px;
                        line-height: 22px;
                        font-family: 'Cera_Black_Italic';
                    }
                }
            }
        }
    }
    .gallery{
        .list_gallery{
            padding: 0;
        }
    }
    .button, .actionButton{
        font-weight: bold !important;
    }
    .title-margin-left{
        margin-right: 20px;
        font-family: 'Cera_Black_Italic';
        line-height: 39px;
        @media screen and (max-width: 600px) {
            font-size: 35px;
            line-height: 32px;
        }
    }
    .title-margin-right{
        margin-left: 100px;
        font-family: 'Cera_Black_Italic';
        line-height: 39px;
        position: relative;
        top: -5px;
        @media screen and (max-width: 600px) {
            font-size: 35px;
        }
        @media screen and (max-width: 400px) {
            font-size: 30px;
        }
    }
    div.results{
        section.win{
            background-position: top !important;
            padding: 180px 0 1px;
            .result_div{
                // padding-top: 100px;
                > div > p:first-of-type{
                    font-family: 'Cera_Black_Italic', sans-serif;
                    @media screen and (max-width: 900px) {
                        font-size: 72px !important;
                    } 
                    @media screen and (max-width: 700px) {
                        font-size: 52px !important;
                    } 
                    @media screen and (max-width: 500px) {
                        font-size: 38px !important;
                    } 
                }
                > div > p{
                    @media screen and (max-width: 600px) {
                        font-size: 20px !important;
                    } 
                }
            } 
            h1.lot_text{
                font-weight: bold !important;
                font-family: 'Cera_Black_Italic' !important;
                font-size: 80px !important;
                margin-top: 20px !important;
                @media screen and (max-width: 800px) {
                    font-size: 40px !important;
                }
                @media screen and (max-width: 600px) {
                    font-size: 30px !important;
                }
            }
        }
    }
    .not_started, .alreadyplayed{
        .game{
            height: 550px;
            padding-top: 100px;
            .popup{
                background-color: unset;
                display: flex;
                justify-content: center;
                align-items: center;
                .popup_content{
                    position: relative;
                    top: 0;
                    margin: 0;
                    transform: none;
                    max-width: 600px;
                    width: 100%;
                    @media screen and (max-width: 500px) {
                        height: auto;
                        padding: 10px;
                    }
                    h1{
                        font-family: 'Cera_Black_Italic';
                        @media screen and (max-width: 500px) {
                            font-size: 32px !important;
                        }
                    }
                    .as_button{
                        display: none;
                    }
                    .inline_div{
                        .image_left{
                            display: none;
                        }
                    }
                }
            }
        }
        
    }
}

#builder-template-scope-game.es_lego_90_challenge_la_roue_legor{
    div.regles{
        .list_regles{
            .regle{
                .description{
                    strong{
                        font-size: 20px;
                        line-height: 22px;
                    }
                }
            }
        }
    }
    div.results section.win{
        padding-top: 100px;
    }
}

#builder-template-scope-game.lego_90ans{
    max-width: 1180px;
    margin: auto;
    section.first{
        padding: 20px 0!important;
        h1{
            text-shadow: 0px 0px 9px #001C2952;
        }
        h2{
            margin: 24px auto!important;
        }
        input{
            border-radius: 0!important;
            padding-top: 8px!important;
            padding-bottom: 8px!important;
            box-shadow: 0px 3px 6px #001C2933;
        }
        button{
            box-shadow: 0 6px 0 0 #148322;
        }
        @media screen and (max-width: 920px) {
            padding: 40px 0!important;
            h1{
                font-size: 24px!important;
            }
        }
        @media screen and (max-width: 660px) {
            h1{
                font-size: 20px!important;
            }
            h1 p, h2 p{
                display: inline;
            }
        }
    }
    .amazing-bloc{
        .title{
            font-weight: bold;
            text-shadow: 0px 0px 9px #001C2952;
        }
        div{
            &:nth-of-type(2){
                .actionButton{
                    box-shadow: 0 6px 0 0 #1D6B9B;
                }
            }
            &:nth-of-type(4){
                .actionButton{
                    box-shadow: 0 6px 0 0 #148322;
                }
            }
        }
        .regles{
            img{
                @media screen and (max-width: 820px) {
                    max-width: 35%;
                }
                max-width: 40%;
            }
        }
    }
    div.popup .popup_content{
        width: 600px;
        max-width: 95%;
        padding: 10px;
        border-radius: 20px;
        button.button{
            margin: 10px auto;
            box-shadow: 0 6px 0 0 #1D6B9B;
        }
    }
} 