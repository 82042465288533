.offer-display {
    .phase {
        width: 100%;
        
        .phase-name {
            background-color: #333;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 5px 10px;
            color: #fff;
            margin-top: 5px;
            margin-bottom: 5px;
        }

        .phase-headers {
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .phase-task {
            font-size: 12px;
            margin-bottom: 10px;
        }

        .phase-subtask {
            font-style: italic;
            font-size: 12px;

            .subtask-name {
                margin-left: 15px;
            }
        }

        .total-text {
            font-weight: bold;
        }
    }

    .totals {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
        margin-bottom: 15px;

        .totals-container {
            width: 300px;

            .total-row {
                border: solid 1px #333;
                border-bottom-width: 0;
                display: flex;

                &:last-child {
                    border-bottom-width: 1px;
                }

                .total-item {
                    height: 30px;
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:nth-child(2) {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    hr {
        margin-bottom: 25px;
        margin-top: 20px;
    }

    .modele-image {
        width: 100%;
    }

    .header-item {
        overflow: hidden;
    }

    .offer-title {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .offer-date {
        font-size: 13px;
    }

    .offer-po {
        display: flex;
        justify-content: flex-end;
    }

    .offer-description {
        font-size: 11px;
    }

    .offer-blue {
        font-size: 13px;
        color: #0273A5;
    }

    .offer-project {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .offer-address {
            text-align: right;
            padding-right: 10px;
        }

        img {
            width: 80px;
        }
    }
}