div.login-game{
    section{
        &.first{
            position: relative;
            height: 700px;
            padding: 25px 0;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            >*{
                position: relative;
            }    
        }
        form{
            display: block;
            width: 650px;
            max-width: calc(100% - 100px);
            margin: auto;
            padding: 50px 50px 20px;
            background-color: rgba(0,0,0,0.5);
            button.button{
                cursor: pointer;
                margin-top: 20px;
            }
        }
    }
}

@media screen and (max-width: 1000px){
    div.login-game{
        section{
            &.first{
                height: 600px;
                background-position: center;
                form{
                    max-width: calc(100% - 50px);
                    padding: 50px 25px 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 525px){
    div.login-game{
        section{
            &.first{
                height: 540px;
                background-position: center;
                form{
                    max-width: calc(100% - 30px);
                    padding: 30px 15px 15px;
                }
            }
        }
    }
}

@media screen and (max-width: 320px){
    div.login-game section.first{
        height: 570px;
    }
}