#builder-template-scope-game.lego_mario_2022{
    max-width: 1180px;
    .first{
        padding-bottom: 30px!important;
        h1{
            em{
                font-style: normal;
                color: #E6280F;
            }
            @media screen and (max-width: 767px) {
                font-size: 20px!important;
            }
        }
        h2{
            font-size: 14px!important;
            @media screen and (max-width: 767px) {
                p{
                    display: inline;
                }
            }
        }
        form{
            margin-bottom: 120px;
            @media screen and (max-width: 767px) {
                margin-bottom: 20px;
            }
            .inputZone input{
                border: 1px solid #0CA0DC!important;
                border-radius: 0!important;
                padding: 6px 20px;
            }
            button{
                box-shadow: 0px 6px 0 0 #1F8310;
                margin: 50px auto;
            }
        }
        .copyrightText{
            text-align: center;
            @media screen and (max-width: 800px) {
                font-size: 9px!important;
                max-width: 150px;
            }
        }
        @media screen and (max-width: 500px) {
            padding: 25px 0 30px!important;
            .logo{
                margin-bottom: 0px!important;
            }
            form{
                button{
                    margin: 10px auto;
                }
            }
        }
    }
    a.home_reglement{
        @media screen and (max-width: 780px){
            top: 0!important;
            right: 0!important;
        }
    }
    .how-participate{
        margin-top: -20px;
        section.container-content > div{
            display: flex;
            &.socials{
                display: block;
                @media screen and (max-width: 400px) {
                    .list_socials{
                        .social{
                            &:first-child{
                                margin-left: -10%;
                            }
                            &:last-child{
                                margin-left: 7%;
                            }
                        }
                    }
                    
                }
            }
        }
    }
    .amazing-bloc{
        p.title{
            font-weight: bold;
            @media screen and (max-width: 767px) {
                font-size: 24px!important;
            }
            @media screen and (max-width: 450px) {
                font-size: 20px!important;
            }
        }
        p{
            @media screen and (max-width: 767px) {
                font-size: 18px!important;
            }
            @media screen and (max-width: 450px) {
                font-size: 16px!important;
            }
        }
        .social.complete{
            img{
                max-height: 100%;
                width: auto;
                height: 150px;
                @media screen and (max-width: 850px) {
                    height: auto;
                }
            }
        }
        &.felicitations{
            background-position: center bottom!important;
            section.container-content{
                @media screen and (max-width: 767px) {
                    div:nth-last-child(3) p{
                        padding-bottom: 0 !important;
                    }
                    div:last-child p{
                        font-size: 12px!important;
                    }
                    p{
                        font-size: 16px!important;
                    }
                    div:nth-last-child(2) p{
                        font-size: 14px!important;
                    }
                }
                @media screen and (max-width: 450px) {
                    div:nth-last-child(2) p{
                        margin-bottom: 40px!important;
                        max-width: 270px!important;
                        font-size: 14px!important;
                    }
                    div:nth-last-child(2) p{
                        margin-bottom: 40px!important;
                        max-width: 270px!important;
                        font-size: 14px!important;
                    }
                    p{
                        font-size: 14px!important;
                    }
                }
                p.title{
                    font-weight: bold;
                    @media screen and (max-width: 767px) {
                        font-size: 30px!important;
                    }
                    @media screen and (max-width: 450px) {
                        font-size: 24px!important;
                    }
                }
                .actionButton{
                    box-shadow: 0px 6px 0 0 #1F8310;
                    @media screen and (max-width: 767px) {
                        font-size: 16px!important;
                    }
                    @media screen and (max-width: 450px) {
                        font-size: 14px!important;
                        padding: 6px 16px;
                    }
                }
                div:last-child{
                    max-width: 270px;
                    margin: auto;
                    @media screen and (max-width: 800px) {
                        max-width: 150px;
                        p{
                            font-size: 9px!important;
                        }
                    }
                }
            }
        }
        &.alreadyplayed{
            background-position: center bottom!important;
            .actionButton{
                box-shadow: 0px 6px 0 0 #1F8310;
            }
            @media screen and (max-width: 767px) {
                padding-top: 10px!important;
                .container-content{
                    padding-top: 0px!important;
                    padding-bottom: 170px!important;
                }
            }
        }
    }
    div.game_game#game_quiz{
        .div_game.nb-step-5{
            .container_response  > div:nth-child(1), .container_response  > div:nth-child(2){
                margin-bottom: 18px;
            }
        }
        .overlayPopupStart > div button{
            box-shadow: 0px 6px 0 0 #1F8310;
        }
    } 
    button, .actionButton{
        font-weight: bold!important;
    }
    .results{
        display: none;
    }
}