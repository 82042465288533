/* COMMON */
#builder-template-scope-game.estac_x_coca_cola_jeu_concours{
    font-family: 'Avenir Next Condensed', sans-serif;
    header{
        .banner{
            p{
                @media screen and (max-width: 600px) {
                    font-size: 13px !important;
                }
                @media screen and (max-width: 320px) {
                    font-size: 11px !important;
                }
            }
        }
    }
    .reglement{
        color: white !important;
        text-transform: uppercase;
        text-shadow: none !important;
        font-weight: bold;
        top: 24px !important;
    }
    .typeSection{
        background-size: cover !important;
        section.first{
            padding-top: 56px !important;
            padding-bottom: 88px !important;
            img.logo{
                width: 100% !important;
                max-height: none !important;
                max-width: 776px !important;
            }
            #form_login{
                width: 100%;
                display: flex;
                .container-form{
                    width: 100%;
                    max-width: 704px;
                    background-color: white;
                    border-radius: 8px;
                    padding: 32px;
                    margin: auto;
                    @media screen and (max-width: 820px){
                        width: 90%;
                        padding: 32px 24px;
                    }
                    > label{
                        font-size: 20px !important;
                        line-height: 22px !important;
                        strong{
                            font-size: 29px !important;
                        }
                        @media screen and (max-width: 768px){
                            font-size: 16px !important;
                            line-height: 20px !important;
                            strong{
                                font-size: 25px !important;
                            }
                        }
                        @media screen and (max-width: 500px){
                            font-size: 13px !important;
                            line-height: 20px !important;
                            strong{
                                font-size: 18px !important;
                            }
                        }
                    }
                }
                .inputZone{
                    margin-bottom: 0;
                    input#inputEmail{
                        margin-top: 12px;
                        width: 100%;
                        background: #FCFCFC 0% 0% no-repeat padding-box;
                        border-color: #DEE0E3 !important;
                        border-width: 1px !important;
                        padding-left: 32px;
                        min-height: 52px;
                        font-size: 15px !important;
                        line-height: 1 !important;
                        &::placeholder {
                            font-size: 15px;
                            color: #D5D5D5;
                            line-height: 1 !important;
                        }
                    }
                }
                .consent{
                    justify-content: space-between;
                    flex-wrap: wrap;
                    > div{
                        margin-top: 8px;
                        input, label{
                            cursor: pointer;
                        }
                    }
                }
                button.button{
                    width: 100%;
                    font-weight: bold !important;
                    margin-top: 30px;
                }
            }
        }
    }
    section.how-container{
        @media screen and (max-width: 820px) {
            background-position: center 150px !important;
        }
        margin-top: -1px;
        section.how{
            .regles{
                padding-top: 0;
                width: 100%;
                max-width: 840px;
                > div:first-child {
                    .img_center{
                        max-width: 400px;
                        width: 100%;
                        @media screen and (max-width: 820px){
                            width: 90%;
                        }
                        @media screen and (max-width: 600px){
                            width: 90%;
                            max-width: 300px;
                        }
                    }
                }
                .list_regles{
                    padding-top: 20px;
                    .regle{
                        .img_center{
                            max-width: 200px;
                        }
                        .description{
                            p{
                                font-size: 20px;
                                strong{
                                    font-size: 25px;
                                    @media screen and (max-width: 450px){
                                        font-size: 20px;
                                    }
                                }
                                @media screen and (max-width: 450px){
                                    font-size: 14px !important;
                                }
                            }
                            @media screen and (max-width: 820px) {
                                max-width: 180px;
                            }
                            @media screen and (max-width: 450px) {
                                max-width: 160px;
                            }
                        }
                    }
                    @media screen and (max-width: 820px){
                        padding-top: 40px;
                        .regle{
                            .description{
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
            .dotations{
                padding-top: 160px;
                padding-bottom: 0;
                @media screen and (max-width: 600px) {
                    padding-top: 0px;
                }
                h2{
                    display: none;
                }
                .list.unique{
                    max-width: 1024px;
                    padding-bottom: 0;
                    .dotation{
                        margin-right: 0;
                        margin-left: 0;
                    }
                    .img_center{
                        max-width: 100%;
                    }
                }
            }
        }
    }
    section.amazing-bloc{
        padding: 0 !important;
        .container-content{
            p{
                margin: 0 !important;
                max-width: none !important;
                padding-top: 8px !important;
                padding-bottom: 8px !important;
                font-size: 12px !important;
                @media screen and (max-width: 450px){
                    font-size: 10px;
                }
            }
        }
    }
    footer{
        > div.footer{
            p{
                font-size: 15px;
            }
            a{
                color: #032C52;
            }
        }
    }
    div.results{
        section.win{
            background-size: cover !important;
            @media screen and (max-width: 600px) {
                padding: 230px 0 20px;
            }
            .welcome-area.result_div{
                > div{
                    padding: 60px 10px 40px !important;
                    padding-top: 80px !important;
                    text-align: center;
                    img{
                        position: relative;
                        left: auto;
                        bottom: auto;
                        max-width: 760px;
                        top: -190px;
                        transform: none;
                        max-height: none;
                        @media screen and (max-width: 800px) {
                            position: absolute;
                            max-width: 340px !important;
                            left: 50%;
                            top: -150px;
                            transform: translate(-50%);
                        }
                    }
                }
            }
            .block_social{
                @media screen and (max-width: 800px) {
                    top: 20px;
                }
                @media screen and (max-width: 600px) {
                    top: 0px;
                }
            }
        }
    }
    .alreadyplayed{
        position: relative;
        section.game{
            padding-top: 130px;
            @media screen and (max-width: 800px) {
                padding-top: 100px;
                height: 500px !important;
            }
            @media screen and (max-width: 600px) {
                padding-top: 80px;
                height: 370px !important;
            }
            img.logo{
                max-width: 775px;
                width: 100%;
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                @media screen and (max-width: 1100px) {
                    max-width: 550px;
                }
                @media screen and (max-width: 600px) {
                    max-width: 420px;
                    width: 90%;
                }
            }
            .container-elements{
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                .block_social{
                    margin-bottom: 40px;
                    @media screen and (max-width: 600px) {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    .not_started, .ended_game{
        section.game{
            padding-top: 0;
            background-size: cover;
            div.popup{
                .overlay{
                    display: none;
                }
                .popup_content{
                    border-radius: 8px;
                    padding: 32px !important;
                    hr{
                        display: none;
                    }
                    .inline_div{
                        display: none;
                    }
                    .as_button{
                        display: none;
                    }
                }
            }
        }
    }
    .block_social{
        flex-direction: row-reverse;
        .social{
            padding: 4px !important;
            min-width: 26px !important;
            margin: 0 !important;
            img{
                height: 26px !important;
            }
            p{
                line-height: 12px !important;
                height: auto;
                display: block;
                font-size: 13px !important;
                strong{
                    font-size: 16px !important;
                }
            }
        }
    }
    div.game_game{
        section.game{
            .popup{
                .popup_content{
                    top: 40%;
                    padding: 0;
                    .button{
                        transform: rotate(-7deg);
                        &:hover{
                            background-color: #032C52 !important;
                        }
                        @media screen and (max-width: 600px) {
                            font-size: 24px !important;
                        }
                    }
                }
            }
        }
    }
}