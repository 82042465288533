.container-text-image{
    margin-top: 64px;
    width: 100%;
    padding: 20px;
    background: #f0f7fa;
    display: flex;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
    .container-text{
        width: 40%;
        display: inline-block;
        padding: 0 48px;
        margin-top: 16px;
        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 0 24px;
        }
        @media screen and (max-width: 500px) {
            padding: 0;
        }
        .image{
            text-align: center;
            img{
                max-width: 100%;
            }
        }
        .text{
            margin-top: 16px;
            p{
                padding-bottom: 16px;
                @media screen and (max-width: 500px){
                    font-size: 14px;
                }
            }
        }
    }
    .container-image{
        width: 60%;
        display: inline-block;
        text-align: center;
        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 0 24px;
        }
        @media screen and (max-width: 500px) {
            padding: 0;
        }
        img{
            max-width: 100%;
        }
    }
}
.simple{
    display: flex;
    flex-direction: column;
    .content-block{
        display: flex;
        @media screen and (max-width: 768px){
            flex-direction: column-reverse;
        }
        .left-block, .right-block{
            width: calc(50%);
            display: inline-block;
            // height: 100%;
            vertical-align: top;
            @media screen and (max-width: 768px){
                width: 100%;
                display: block;
            }
        }
        .left-block{
            position: relative;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            .main{
                .content-text{
                    margin-top: 48px;
                }
            }
            .list-image{
                display: flex;
                padding-bottom: 40px;
                justify-content: space-between;
                max-width: 350px;
                @media screen and (max-width: 500px){
                    max-width: 100%;
                }
                .image-item{
                    img{
                        width: 100%;
                    }
                }
            }
        }
        .right-block{
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 768px){
                margin-top: 16px;
            }
            img{
                max-width: 100%;
            }
        }
    }
}