#builder-template-scope-book{
    .result_screen{
        max-width: 100%;
        margin: auto;
        background: url('./image/bg-end.png');
        background-size: 100%;
        background-position: top center;
        background-repeat: no-repeat;
        padding: 0px 0px 68px;
        position: relative;
        overflow: hidden;
        @media screen and (max-width: 1280px){
            background-size: 1280px;
        }
        @media screen and (max-width: 768px) {
            background: url('./image/bg-end-mobile.png');
            background-size: contain;
            background-position: top center;
            background-repeat: no-repeat;
            padding-bottom: 28px;
        }
        .wrapper_card{
            padding: 0 18px;
            position: relative;
            &.es-version{
                h1{
                    @media screen and (max-width: 1280px){
                        font-size: 55px;
                        margin-bottom: 22px;
                    }
                    @media screen and (max-width: 550px){
                        font-size: 46px;
                        margin-bottom: 12px;
                    }
                    @media screen and (max-width: 400px){
                        font-size: 35px;
                        margin-bottom: 12px;
                    }
                }
                .card{
                    @media screen and (max-width: 1280px){
                        margin-left: 27vw;
                    }
                    @media screen and (max-width: 991px){
                        margin-left: 19vw;
                    }
                    @media screen and (max-width: 850px){
                        margin-left: 17vw;
                    }
                    @media screen and (max-width: 768px){
                        padding-top: 30vw;
                        padding-bottom: 60px;
                        margin-left: 6vw;
                    }
                    @media screen and (max-width: 550px) {
                        padding-top: 20vw;
                        margin-left: 0;
                    }
                }
                &>img{
                    right: 16vw;
                    @media screen and (max-width: 1280px){
                        right: 16vw;
                    }
                    @media screen and (max-width: 1250px) {
                        right: 10vw;
                    }
                    @media screen and (max-width: 991px) {
                        right: 5vw;
                    }
                    @media screen and (max-width: 768px) {
                        width: 40vw;
                        right: 2vw;
                        display: none;
                    }
                    @media screen and (max-width: 500px) {
                        right: 0;
                    }
                    &.only_mobile{
                        display: none;
                        @media screen and (max-width: 768px) {
                            display: block;
                        }
                        @media screen and (max-width: 550px) {
                            top: -7vw;
                        }
                    }
                    &.banner_left_corner{
                        position: absolute;
                        left: 0;
                        right: 0;
                        @media screen and (max-width: 550px) {
                            top: 0;
                        }
                    }
                }
                
            }
            .card{
                max-width: 608px;
                margin-left: 30vw;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                padding-bottom: 6vw;
                padding-top: 5vw;
                @media screen and (max-width: 991px){
                    margin-left: 25vw;
                }
                @media screen and (max-width: 768px){
                    padding-top: 30vw;
                    padding-bottom: 60px;
                    margin-left: 10vw;
                }
                @media screen and (max-width: 550px) {
                    padding-top: 20vw;
                    margin-left: 0;
                }
            }
            h1{
                color: white;
                font-size: 4.3vw;
                line-height: 1.2;
                font-weight: bold;
                font-style: italic;
                margin-bottom: 2.5vw;
                margin-top: 0;
                font-family: 'Cera_Black_Italic';
                @media screen and (max-width: 1280px){
                    font-size: 70px;
                    margin-bottom: 22px;
                }
                @media screen and (max-width: 550px){
                    font-size: 46px;
                    margin-bottom: 12px;
                }
            }
            p{
                font-size: 1.4vw;
                line-height: 1.4;
                max-width: 100%;
                color: white;
                font-family: 'Cera_Normal';
                margin: 0;
                br {
                    display: none;
                }
                @media screen and (max-width: 1280px){
                    font-size: 18px;
                    line-height: 1.3;
                    max-width: 320px;
                }
                @media screen and (max-width: 500px){
                    font-size: 16px;
                }
                span{
                    color: white;
                    font-family: 'Cera_Bold';
                }
                &.second_text{
                    margin-top: 24px;
                    @media screen and (max-width: 500px){
                        margin-top: 14px;
                    }
                }
            }
            &>img{
                position: absolute;
                top: 0;
                right: 20vw;
                width: 25vw;
                @media screen and (max-width: 1250px) {
                    right: 10vw;
                }
                @media screen and (max-width: 991px) {
                    right: 5vw;
                }
                @media screen and (max-width: 768px) {
                    width: 40vw;
                }
                @media screen and (max-width: 500px) {
                    right: 0;
                }
            }
        }
        .subBlock{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            &.es-version{
                h2{
                    @media screen and (max-width: 500px) {
                        font-size: 20px;
                    }
                }
            }
            h2{
                transform: matrix(1, -0.09, 0.09, 1, 0, 0);
                text-align: center;
                font-size: 38px;
                line-height: 1.3;
                font-family: 'Cera_Black_Italic';
                @media screen and (max-width: 767px) {
                    font-size: 22px;
                }
            }
            & > p{
                font-family: 'Cera_Bold';
                text-align: center;
                font-size: 20px;
                line-height: 1.2;
                max-width: 90%;
                margin: 16px auto;
                @media screen and (max-width: 767px) {
                    font-size: 16px;
                    br{
                        display: none;
                    }
                }
                p{
                    margin: 0;
                }
            }
            &>div{
                display: flex;
                flex-direction: row;
                &.titleContainer{
                    margin-top: 100px;
                    position: relative;
                    &>img{
                        position: absolute;
                        &:first-child{
                            left: -25px;
                            top: 0;
                        }
                        &:last-child{
                            right: 0;
                            bottom: 20px;
                        }
                        @media screen and (max-width: 900px) {
                            width: 25px;
                        }
                        @media screen and (max-width: 767px) {
                            width: 22px;
                        }
                        @media screen and (max-width: 500px) {
                            width: 20px;
                        }
                    }
                    @media screen and (max-width: 400px) {
                        margin-top: 30px;
                    }
                }
                @media screen and (max-width: 767px) {
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
        .container_button{
            position: relative;
            display: inline-block;
            z-index: 1;
            margin: 25px 8px;
            div.button_lego{
                font-size: 16px;
                line-height: 1.2;
                padding: 12px 32px;
                box-shadow: 0px 3px 0px #33333380;
                border-radius: 4px;
                color: white;
                border: none;
                font-family: 'Cera_Bold';
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;
                &:hover{
                    filter: brightness(110%);
                }
                @media screen and (max-width: 600px){
                    font-size: 14px;
                    line-height: 18px;
                    padding: 13px 23px;
                }
                &.facebook{
                    background: #0895EC;
                    box-shadow: 0px 3px 0px #096096;
                    img{
                        width: 12px;
                        margin-right: 16px;
                    }
                }
                &.mail{
                    background: #D70008;
                    box-shadow: 0px 3px 0px #8D090E;
                    img{
                        width: 24px;
                        margin-right: 16px;
                    }
                }
            }
            @media screen and (max-width: 767px){
                margin: 8px;
                width: 100%;
                button{
                    width: 100%;
                    span{
                        display: block;
                        flex: 1;
                    }
                }
            }
        }
    }
}