html{
    background: #E5E8EF;
}
body{
    margin: auto;
    background: white;
    max-width: 100%;
    position: relative;
    &.no-slide{
        overflow: hidden;
    }
}
@media screen and (max-width: 1500px){
    section, div.typeSection{
        background-size: cover!important;
    }
}
section{
    width: 100%;
    position: relative;
    &.overflow_hidden{
        overflow: hidden;
    }
}

h1{
    display: block;
    width: 100%;
    text-align: center;
    margin: auto;
    font-size: 42px;
    line-height: 49px;
    color: white;
    margin: 10px 0;
    @media screen and (max-width: 480px){
        font-size: 32px;
        line-height: 40px;
    }
    p{
        color: inherit!important;
        font-size: inherit!important;
        line-height: inherit!important;
    }
}

h2{
    text-align: center;
    font-size: 38px;
    font-weight: inherit;
    line-height: 56px;
    margin: 10px auto;
    width: auto;
    max-width: 100%;
    max-width: max-content;
    padding: 0 25px;
    @media screen and (max-width: 450px){
        font-size: 26px!important;
    }
    p{
        color: inherit!important;
        font-size: inherit!important;
        line-height: inherit!important;
    }
}

h3{
    color: white;
    p{
        color: inherit;
        font-size: inherit;
        line-height: inherit;
    }
}

p{
    margin: 0;
    font-size: 21px;
    line-height: 1.2;
}

.center{
    text-align: center;
}

.uppercase{
    text-transform: uppercase;
}
.white{
    color: white;
}

img{
    image-rendering: -moz-crisp-edges;         /* Firefox */
    image-rendering:   -o-crisp-edges;         /* Opera */
    image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
    &.img_center{
        display: block;
        margin: auto;
        max-width: 90%;
    }
    &.img_absolute{
        position: absolute;
        z-index: 99;
    }
    &.logo{
        width: auto;
        max-width: 100%;
    }
    &.imageAbsolute{
        position: absolute;
        max-width: 100%;
    }
    &.imageRelative{
        position: relative;
    }
}

hr{
    border: 1px solid white;
    &.center{
        margin: auto;
    }
}

section.loader{
    height: 82vh;
    width: 100%;
    .logo_ubisoft{
        animation: loading 1.75s ease infinite;
        height: 100px;
        left: 50%;
        margin: -50px 0 0 -50px;
        opacity: 0;
        position: absolute;
        text-indent: -9999px;
        top: 50%;
        width: 100px;
    }
}

div.custom_div{
    &>*{
        font-size: inherit;
    }
}

@keyframes loading {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
    60% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(0.8);
        opacity: 0;
    }
}

*{
    &.betweenLine{
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        &:before{
            content: '';
            height: 1px;
            width: auto;
            display: flex;
            background-color: var(--tooltip-color, '#000000');
            margin-right: 10px;
            flex-grow: 1;
        }
        &:after{
            content: '';
            height: 1px;
            width: auto;
            display: flex;
            background-color: var(--tooltip-color, '#000000');
            margin-left: 10px;
            flex-grow: 1;
        }
    }
    &.underline{
        text-decoration: underline;
    }
}

// REGLEMENT MODAL
.text_reglement{
    display: none;
    position: fixed;
    z-index: 3000;
    width: calc(860px - 60px);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 30px;
    height: 80vh;
    overflow-x: auto;
    h2, h3, p{
        max-width: 100%;
    }
    h2{
        @media screen and (max-width: 768px) {
            font-size: 20px;
        }
    }
    p{
        text-align: justify;
        margin: 16px 0;
        font-size: 16px;
        line-height: 24px;
        margin: 0;
        @media screen and (max-width: 768px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
    ul{
        li{
            @media screen and (max-width: 768px) {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    @media screen and (max-width: 1000px) {
        width: calc(90% - 60px);
    }
    @media screen and (max-width: 768px) {
        padding: 20px;
        width: calc(90% - 40px);
    }
    .button_close{
        position: fixed;
        bottom: 1vw;
        left: 50%;
        transform: translate(-50%);
        .as_button{
            font-size: 26px;
            line-height: 1;
            text-transform: uppercase;
            padding: 6px 12px;
            background: #ac191d;
            box-shadow: 0px 3px 0px #33333380;
            border-radius: 50%;
            color: white;
            border: none;
            cursor: pointer;
        }
        @media screen and (max-width: 768px) {
            bottom: 3vw;
        }
    }
}
.overlay_reglement{
    display:none;
    width: 100vw;
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    height: 110vh;
    top: 0;
    z-index: 2900;
    left: 0;
}

.onlyDesktop{
    display: block;
    @media screen and (max-width: 1000px){
        display: none;
    }
}
.onlyTablet{
    display: block;
    @media screen and (max-width: 500px){
        display: none;
    }
    @media screen and (min-width: 1001px){
        display: none;
    }
}
.onlyMobile{
    display: block;
    @media screen and (min-width: 501px){
        display: none;
    }
}

.d-block{
    display: block;
}

.d-flex{
    display: flex;
}