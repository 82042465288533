#builder-template-scope-game.lego_city_friends {
  font-family: "Cera Pro Black", sans-serif;
  max-width: 1180px;
  header {
    height: 50px;
    .banner p {
      text-transform: none;
      font-weight: bold;
    }
  }
  img.logo {
    display: none !important;
  }
  div.typeSection {
    background-position: center top !important;
    a.reglement {
      font-weight: bold;
    }
    .first {
      padding: 190px 0 100px 0 !important;
      @media screen and (max-width: 720px) {
        padding: 100px 0 60px 0 !important;
      }
      > div {
        border-radius: 10px;
        margin: 0 10px;
        h1 {
          margin-bottom: 19px;
          @media screen and (max-width: 767px) {
            font-size: 20px !important;
          }
        }
        #form_login {
          .inputZone {
            input {
              border-width: 1px !important;
              border-radius: 0 !important;
              border-color: #000000 !important;
              width: 100%;
              max-width: none;
              text-align: center;
              &::placeholder {
                text-transform: none;
              }
            }
          }
          .boxCheckbox label,
          button {
            font-weight: bold !important;
          }
        }
      }
    }
  }
  section.how-participate {
    overflow: visible;
    @media screen and (max-width: 410px) {
      padding-bottom: 40px !important;
    }
    .container-content {
      transform: translateY(-30px);
      padding: 0 10px;
      .title {
        font-weight: bold !important;
        font-style: italic;
        @media screen and (max-width: 767px) {
          font-size: 20px !important;
          width: 90% !important;
        }
      }
      .regles .list_regles {
        .regle .description {
          justify-content: flex-start;
          strong {
            font-size: 14px !important;
          }
        }
        #rule-0 {
          img {
            @media screen and (max-width: 820px) {
              padding-left: 10px;
            }
          }
          .description {
            @media screen and (max-width: 820px) {
              padding-left: 37px;
            }
          }
        }
        #rule-1 {
          .description {
            @media screen and (min-width: 820px) {
              padding-top: 10px;
            }
          }
        }
        #rule-2 {
          img {
            @media screen and (max-width: 820px) {
              padding-left: 20px;
            }
          }
          .description {
            @media screen and (min-width: 820px) {
              padding-top: 20px;
            }
            @media screen and (max-width: 820px) {
              padding-left: 51px;
            }
          }
        }
      }
      .regles + div {
        p {
          position: absolute;
          bottom: 0;
          transform: translateY(150%);
        }
      }
    }
  }
  section.share_social_media {
    min-height: 250px;
    @media screen and (max-width: 800px) {
      min-height: 200px;
    }
    @media screen and (max-width: 600px) {
      min-height: 170px;
    }
    background-position: center bottom !important;
    background-size: 100% !important;
    p.title {
      font-weight: bold;
      font-style: italic;
      @media screen and (max-width: 800px) {
        margin: 36px auto 20px !important;
      }
      @media screen and (max-width: 800px) {
        font-size: 20px !important;
      }
      @media screen and (max-width: 600px) {
        font-size: 18px !important;
      }
    }
    &.with_buttons {
      div.socials div.list_socials {
        display: flex;
        justify-content: center;
        div.social {
          width: auto;
          img {
            width: 73px;
            @media screen and (max-width: 600px) {
              width: 43px;
            }
          }
          @media screen and (max-width: 600px) {
            min-width: auto;
          }
        }
      }
    }
  }
  section.block_lots {
    min-height: 500px;
    section.container-content {
      p.title {
        font-weight: bold;
        font-style: italic;
        @media screen and (max-width: 600px) {
          font-size: 20px !important;
        }
      }
    }
  }
  section.block_twice {
    section.container-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      max-width: none;
      margin: 25px auto;
      @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
      }
    }
  }
  section.felicitations .container-content {
    .title {
      @media screen and (max-width: 420px) {
        font-size: 30px !important;
      }
    }
    .d-block {
      max-width: 400px !important;
    }
  }
  #game_quiz {
    section {
      padding: 0 !important;
      background-size: contain !important;
      .overlayPopupStart {
        z-index: 99;
        > div {
          border-radius: 10px !important;
          button.as_button {
            font-weight: bold !important;
            font-family: "Cera Pro Black", sans-serif;
          }
        }
      }
      #top-game {
        margin: 0 10px;
        @media screen and (max-width: 550px) {
          min-height: 780px !important;
        }
        @media screen and (max-width: 450px) {
          min-height: 680px !important;
        }
        h2 {
          color: #000000 !important;
          font-size: 33px !important;
          max-width: 700px;
          margin-bottom: 30px;
          @media screen and (max-width: 900px) {
            max-width: 600px;
            font-size: 33px !important;
          }
          @media screen and (max-width: 767px) {
            font-size: 26px !important;
          }
        }
        .inline_answers {
          flex-wrap: nowrap !important;
          flex-direction: column;
          align-items: flex-start !important;
          padding: 0 !important;
          width: 100%;
          margin: 10px !important;
          background-color: white;
          > div {
            padding: 0 !important;
            overflow: hidden;
            display: flex;
            width: 100%;
            font-weight: bold;
            .active {
              background-color: #2c2c89;
              color: white;
            }
            > div {
              border-top: 0.5px solid #f5f5f5;
              background-color: white;
              width: calc(100% - 30px);
              padding: 0 !important;
              z-index: 3;
              display: flex;
              align-items: center;
              padding-left: 30px !important;
              @media screen and (max-width: 501px) {
                padding-left: 15px !important;
                width: calc(100% - 15px);
              }
              p {
                font-size: 20px !important;
                @media screen and (max-width: 501px) {
                  font-size: 16px !important;
                }
              }
            }
            &:nth-child(1) {
              > div {
                border-top: none;
              }
            }
            img {
              max-width: 27% !important;
              height: 114px;
              object-fit: cover;
              transition: transform 0.2s linear;
              z-index: 1;
              @media screen and (max-width: 450px) {
                height: 94px;
              }
              &:hover {
                filter: none !important;
              }
            }
            &:hover {
              > div {
                background-color: #efeffc;
                color: #000000;
              }
              .active {
                background-color: #2c2c89;
                color: white;
              }
              cursor: pointer;
              img {
                transform: scale(1.1);
              }
            }
          }
        }
        button.as_button {
          box-shadow: none !important;
          font-weight: bold !important;
          font-family: "Cera Pro Black", sans-serif !important;
        }
      }
    }
    div.div_result {
      margin: 50px 10px;
      max-width: 680px !important;
      div.contain {
        display: flex;
        flex-direction: column;
        padding: 30px 60px !important;
        h2 {
          order: 1;
        }
        > p {
          order: 3;
          font-weight: bold;
        }
        div.result {
          order: 2;
          padding-bottom: 50px;
        }
        button.as_button {
          order: 4;
          box-shadow: none !important;
        }
      }
    }
  }
}
