button.button, a.as_button{
    outline: none;
    width: max-content;
    cursor: pointer;
    text-decoration: none;
    margin: 10px auto;
    display: block;
    background-color: rgba(0,0,0,0.6);
    // font-family: 'Mark_Bold';
    font-size: 42px;
    line-height: 61px;
    color: white;
    border: 3px solid white;
    padding: 0px 40px;
    transition: all ease 200ms;
    width: auto;
    text-align: center;
    opacity: 1;
    @media screen and (max-width: 750px){
        font-size: 28px;
        line-height: 35px;
        padding: 0px 25px;
        border-width: 1px;
    }
    @media screen and (max-width: 500px){
        font-size: 22px;
        line-height: 28px;
        padding: 0px 20px;
        border-width: 1px;
    }
    &.little{
        font-size: 36px;
        line-height: 36px;
        padding: 10px 40px;
        @media screen and (max-width: 750px){
            font-size: 28px;
            line-height: 35px;
            padding: 10px 25px;
            border-width: 1px;
        }
        @media screen and (max-width: 500px){
            font-size: 22px;
            line-height: 28px;
            padding: 5px 20px;
            border-width: 1px;
        }
    }
    &:hover{
        opacity: 0.8;
    }
}
a.as_button{
    padding: 10px 40px; 
}
.actionButton{
    transition: all ease 200ms;
    opacity: 1;
    &:hover{
        opacity: 0.8;
    }
}