#builder-template-scope-book{
    footer{
        background: #333333;
        .banner{
            padding: 32px;
            max-width: 1230px;
            margin: auto;
            font-family: 'Cera_Normal';
            color: white;
            @media screen and (max-width: 768px){
                padding: 0;
            }
            span{
                font-weight: bold;
                cursor: pointer;
                &:hover{
                    text-decoration: underline;
                }
            }
            p{
                margin: 0;
                font-family: 'Cera_Normal';
                font-size: 14px;
                @media screen and (max-width: 768px){
                    padding: 17px 21px;
                    font-size: 12px;
                }
                @media screen and (max-width: 500px){
                    padding: 17px 21px;
                    padding-bottom: 110px;
                }
            }
        }
    }
}