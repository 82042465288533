.ql-size-small {
    font-size: 10px;
}

.ql-size-large {
    font-size: 20px;
}

.ql-size-huge {
    font-size: 30px;
}

.ql-video{
    width: 100%;
    aspect-ratio: 16/9;
}