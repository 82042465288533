.builder-template-scope .product-content {
    text-align: left !important;

    h3 {
        font-size: 16px;
        font-weight: bold;
        font-family: 'Cera Pro', sans-serif;
        margin-bottom: 10px !important;
    }

    .product-price span {
        font-size: 20px !important;
        font-weight: bold !important;
        font-family: 'Cera Pro', sans-serif;
        margin-left: 0 !important;
    }
}

