//HACKED
@font-face {
    // font-family: 'Hacked';
    src: "url('/static/fonts/HACKED.ttf') format('truetype')";
    font-weight: normal;
    font-style: normal;
    font-display:swap;
}

//LCD
@font-face {
    // font-family: 'LCD';
    src: "url('/static/fonts/LCD-BOLD.ttf') format('truetype')";
    font-weight: normal;
    font-style: normal;
    font-display:swap;
}

//MARK
@font-face {
    // font-family: 'Mark_Bold';
    src: "url('/static/fonts/Mark/MARK_BOLD.OTF') format('truetype')";
    font-weight: normal;
    font-style: normal;
    font-display:swap;
}


//NOTO
@font-face {
    // font-family: 'Noto';
    src: "url('/static/fonts/Noto/NotoSans-Regular.ttf') format('truetype')";
    font-weight: normal;
    font-style: normal;
    font-display:swap;
}
@font-face {
    // font-family: 'Noto_Black';
    src: "url('/static/fonts/Noto/NotoSans-Black.ttf') format('truetype')";
    font-weight: normal;
    font-style: normal;
    font-display:swap;
}
@font-face {
    // font-family: 'Noto_Bold';
    src: "url('/static/fonts/Noto/NotoSans-Bold.ttf') format('truetype')";
    font-weight: normal;
    font-style: normal;
    font-display:swap;
}
