.information-banner {
    padding-top: 10px;
    padding-bottom: 10px;

    .title {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 20px;
        line-height: 34px;
        overflow: hidden;
        margin-bottom: 0;

        button {
            display: flex;
            align-items: center;
            position: absolute;
            left: 0%;
            background-color: transparent;
            border: none;

            svg {
                width: 20px;
                height: 20px;
            }

            p {
                color: #fff;
                font-family: 'Cera Pro', sans-serif;
                font-size: 18px;
                letter-spacing: 1.5px;
                margin-left: 5px;
            }

            @media screen and (max-width: 460px){
                p {
                    display: none;
                }
            }
        }

        h1, h2 {
            font-family: 'Cera Pro', sans-serif;
            text-align: center;
            display: inline;
            letter-spacing: 2px;
            margin-bottom: 0;
        }

        h1 {
            font-weight: bold;
            margin-right: 2px;
        }

        h2 {
            font-weight: 300;
        }
    }
}