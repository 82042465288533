.offer-manager {
    width: calc(100% - 40px);
    margin: 20px;

    hr {
        margin: 20px 0;
        border-color: #fff;
    }

    .MuiAccordionSummary-root.Mui-focused {
        background-color: #333;
    }

    .MuiSelect-select:focus {
        border-radius: 4px;
    }

    .phase-name {
        color: #fff;
        font-weight: bold;
        font-size: 15px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .delete-phase {
            text-decoration: underline;
            color: #fff;
            font-size: 13px;
            font-weight: normal;
        }
    }

    .input {
        margin-top: 5px;
        margin-right: 10px;

        &.small-margin {
            margin-right: 5px;
        }
    }

    .add-something {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 4px;
        cursor: pointer;
        padding-left: 15px;
        padding-right: 10px;
        margin-top: 10px;
        font-weight: bold;
        width: calc(100% - 25px);

        &:hover {
            opacity: 0.7;
        }

        &.add-phase {
            border: dotted 1px #000;
            height: 56px;
            color: #000;
            font-size: 15px;
        }

        &.add-tache {
            border: dotted 1px #0273A5;
            height: 46px;
            color: #0273A5;
            font-size: 14px;
        }
    }

    .taches {
        display: flex;
        flex-direction: column;
        width: 100%;

        .tache {
            border-radius: 4px;
            background-color: #FAFAFA;
            width: 100%;
            padding: 15px;
            border: solid 1px #D4D9DC;
            margin-bottom: 10px;

            .tache-name {
                margin-bottom: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .tache-delete {
                    cursor: pointer;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }

            .tache-line {
                width: '100%'
            }

            .add-sub-task {
                margin: 15px 0 10px 0;
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;
                color: #0273A5;
                padding-left: 15px;

                &:hover {
                    opacity: 0.7;
                }
            }
        }

        .subtask {
            margin-top: 20px;
            padding-left: 30px;

            .subtask-name {
                margin-bottom: 10px;
                font-size: 13px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .tache-delete {
                    cursor: pointer;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }

    .total {
        width: 100%;
        height: 50px;
        background-color: #EAECEE;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #D4D9DC;
        border-radius: 4px;
        padding: 0 15px;
        margin-top: 10px;

        .amount {
            font-weight: bold;
            font-size: 18px;
        }
    }
}

.sortable-helper {
    z-index: 1000 !important;
}