.categories {
    padding-top: 20px;
    padding-bottom: 80px;
    width: 100%;
    
    .title {
        text-align: center;
        line-height: 34px;
        margin-bottom: 50px;

        h1 {
            font-family: 'Cera Pro', sans-serif;
            margin-top: 60px;
            font-size: 42px;
            font-weight: 600;
            margin-bottom: 0;
        }
    }

    .categories-container {
        margin: 10px auto;
        width: 80%;
        position: relative;

        .category {
            position: relative;
            height: 250px;
            width: 200px;
            transform-origin: 0 0;                    
            transition: transform .300s ease-out;
            overflow: hidden;

            &:hover p,
            &:hover img {
                transform: scale(1.1);
            }

            .category-content {
                height: 250px;
                width: 200px;
                position: relative;

                p {
                    margin: 6px;
                    font-family: 'Cera Pro', sans-serif;
                    position: absolute;
                    top: 30px;
                    margin-left: auto;
                    margin-right: auto;
                    left: 0;
                    right: 0;
                    text-align: center;
                    font-weight: bold;
                    font-size: 18px;
                    z-index: 10;
                    max-width: 150px;
                    color: #fff;
                    transition: transform .200s ease-out;
                }

                @media screen and (max-width: 768px) {
                    p {
                        left: -10px;
                    }
                }
    
                img {
                    object-fit: cover;
                    max-width: 100%;
                    height: 100%;
                }
            }
        }

        .pagination {
            display: flex;
            justify-content: center;
            width: 100%;
    
            .prev, .next {
                position: absolute;
                top: 50%;
                color: '#fff' ;
                display: inline-block;
                font-size: 24px;
                height: 35px;
                line-height: 31px;
                text-align: center;
                width: 35px;
                margin: 0 5px;
                
                &:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }
            }
    
            .prev {
                left: -3%;
            }
    
            .next {
                right: -3%;
            }
            
            @media screen and (max-width: 768px) {
                .prev {
                    left: -7%;
                }
        
                .next {
                    right: -7%;
                }
            }
        }
    }
}