#builder-template-scope-game.lego_fr_wdn_2022,
#builder-template-scope-game.lego_es_wdn_2022 {
    max-width: 1180px;
    margin: 0 auto;
    font-family: 'Cera_Normal';
    .typeSection{
        background-position: top !important;
        background-size: 100% !important;
    }
    button.button{
        line-height: 17px !important;
        margin-top: 45px;
        font-family: 'Cera_Black_Italic';
    }
    strong, b{
        font-family: 'Cera_Black_Italic';
    }
    i{
        font-family: 'Cera_Black_Italic';
    }
    div.game_game{
        section.game.wishlist{
            // padding: 24px;
            padding-top: 0px;
            // width: calc(100% - 48px);
            width: 100%;
            h1{
                font-family: 'Cera_Black_Italic';
                margin-bottom: 0;
            }
            button.button{
                max-width: 364px;
                width: 100%;
            }
            .wrapper-img{
                width: 100%;
                max-width: 1200px;
                margin: auto;
                picture{
                    display: flex;
                    img{
                        width: 100%;
                    }
                }
            }
            .wrapper-recap{ 
                p{
                    font-family: 'Cera_Black_Italic';
                }
                .recap{
                    width: 100%;
                    margin-top: 20px;
                    max-width: 100%;
                    flex-wrap: nowrap;
                    @media screen and (max-width: 550px){
                        flex-wrap: wrap;
                    }
                    div{
                        width: 96px;
                        height: 96px;
                        flex: none;
                        background-color: transparent;
                        border: 1px dashed #C9CBCC;
                        border-radius: 10px;
                        @media screen and (max-width: 550px){
                            width: 125px;
                            height: 125px;
                        }
                        @media screen and (max-width: 400px){
                            width: 95px;
                            height: 95px;
                        }
                        &.active{
                            border: 1px solid black;
                            .remove{
                                color: white;
                                background: #CF2E25;
                                border-radius: 50%;
                                text-align: center;
                                line-height: 1;
                                width: 17px;
                                height: 17px;
                                right: -8px;
                                top: -8px;
                            }
                        }
                    }
                }
            }
            .wrapper-wishlist{
                max-width: calc(1123px - (76px * 2));
                .wishlist-container{
                    flex: 1;
                    > div.item-product {
                        width: calc(100% / 4 - 20px);
                        flex-basis: auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        button{
                            border: 1px solid transparent;
                            background-color: #DC1F2B;
                            color: white;
                            font-family: 'Cera_Black_Italic';
                            border-radius: 0;
                            &:hover{
                                filter: brightness(1);
                                color: rgba(255,255,255,0.5) !important;
                            }
                        }
                        .products{
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            .container_image{
                                flex: 1;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-bottom: 16px;
                                position: relative;
                                img{
                                    max-height: 210px;
                                    max-width: 100%;
                                    width: auto;
                                }   
                            }
                            p{
                                color: black;
                            }
                        }
                        @media screen and (max-width: 850px){
                            width: calc(100% / 3 - 20px);
                        }
                        @media screen and (max-width: 600px){
                            width: calc(100% / 2 - 20px);
                        }
                        @media screen and (max-width: 500px){
                            width: calc(100% / 2 - 10px);
                        }
                    }
                }
            }
        }
    }
    .banner{
        height: 40px;
        font-family: 'Cera_Black_Italic';
    }
    .reglement{
        font-weight: bold;
        text-shadow: none;
        font-family: 'Cera_Bold';
    }
    .how-container{
        padding-top: 0;
        padding-bottom: 0;
        .how{
            .list_regles{
                img{
                    max-width: 78px;
                }
            }
            .dotations{
                padding-top: 16px;
                padding-bottom: 0;
            }
        }
    }
    .typeSection{
        .contentCenter{
            .first{
                padding-bottom: 0 !important;
                padding-top: 0px !important;
                min-height: 750px !important;
                justify-content: flex-end !important;
                @media screen and (max-width: 700px) {
                    min-height: 600px !important;
                }
                h1{
                    line-height: 49px !important;
                    font-family: 'Cera_Black_Italic';
                    u{
                        text-decoration: none;
                        font-size: 33px;
                        line-height: 29px;
                    }
                    @media screen and (max-width: 700px) {
                        font-size: 40px !important;
                        line-height: 38px !important;
                        u{
                            font-size: 25px;
                            line-height: 25px;
                        }
                    }
                    @media screen and (max-width: 500px) {
                        font-size: 25px !important;
                        line-height: 25px !important;
                        u{
                            font-size: 18px;
                            line-height: 18px;
                        }
                    }
                }
                h2{
                    @media screen and (max-width: 500px) {
                        padding: 0;
                        font-size: 14px !important;
                        line-height: 14px !important;
                    }
                }
                .inputZone{
                    margin-bottom: 6px;
                    #inputEmail{
                        margin-top: 33px;
                        text-align: center;
                        width: 100%;
                        max-width: 554px;
                        padding: 20px;
                        font-size: 16px !important;
                        line-height: 1 !important;
                        min-height: auto;
                        border-radius: 0 !important;
                    }
                }
            }
        }
    }
    .not_started {
        section.game {
            height: 1030px !important;
        }

        .inline_div {
            display: none !important;
        }

        h1 {
            display: none;
        }

        h3 {
            font-family: "Cera_Black_Italic" !important;
        }

        hr {
            display: none !important;
        }

        p {
            font-family: "Cera_Normal" !important;
        }

        .popup.play {
            background-color: inherit !important;
        }

        .popup_content {
            margin-top: 200px !important;

            a {
                margin-top: 48px !important;
                width: 240px !important;
                margin: 32px auto !important;
                font-family: "Cera_Black_Italic" !important;
            }
        }

        @media screen and (max-width: 500px) {
            .popup_content { 
                margin-top: 0 !important;

                h3 {
                    font-size: 32px !important;
                }
            }
        }
    }
    .custom-separator{
        position: relative;
        z-index: 10;
    }
    footer{
        padding-top: 50px;
        padding-bottom: 40px;
        margin-top: -50px;
    }
    .alreadyplayed.sony{
        .game{        
            background-size: 100% !important;    
            background-position: center top !important;
            height: 550px;
            @media screen and (min-width: 1300px) {
                height: 650px !important;
            }
            @media screen and (max-width: 1000px) {
                height: 900px !important;
            }
            @media screen and (max-width: 900px) {
                height: 800px !important;
            }
            @media screen and (max-width: 800px) {
                height: 700px !important;
            }
            @media screen and (max-width: 700px) {
                height: 620px !important;
            }
            @media screen and (max-width: 600px) {
                height: 520px !important;
            }
            @media screen and (max-width: 400px) {
                height: 420px !important;
            }
            @media screen and (max-width: 350px) {
                height: 350px !important;
            }
            .popup{
                display: none ;
            }
        }
    }
    .recap-wishlist.results{
        .win{            
            padding: 0;
            padding-bottom: 30px;
            h1{
                margin: 0 !important;                
            }
            .title-recap{
                padding: 0 !important;
                @media screen and (max-width:450px) {
                    max-width: 200px;
                }
            }
            .recap{  
                position: relative;   
                background-color: white;        
                width: 90%;
                border-radius: 10px;
                margin: auto;
                display: flex;
                flex-direction: column;
                align-items: center;   
                padding: 40px 0;    
                img.absolute{
                    position: absolute;
                    z-index: 1;
                    &.top-left{
                        top: 0;
                        left: 0;
                        max-width: 260px;
                        @media screen and (max-width: 768px){
                            max-width: 200px;
                        }
                        @media screen and (max-width: 600px){
                            max-width: 120px;
                        }
                    }
                    &.bottom-right{
                        bottom: 0;
                        right: 0;
                        max-width: 360px;
                        @media screen and (max-width: 768px){
                            max-width: 260px;
                        }
                        @media screen and (max-width: 600px){
                            max-width: 150px;
                        }
                    }
                }         
                .button{
                    display: none;
                }
                .products{                    
                    display: flex;    
                    flex-wrap: wrap;
                    width: 50%;   
                    @media screen and (max-width:1000px) {            
                        width: 80%;
                    }
                    @media screen and (max-width:700px) {            
                        width: 90%;
                    }                                    
                    >div{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;      
                        width: 50%;                  
                        @media screen and (max-width: 600px) {
                            width: 100%;                  
                        }                        
                    }
                    img{
                        max-width: 100%;
                        height: 130px;
                        object-fit: contain;
                        margin-bottom: 8px;
                    }
                    p{
                        text-align: center;
                        font-size: 14px;
                        line-height: 14px;
                        font-family: "Cera_Black_Italic" !important;
                        max-width: 250px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                                line-clamp: 2;
                        -webkit-box-orient: vertical;   
                        height: 28px;     
                        margin-bottom: 4px;                     
                        &.ref{
                            font-family: "Cera_Normal" !important;
                            max-width: none;
                        }
                    }
                }
            }
            .containerImage{
                .imageAbsolute{
                    position: initial;
                    width: 100%;      
                    display: block;                            
                }
            }
        }
    }
    .background-hack{
        background-position: center bottom !important;
    }
    .share_socials{        
        background-size: 100% !important;              
        padding: 0;      
        display: flex;
        align-items: flex-end;
        min-height: 350px;
        @media screen and (max-width:1400px) {
            min-height: 270px;
        }
        @media screen and (max-width:750px) {
            min-height: 220px;
        }
        @media screen and (max-width:500px) {
            min-height: 370px;
        }
        @media screen and (max-width:400px) {
            min-height: 320px;
        }
        @media screen and (max-width:350px) {
            min-height: 325px;
        }
        .container-content{
            width: 75%;      
            padding-bottom: 0 !important;      
            .socials {                                
                .list_socials{
                @media screen and (max-width:500px) {
                    display: flex;
                    flex-direction: column;
                    margin: auto;
                    width: fit-content;
                }
                .social.complete{
                    @media screen and (max-width:650px) {
                        padding: 0 !important;
                    }
                    width: auto;
                    img{
                        max-width: 100px;
                        max-height: 100px;
                    }
                    a{
                        text-decoration: none;
                    }
                    .text-button{
                        font-style: italic;
                        width: auto;
                        text-align: start;                        
                        font-weight: initial;                        
                        strong{
                            font-weight: bold;
                        }
                    }
                }
            }
        }
        }
    }
    .gallery .list_gallery{
        .image-gallery{
            @media screen and (max-width:820px) {
                max-width: none !important;
                width: 100% !important;
                img{
                    width: 90% !important;
                }
            }
        }
    }
    .game_finish{
        height: 600px;
        @media screen and (max-width:1000px) {
            height: 800px;
        }
        @media screen and (max-width:850px) {
            height: 700px;
        }
        @media screen and (max-width:700px) {
            height: 600px;
        }
        @media screen and (max-width:600px) {
            height: 500px;
        }
        @media screen and (max-width:500px) {
            height: 600px;
        }
        @media screen and (max-width:400px) {
            height: 500px;
        }
        background-size: 100% !important ;
        background-position: center top !important;
        display: flex;
        align-items: flex-end;
        .container-content{            
            >div{                                                    
                .actionButton{
                    margin-bottom: 10px;
                    font-style: italic;
                }
            }
        }
    }
    .game_game{
        #game_wishlist{
            .wrapper-wishlist{
                .wishlist-container{
                    .item-product{
                        button{
                            border-radius: 5px;
                        }
                        button:disabled,
                        button[disabled]{     
                            &.blue{
                                background-color: #0069BC !important;                                
                            }
                            &.purple{
                                background-color: #47296F !important;                                
                            }                     
                          background-color: rgb(220, 31, 43) !important;        
                          opacity: 1 !important;                  
                          color: rgba(255, 255, 255,0.5) !important;
                        }
                    }
                }
            }
            .wrapper-elements{
                button{
                    border-radius: 5px;
                }
                button:disabled,
                button[disabled]{                          
                  background-color: rgb(220, 31, 43) !important;        
                  opacity: 1 !important;                  
                  color: rgba(255, 255, 255,0.5) !important;
                }
            }
        }
    }
}
