.builder-template-scope {
    @import "~bootstrap/scss/bootstrap.scss";
    @import "~swiper/swiper.scss";
    @import "~animate.css/animate.min.css";
    @import "~lightgallery.js/dist/css/lightgallery.css";
    @import "../css/icons.min.css";

    @import "variables";
    @import "helper";
    @import "common";
    @import "preloader";
    @import "header";
    @import "slider";
    @import "support";
    @import "section-title";
    @import "product";
    @import "blog";
    @import "footer";
    @import "banner";
    @import "suscribe";
    @import "about";
    @import "image-slider";
    @import "testimonial";
    @import "brand-logo";
    @import "timers";
    @import "breadcrumb";
    @import "sidebar";
    @import "shop";
    @import "product-details";
    @import "cart";
    @import "compare";
    @import "checkout";
    @import "contact";
    @import "blog-details";
    @import "login-register";
    @import "my-account";
    @import "404";

    @import "../components/gift-finder/GiftFinder.scss";
    @import "../components/slider-with-right-images/SliderWithRightImages.scss";
    @import "../components/slider-multiple/SliderMultiple.scss";
    @import "../components/three-images-row/ThreeImagesRow.scss";
    @import "../components/three-images-grid/ThreeImagesGrid.scss";
    @import "../components/bloc-text/BlocText.scss";
    @import "../components/information-banner/InformationBanner.scss";
    @import "../components/link-banner/LinkBanner.scss";
    @import "../components/category/Category.scss";
    @import "../components/text-image/TextImage.scss";
    @import "../components/slider-product/SliderProduct.scss";
    @import "../components/text-video/TextVideo.scss";
    @import "../components/_pages/landing.scss";

    line-height: 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    font-family: $poppins;
    color: #000;
    position: relative;
}