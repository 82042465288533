div.basicBlock{
    height: 70vh;
    max-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &> p{
        &:first-child{
            // font-family: 'Hacked';
        }
        // font-family: 'Noto_Bold';
    }
    p{
        // font-family: 'Noto_Bold';
    }
    @media screen and (max-width: 700px){
        max-height: 500px;
        &>div{
            max-width: 90%;
        }
        p{
            display: inline;
            margin-right: 5px;
        }
    }
    @media screen and (max-width: 450px){
        &> p{
            &:first-child{
                font-size: 70px!important;
            }
        }
        
    }
}