div.results{
    section{
        &.loose, &.win{
            padding: 200px 0;
            &.without_social{
                padding: 150px 0 100px;
            }
            @media screen and (max-width: 800px){
                padding: 100px 0;
                overflow-x: hidden;
            }
        }
        &.loose{
            position: relative;
            z-index: 9;
            padding: 100px 0;
            h1{
                margin-bottom: 10px!important;
            }
            .result_div{
                margin-bottom: 100px;
            }
        }
        &.win{
            padding: 180px 0 30px;
            .result_div{
                max-width: 900px;
                margin: auto;
                margin-bottom: 20px;
                &>div{
                    padding: 120px 70px 10px;
                    position: relative;
                    h1{
                        font-size: 56px;
                        line-height: 60px;
                        margin: 0 0 20px!important;
                    }
                    .lot{
                        position: absolute;
                        bottom: calc(100% - 110px);
                        left: 50%;
                        transform: translateX(-50%);
                        max-height: 280px;
                    }
                    &>p{
                        text-align: center;
                        &:first-of-type{
                            font-size: 25px;
                        }
                    }
                    .reglement{
                        margin-top: 30px;
                    }
                    @media screen and (max-width: 1000px){
                        padding: 90px 20px 0;
                        &>p{
                            text-align: center;
                            font-size: 30px!important;
                            &:first-of-type{
                                font-size: 80px!important;
                            }
                            &:last-of-type{
                                font-size: 18px!important;
                            }
                        }
                        .lot{
                            bottom: calc(100% - 90px);
                            max-height: 240px;
                        }
                        .reglement{
                            margin-top: 30px;
                            text-shadow: inherit;
                        }
                    }
                    @media screen and (max-width: 750px){
                        &>p{
                            br{
                                display: none;
                            }
                        }
                    }
                    @media screen and (max-width: 500px) {
                        padding: 60px 10px 0;
                        &>p{
                            text-align: center;
                            font-size: 20px!important;
                            &:first-of-type{
                                font-size: 50px!important;
                            }
                            &:last-of-type{
                                font-size: 14px!important;
                            }
                        }
                        .lot{
                            bottom: calc(100% - 50px);
                            max-height: 200px;
                        }
                    }
                }
            }
        }
    }

    .result_div{
        max-width: 1000px;
        width: auto;
        margin: auto;
        &>div{
            padding: 35px 70px 25px;
            h1{
                font-weight: initial;
                font-size: 66px;
                //font-family: 'Noto_Bold';
                line-height: 70px;
                margin: 20px 0 30px;
                @media screen and (max-width: 900px){
                    font-size: 25px!important;
                    br{
                        display: none;
                    }
                }
                @media screen and (max-width: 400px){
                    font-size: 20px!important;
                }
            }
            p{
                text-align: center;
                //font-family: 'Noto_Black';
            }
            &>p{
                @media screen and (max-width: 900px){
                    font-size: 20px!important;
                    br{
                        display: none;
                    }
                }
                @media screen and (max-width: 400px){
                    font-size: 16px!important;
                }
            }
            .reglement{
                position: relative!important;
                top: 0;
                right: 0;
                display: block;
                text-align: center;
                margin: auto;
                //font-family: 'Noto_Bold';
                p{
                    @media screen and (max-width: 600px){
                        font-size: 14px!important;
                    }
                }
            }
        }
        @media screen and (max-width: 900px){
            &>div h1{
                font-size: 50px;
                line-height: 50px;
            }
        }
        @media screen and (max-width: 800px){
            &>div{
                padding: 35px 10px 40px!important;
                &>img{
                    max-width: 200px!important;
                }
            }
        }
    }
}