#builder-template-scope-book{
    .banner_link_lego{
        max-width: 100%;
        margin: auto;
        position: relative;
        overflow: hidden;
        .wrapper_text{
            text-align: center;
            margin: auto;
            & > p{
                font-weight: bold;
                margin: 0;
                margin-bottom: 22px;
                font-family: 'Sansa_Bold';
                text-shadow: 0px 1px 2px #0000009A;
                transition: 0.15s ease-in-out;
            }
            a{
                text-transform: uppercase;
                padding: 10px 20px!important;
                font-family: 'Ubuntu_Bold';
                font-weight: 500;
                position: relative;
                transition: 0.5s ease-in-out;
                text-decoration: none;
                &:hover{
                    filter: brightness(110%);
                }
            }
            .container{
                display: flex;
                flex-direction: row;
                .fullLink{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    padding: 0 !important;
                }
                &>div{
                    width: 50%;
                    &.discoverSection{
                        padding: 40px 0;
                        background: #FFCF00;
                        background-image:  url('./image/bg-jaune-lego.png');
                        background-size: 900px auto;
                        background-position: bottom center;
                        background-repeat: no-repeat;
                        position: relative;
                        p{
                            font-family: 'Cera_Bold';
                            font-size: 25px;
                            line-height: 1.2;
                            color: #032C2A;
                            width: 90%;
                            max-width: 450px;
                            display: block;
                            margin: auto;
                        }
                        img{
                            width: 187px;
                        }
                        &.only{
                            width: 100%;
                            background-size: cover;
                        }
                        .container_button{
                            width: 240px;
                            margin: auto;
                            margin-top: 25px;
                            a.button_lego{
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                img{
                                    width: 28px;
                                    &:last-child{
                                        width: 16px;
                                        margin-left: 30px;
                                    }
                                }
                            }
                        } 
                    }
                    &.vipSection{
                        background: #254EA7;
                        position: relative;
                        &>div{
                            &:first-child{
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                background: rgb(120,194,226);
                                background: linear-gradient(180deg, rgba(120,194,226,1) 0%, rgba(54,110,175,1) 100%);
                                padding: 20px 10px;
                                h2{
                                    font-size: 2.2vw;
                                    line-height: 1.1;
                                    color: white;
                                    font-family: 'Cera_Black_Italic';
                                    padding: 0 12px;
                                    p{
                                        margin: 0;
                                    }
                                }
                                img{
                                    width: 145px;
                                    padding: 0 12px;
                                }
                            }
                            &:nth-child(2){
                                padding: 24px 10px;
                                & > p{
                                    margin-top: 0;
                                    color: white;
                                    font-size: 25px;
                                    line-height: 1.2;
                                    font-family: 'Cera_Bold';
                                    p{
                                        margin: 0;
                                    }
                                }
                                .container_button{
                                    width: 240px;
                                    margin: auto;
                                    a.button_lego{
                                        display: flex;
                                        justify-content: space-around;
                                        align-items: center;
                                        height: 30px;
                                        img{
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }
                        }
                        .container_button a.button_lego{
                            color: #254EA7;
                        }
                    }
                    .container_button{
                        position: relative;
                        display: block;
                        z-index: 1;
                        margin-top: 25px;
                        a.button_lego{
                            font-size: 16px;
                            line-height: 1.2;
                            text-transform: uppercase;
                            padding: 18px 32px;
                            background: #FFF;
                            box-shadow: 0px 3px 0px #33333380;
                            border-radius: 4px;
                            color: #032C2A;
                            border: none;
                            font-family: 'Cera_Bold';
                            cursor: pointer;
                            img{
                                width: 16px;
                                margin: 0 8px;
                                vertical-align: text-bottom;
                            }
                            &:hover{
                                filter: brightness(90%);
                            }
                            @media screen and (max-width: 600px){
                                font-size: 14px;
                                line-height: 18px;
                                padding: 13px 23px;
                            }
                        }
                    }
                }
                @media screen and (max-width: 1200px) {
                    &>div{
                        &.discoverSection{
                            img{
                                width: 187px;
                            }
                            p{
                                font-size: 22px;
                            }
                        }
                        &.vipSection{
                            &>div{
                                &:first-child img{
                                    width: 120px;
                                }
                                &:last-child p{
                                    font-size: 22px;
                                }
                            }
                        }
                        .container_button a.button_lego{
                            font-size: 15px;
                        }
                    }
                }
                @media screen and (max-width: 991px) {
                    &>div{
                        &.discoverSection p{
                            font-size: 18px;
                        }
                        &.vipSection > div:last-child p{
                            font-size: 18px;
                        }
                    }
                }
                @media screen and (max-width: 700px){
                    flex-direction: column;
                    &>div{
                        width: 100%;
                        &.vipSection > div:first-child h2{
                            font-size: 4vw;
                        }
                    }
                }
                @media screen and (max-width: 450px){
                    &>div.vipSection > div:first-child{
                        h2{
                            font-size: 20px;
                        }
                        img{
                            width: 100px;
                        }
                    } 
                }
            }

            .starsSection{
                height: 200px;
                position: relative;
                &>img{
                    position: absolute;
                    &:nth-child(1){
                        left: 28vw;
                        top: 25%;
                    }
                    &:nth-child(2){
                        left: 37vw;
                        top: 60%;
                    }
                    &:nth-child(3){
                        left: 52vw;
                        top: 35%;
                    }
                    @media screen and (max-width: 900px) {
                        width: 25px;
                        &:nth-child(1){
                            left: 20vw;
                        }
                        &:nth-child(3){
                            left: 60vw;
                        }
                    }
                    @media screen and (max-width: 767px) {
                        width: 22px;
                        &:nth-child(3){
                            left: 70vw;
                        }
                    }
                    @media screen and (max-width: 500px) {
                        width: 20px;
                    }
                }
                @media screen and (max-width: 767px){
                    height: 100px;
                }
            }
            .copyright{
                color: #333333;
                font-size: 12px;
                line-height: 1.2;
                position: relative;
                font-family: 'Ubuntu_Regular';
                font-weight: 500;
                padding-bottom: 8px;
                padding: 6px 32px;
                max-width: 1230px;
                margin: auto;
                display: block;
                width: 80%;
                text-align: start;
                @media screen and (max-width: 1500px) {
                    margin: 0;
                }
                @media screen and (max-width: 768px) {
                    width: 65vw;
                    font-size: 10px;
                }
            }
        }
        .ribbon_bottom_right{
            position: absolute;
            bottom: -5px;
            right: 0;
            display: flex;
            z-index: 1;
            width: 13vw;
            height: 13vw;
            img{
                width: 100%;
            }
            @media screen and (max-width: 991px) {
                width: 20vw;
                height: 20vw;
            }
            @media screen and (max-width: 768px) {
                width: 30vw;
                height: 30vw;
            }
        }
    }
}