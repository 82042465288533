div.gallery{
    padding: 10px 0;
    position: relative;
    max-width: 100%;
    width: 100%;
    margin: 8px auto 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .list_gallery{
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 40px 0 0;
        @media screen and (max-width: 820px) {
            flex-direction: column;
        }
    }
    .image-gallery{
        display: inline-block;
        img{
            max-width: 100%;
        }
        @media screen and (max-width: 820px){
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 25px;
            &>img{
                display: inline-block;
                width: 80px;
                margin: 0;
            }
        }
        @media screen and (max-width: 450px){
            &>img{
                width: 60px;
                margin-left: 10px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        padding: 0;
    }
}