.container-blocs{
    // padding: 0 20px;
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 60px;
    justify-content: center;
    &.type-list{
        .bloc-list{
            width: calc(100%/3 - 40px);
            display: flex;
            flex-direction: column;
            // flex: 1;
            margin: 0 20px;
            justify-content: center;
            @media screen and (max-width: 1000px){
                width: calc(100%/2 - 40px);
            }
            @media screen and (max-width: 768px){
                width: calc(100% - 40px);
                &:not(:first-child){
                    margin-top: 40px;
                }
            }
            @media screen and (max-width: 500px){
                width: 100%;
                margin-left: 0;
                margin-right: 0;
            }
            // &:not(:last-child){
            //     margin-right: 20px;
            //     width: calc(100%/3 - 20px);
            //     @media screen and (max-width: 1000px){
            //         margin-right: 0px;
            //         width: calc(100%/2);
            //     }
            // }
            .title-bloc{
                margin-left: 8px;
                font-size: 39px;
                color: #6CAED1;
                @media screen and (max-width: 500px){
                    font-size: 25px;
                }
            }
            .container-list{
                background: #F8F8F8;
                display: flex;
                flex-direction: column;
                flex: 1;
                .title-list{
                    margin-left: 8px;
                    font-size: 39px;
                    font-weight: bold;
                    color: #6CAED1;
                    @media screen and (max-width: 500px){
                        font-size: 25px;
                    }
                }
                .item-list{
                    display: flex;
                    padding: 20px;
                    flex-direction: row;
                    align-items: center;
                    @media screen and (max-width: 768px){
                        width: calc(100% - 40px);
                    }
                    .infos{
                        margin-left: 16px;
                        .nom{
                            font-weight: bold;
                        }
                        @media screen and (max-width: 500px){
                            .nom, .role{
                                font-size: 14px;
                            }
                        }
                    }
                }
                .infos-sup{
                    padding-bottom: 16px;
                    padding-right: 20px;
                    font-style: italic;
                    color: #333;
                    font-size: 14px;
                }
            }
        }
    }
    &.type-blocs{
        margin: auto;
        margin-top: 40px;
        max-width: 1100px;
        padding-bottom: 60px;
        @media screen and (max-width: 768px) {
            max-width: 440px;
        }
        &.type-text{
            max-width: 100%;
            .bloc-blocs{
                @media screen and (max-width: 768px) {
                    margin: 0 !important;
                }
            }
        }
        .bloc-blocs{
            width: calc(100%/3 - 20px);
            display: flex;
            flex-direction: column;
            margin: 0 10px;
            @media screen and (max-width: 768px) {
                width: 100%;
                &:not(:first-child){
                    margin-top: 40px;
                }
            }
            img{
                width: 100%;
            }
            .title{
                font-weight: bold;
                @media screen and (max-width: 500px) {
                    font-size: 14px;
                }
            }
            .subtitle{
                font-style: italic;
                font-weight: bold;
                @media screen and (max-width: 500px) {
                    font-size: 14px;
                }
            }
            ul{
                padding-left: 18px;
                // margin-bottom: 64px;
                // @media screen and (max-width: 768px) {
                //     margin-bottom: 32px;
                // }
                @media screen and (max-width: 500px) {
                    font-size: 14px;
                }
            }
            p{
                margin: 0;
                @media screen and (max-width: 500px) {
                    font-size: 14px;
                }
            }
            // .bloc-title{
            //     font-size: 24px;
            //     margin-top: 16px;
            //     font-weight: bold;
            //     @media screen and (max-width: 500px) {
            //         font-size: 14px;
            //     }
            // }
            // .bloc-description{
            //     margin-top: 16px;
            //     @media screen and (max-width: 500px) {
            //         font-size: 14px;
            //         margin-top: 8px;
            //     }
            // }
        }
    }
}