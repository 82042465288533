#swiper-home {
    .slider-area{
        // .swiper-container{
        //     padding-bottom: 30px;
        // }
        .swiper-wrapper{
            // height: auto !important;
            // .slider-animated-1{
            //     img{
            //         -webkit-animation-delay: .8s;
            //         animation-delay: .8s;
            //         -webkit-animation-name: fadeInUp;
            //         animation-name: fadeInUp;
            //     }
            // }
        }
        .swiper-slide{
            height: auto;
        }
        .swiper-button-next, .swiper-button-prev{
            top: auto;
            bottom: 6px;
            width: 16px;
            height: 16px;
            &::after{
                font-size: 14px;
                font-weight: bolder;
                color: #0273A5;
            }
        }
        .swiper-button-prev{
            left: auto;
            right: 30px;
        }
        .swiper-pagination-bullets {
            bottom: unset !important;
            position: relative !important;
            background: white;
            .swiper-pagination-bullet{
                background: white;
                border: 1px solid #0273A5;
                width: 10px !important;
                height: 10px !important;
                margin: 0 4px;
            } 
            .swiper-pagination-bullet-active{
                background: #0273A5 !important;
            } 
        }
    } 
}