.bandeau{
    display: flex;
    div.block{
        padding: 20px 10px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-left: 5%;
        &>img{
            vertical-align: bottom;
            max-width: 400px;
        }
        &>a{
            margin: 0;
        }
    }

    @media screen and (max-width: 1050px) {
        justify-content: center;
        div.block{
            &>img{
                width: 300px;
            }
        }
    }
    @media screen and (max-width: 950px) {
        div.block{
            &>a, &>button{
                font-size: 18px!important;
                line-height: 30px;
                padding: 0 10px;
            }
            h2{
                font-size: 26px!important;
                line-height: 32px;
            }
        }
    }
    @media screen and (max-width: 770px) {
        flex-direction: column;
        div.block{
            margin-left: 0;
            &:first-child{
                order: 1;
            }
            &:last-child{
                margin-bottom: 30px;
                margin-top: 25px;
            }
            &>img{
                width: 250px;
            }
            &>a{
                font-size: 22px;
                line-height: 26px;
                padding: 10px 15px;
            }
            h2{
                font-size: 40px;
                line-height: 50px;
            }
        }
    }
}

.bandeau2{
    padding: 40px;
    width: calc(100% - 80px);
    .block{
        &:first-child{
            width: 30%;
            display: inline-block;
        }
        &:nth-child(2){
            width: 70%;
            display: inline-block;
            img{
                width: 900px;
            }
        }
        &:nth-child(3), &:nth-child(4){
            width: 360px;
            position: relative;
            right: 0;
            left: calc(100% - 360px);
        }
    }
    .block_absolute{
        position: absolute;
        left: 10%;
        bottom: 10%;
    }
    a.as_button{
        //font-family: 'HaggardHeavy';
        text-transform: uppercase;
        padding-top: 20px!important;
        padding-bottom: 20px!important;
    }
    @media screen and (max-width: 767px){    
        .block{
            &:first-child{
                display: none;
            }
            &:nth-child(2){
                width: 100%;
            }
            &:nth-child(3), &:nth-child(4){
                width: 100%;
                position: relative;
                left: 0;
            }
        }
        .block_absolute{
            display: none;
        }
    }
    @media screen and (max-width: 500px){
        padding: 40px 10px;
        width: calc(100% - 20px);
    }
}


.bandeau3{
    width: 100%;
    position: relative;
    min-height: 300px;
    div.block{
        width: 400px;
        max-width: 100%;
        position: absolute;
        margin: 1px;
        a{
            width: max-content;
            margin: 10px auto;
            line-height: 1.2!important;
        }
        h2{
            margin: 10px auto;
        }
    }
    &.position{
        &-left{
            .block{
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        &-right{
            .block{
                left: calc(100% - 410px);
                top: 50%;
                transform: translateY(-50%);
            }
        }
        &-top{
            min-height: 400px;
            .block{
                padding-bottom: 200px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        &-bottom{
            min-height: 400px;
            .block{
                padding-top: 200px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    } 
}


.bandeau4{
    padding-top: 25px;
    padding-bottom: 100px;
    &>div.content{
        width: 50%;
        left: 50%;
        position: relative;
        a{
            width: max-content;
            //font-family: 'Gotham_Black';
        }
        @media screen and (max-width: 1150px){
            width: 60%;
            left: 40%;
        }
        @media screen and (max-width: 900px){
            width: 70%;
            left: 30%;
        }
        @media screen and (max-width: 650px){
            width: 100%;
            left: 0;
        }
    }
    &>div.block{
        width: 50%;
        left: 50%;
        position: relative;
        a{
            width: max-content;
            //font-family: 'Gotham_Black';
        }
        @media screen and (max-width: 1150px){
            width: 60%;
            left: 40%;
        }
        @media screen and (max-width: 900px){
            width: 70%;
            left: 30%;
            position: absolute;
            left: calc(100% - 120px);
            bottom: 10px;
            width: 110px;
            height: 90px;
        }
        @media screen and (max-width: 650px){
            width: 100%;
            left: 0;
        }
        position: absolute;
        left: calc(100% - 160px);
        bottom: 10px;
        width: 150px;
        height: 150px;
        img{
            width: 100%;
            max-width: 100%;
        }
    }
}