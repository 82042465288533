
.slider-with-right-images {
    .slider-area {
        position: relative;

        .pagination-container {
            bottom: 26px;
            left: 0;
            position: absolute;
            right: 0;
            text-align: center;
            z-index: 1;

            .bullet {
                background: green none repeat scroll 0 0;
                display: inline-block;
                margin: 0 8px;
                position: relative;
                border-radius: 0;
                height: 14px;
                width: 14px;
                opacity: 0.5;
                cursor: pointer;

                &.active {
                    opacity: 1;
                }
            }
        }
    }
    
    .swiper-container {
        width: 100%;

        .swiper-slide.slider-height-4  {
            height: 460px;
        
            @media #{$xx-layout}{
                height: 460px;
            }
            @media #{$xl-layout}{
                height: 460px;
            }
            @media #{$lg-layout}{
                height: 460px;
            }
            @media #{$md-layout}{
                height: 460px;
            }
            @media #{$xs-layout}{
                height: 460px;
            }
        }
    }

    .right-images {
        .slider-image {
            width: 100%;
            height: calc(50% - 5px);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        .slider-image:first-child() {
            margin-bottom: 10px;
        }
    }
}