.offer-template-scope {
    @import "~bootstrap/scss/bootstrap.scss";
    @import "~swiper/swiper.scss";
    @import "~animate.css/animate.min.css";
    @import "~lightgallery.js/dist/css/lightgallery.css";

    /* COMMON */
    @import "common";

    /* COMPONENTS */
    @import "front-page";
    @import "about-slide";
    @import "triple-block";
    @import "text-with-image";
    @import "devis";

    background: white !important;
    font-family: "Poppins", sans-serif !important;
    position: relative;

    p {
        margin: auto;
        font-family: "Poppins", sans-serif !important;
    }

    @media screen and (max-width: 1000px) {
        .container {
            max-width: 100%;
        }
    }
}
